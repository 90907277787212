import axios from 'axios'
import { IAuthTokens, TokenRefreshRequest, applyAuthTokenInterceptor, clearAuthTokens } from 'axios-jwt'

import { globals, BACKEND as BASE_URL } from '../../config';


const axiosInstance = axios.create({ baseURL: BASE_URL });

const requestRefresh: TokenRefreshRequest = async (refreshToken: string): Promise<IAuthTokens | string> => {
    const response = await axios({
        method: 'post',
        withCredentials: true,
        headers: { accept: 'application/json', 'Content-Type': 'application/json' },
        url: `${BASE_URL}${globals.api.refresh}`,
        data: { refresh_token: refreshToken },
    }).catch((error) => {
        console.log(error);
        clearAuthTokens();
        window.location.reload();
    });

    return response?.data?.token;
};

applyAuthTokenInterceptor(axiosInstance, { requestRefresh });


export default axiosInstance;