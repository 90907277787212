import { ReactNode } from 'react';
import { Link } from 'react-router-dom';


type Props = {
    inline?: boolean,
    variant?: string,
    size?: string,
    path?: string,
    className?: string,
    children: ReactNode,
    rounded?: boolean,
    [x: string]: any;
}


const Button = ({inline, variant, size, className, path, children, rounded, ...props}: Props): JSX.Element => {

    const classes = () => {
        let classes = { type: '', variant: '', size: '', base: 'font-bold transition-all duration-200' };

        if (inline) {
            classes.type = '!p-1 underline hover:opacity-70';

            switch (variant) {
                case 'black':
                    classes.variant = 'text-sm text-c-light-black';
                    break;
                case 'green':
                    classes.variant = 'text-sm text-c-green';
                    break;
                case 'orange':
                    classes.variant = 'text-sm text-c-orange';
                    break;
                default:
                    classes.variant = 'text-base text-c-blue';
                    break;
            }
        } else {
            classes.type = 'group text-base rounded border-2 border-transparent hover:bg-white disabled:bg-gray-300 disabled:border-gray-300 disabled:pointer-events-none disabled:text-white';

            switch (variant) {
                case 'back':
                    classes.variant = 'text-c-orange bg-c-light-orange hover:border-c-light-orange';
                    break;
                case 'green':
                    classes.variant = 'text-white bg-c-green hover:border-c-green hover:text-c-green';
                    break;
                case 'light-green':
                    classes.variant = 'text-c-green bg-c-green/15 hover:border-c-green hover:text-c-green';
                    break;
                case 'hollow-blue':
                    classes.variant = 'text-c-blue bg-c-light-blue hover:border-c-blue';
                    break;
                case 'hollow-white':
                    classes.variant = 'text-c-blue bg-white !border border-c-medium-blue hover:bg-c-blue hover:border-c-blue hover:text-white';
                    break;
                case 'white':
                    classes.variant = 'text-c-blue bg-white shadow-c-button hover:border-c-blue';
                    break;
                default:
                    classes.variant = 'text-white bg-c-blue hover:border-c-blue hover:text-c-blue';
                    break;
            }
        }

        switch (size) {
            case 'slim':
                classes.size = 'px-7 py-2';
                break;
            case 'big':
                classes.size = 'px-5 py-3.5';
                break;
            case 'picto':
                classes.size = 'justify-center p-2';
                break;
            default:
                classes.size = 'px-4.5 py-2.75';
                break;
        }

        return `${classes.base} ${classes.type} ${classes.size} ${classes.variant} ${className || ''} ${rounded ? '!rounded-full' : ''}`;
    }

    return (
        <>
            { path ? (
                <Link to={path} {...props} className={`${classes()} inline-flex items-center`}>
                    { children }
                </Link>
            ) : (
                <button type="button" {...props} className={`${classes()} inline-flex items-center`}>
                    { children }
                </button>
            )}
        </>
    )
}


export default Button;