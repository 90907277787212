import { useFieldArray } from '../../../../helpers/hooks';
import { FieldArray } from 'formik';

import { Paragraph } from '../../../common/Typos';
import { Tooltip } from '../../../common/Tooltip';
import { Square } from '../../../common/Box';
import { SelectField } from '../../../common/Forms';

import texts from '../../../../assets/data/contents.json';


const options = [
    { value: 0, label: '0' },
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5' },
];


const RankHeader = ({ containerRef }: { containerRef: any }) => {

    const { values } = useFieldArray();

    return (
        <div className="flex mb-2.5">
            <div className="flex-none w-32 min-h-results border border-c-blue rounded-sm p-2 text-center flex flex-col items-center justify-center">
                <Paragraph variant="blue" weight="bold">{texts.actions.rank.form.anticipate.name}</Paragraph>

                <Tooltip tooltipContainer={containerRef} color="mt-1 fill-c-light-cyan">
                    <Paragraph className="text-center">{texts.actions.rank.tooltip}</Paragraph>
                </Tooltip>
            </div>

            <FieldArray name="categories" render={() => (
                <>
                    <ul className="flex-none ml-2.5 flex gap-2.5">

                        { values.categories && values.categories.length > 0 && (
                            values.categories.map((cat: number, idx: number) => (

                                <li className="w-40 flex flex-col gap-2.5" key={idx}>
                                    <Square className="flex-1">{texts.actions.rank.form.categories[idx]}</Square>

                                    <div className="flex-none">
                                        <SelectField name={`categories[${idx}]`} options={options}/>
                                    </div>
                                </li>

                            ))
                        )}
                    </ul>
                </>
            )}/>
        </div>
    )
}

export default RankHeader;