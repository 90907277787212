import { PlanningPostDatasType, PlanningType } from '../../types';


export const formatPlanningGetDatas = (datas: PlanningPostDatasType, levels: number) => {

    const getDatas: PlanningType = {
        step2_answer_sage: datas.step2_answer_sage,
        step3_answer_sage_1: datas.step3_answer_sage_1,
        step3_answer_sage_2: datas.step3_answer_sage_2,
        step4_answer_sage_1: datas.step4_answer_sage_1,
        step4_answer_sage_2: datas.step4_answer_sage_2,
        step5_answer_sage_1: datas.step5_answer_sage_1,
        step5_answer_sage_2: datas.step5_answer_sage_2,
        step5_answer_sage_3: datas.step5_answer_sage_3,
        step6_answer_sage_1: datas.step6_answer_sage_1,
        step6_answer_sage_2: datas.step6_answer_sage_2,
        step6_answer_sage_3: datas.step6_answer_sage_3,
        step6_answer_sage_4: datas.step6_answer_sage_4,
        step6_answer_sage_5: datas.step6_answer_sage_5,
        step6_answer_sage_6: datas.step6_answer_sage_6,
        temporality: [
            datas.step6_level1_temporality ? datas.step6_level1_temporality : null,
            datas.step6_level2_temporality ? datas.step6_level2_temporality : null,
            datas.step6_level3_temporality ? datas.step6_level3_temporality : null,
        ]
    }

    if (levels === 4) getDatas.temporality.push(datas.step6_level4_temporality ? datas.step6_level4_temporality : null);


    return getDatas;
}


export const formatPlanningPostDatas = (datas: PlanningType, levels: number | undefined) => {

    const postDatas: PlanningPostDatasType = {
        step2_answer_sage: datas.step2_answer_sage,
        step3_answer_sage_1: datas.step3_answer_sage_1,
        step3_answer_sage_2: datas.step3_answer_sage_2,
        step4_answer_sage_1: datas.step4_answer_sage_1,
        step4_answer_sage_2: datas.step4_answer_sage_2,
        step5_answer_sage_1: datas.step5_answer_sage_1,
        step5_answer_sage_2: datas.step5_answer_sage_2,
        step5_answer_sage_3: datas.step5_answer_sage_3,
        step6_answer_sage_1: datas.step6_answer_sage_1,
        step6_answer_sage_2: datas.step6_answer_sage_2,
        step6_answer_sage_3: datas.step6_answer_sage_3,
        step6_answer_sage_4: datas.step6_answer_sage_4,
        step6_answer_sage_5: datas.step6_answer_sage_5,
        step6_answer_sage_6: datas.step6_answer_sage_6,
        step6_level1_temporality: datas.temporality[0] ? datas.temporality[0] : null,
        step6_level2_temporality: datas.temporality[1] ? datas.temporality[1] : null,
        step6_level3_temporality: datas.temporality[2] ? datas.temporality[2] : null,
        step6_level4_temporality: (levels === 4) ? (datas.temporality[3] ? datas.temporality[3] : null) : null,
    }

    return postDatas;
}