import { useRef } from 'react';
import { useProject, useFieldArray } from '../../../helpers/hooks';
import { FieldArray } from 'formik';
import { ImpactBlueprint } from './Impacts';


import { ImpactType } from '../../../types';

import { Input } from '../../common/Forms';
import { TableRow } from '../../common/Table';
import { ListHeader, TableActions, TableLeft, TableRight } from '../../common/Table/Table';
import { Add, Button } from '../../common/Buttons';
import { ConfirmModal } from '../../common/Modal';

import { SVG } from '../../../config';
import texts from '../../../assets/data/contents.json';



const ImpactField = ({ idx, impactIndex, locked }: { idx: number, impactIndex: number, locked: boolean }): JSX.Element => {

    const inputRef = useRef<HTMLInputElement>(null);

    return (
        <li className="w-full">
            <Input
                name={`impacts[${impactIndex}].descriptions[${idx}]`}
                type="text"
                variant="blue"
                placeholder={texts.impacts.form.label}
                disabled={locked}
                forwardRef={inputRef}
                onFocus={() => { inputRef.current && inputRef.current.classList.remove('field-warning'); }}
                showTitle
            />
        </li>
    );
}


export const ImpactsListHeader = (props: { locked: boolean, containerRef: any }) => (
    <ListHeader
        tooltipContainer={props.containerRef}
        title={texts.impacts.form.title}
        suffix={texts.impacts.form.max}
        tooltip={texts.impacts.form.tooltip}
        {...props}
    />
);

const ImpactsList = ({ locked = false, tight = false, containerRef }: { locked?: boolean, tight?: boolean, containerRef: any }): JSX.Element => {

    const { seuils } = useProject();
    const { values, open, setOpen, handleRemove, doneRemove } = useFieldArray();

    return (
        <div>
            { !locked && <ImpactsListHeader containerRef={containerRef} locked={locked}/>}

            <FieldArray name="impacts" render={(arrayHelpers: any) => (
                <>
                    <div className={"space-y-3 mt-3"}>

                        { values.impacts && values.impacts.length > 0 && (
                            values.impacts.map((impact: ImpactType, index: number) => (

                                <TableRow key={index}>

                                    <TableLeft>
                                        <Input
                                            name={`impacts[${index}].name`}
                                            type="text"
                                            variant="blue"
                                            placeholder={texts.impacts.form.libelle}
                                            disabled={locked}
                                            floatErrors
                                            showTitle
                                        />
                                    </TableLeft>

                                    <TableRight tight={tight}>
                                        <ul className={`flex items-center ${!tight ? 'space-x-12' : 'space-x-2.5'}`}>
                                            { seuils && seuils.levels.map((level, idx) => (
                                                <ImpactField key={idx} impactIndex={index} idx={idx} locked={locked}/>
                                            ))}
                                        </ul>
                                    </TableRight>

                                    <TableActions>
                                        { !locked && index > 0 && (
                                            <Button className="w-seuils h-seuils" size="picto" inline onClick={() => handleRemove(index)}>
                                                <SVG.Trash className="w-6 h-6"/>
                                            </Button>
                                        )}
                                    </TableActions>
                                </TableRow>

                            ))
                        )}
                    </div>

                    {!locked && (
                        <>
                            <Add onClick={() => values.impacts.length < 5 && arrayHelpers.push(ImpactBlueprint)} text={texts.impacts.form.add} disabled={values.impacts.length >= 5}/>

                            <ConfirmModal title={texts.impacts.delete} open={open} setOpen={setOpen} doneCallback={() => doneRemove(arrayHelpers)}/>
                        </>
                    )}
                </>
            )}/>
        </div>
    );
}


export default ImpactsList;