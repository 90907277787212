import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from './index';

import { assets } from '../../../config';
import texts from '../../../assets/data/contents.json';



type Props = {
    path: string,
    children?: ReactNode
}

const Back = (props: Props): JSX.Element => {
    const navigate = useNavigate();
    const { path, children } = props;

    const handleClick = () => {
        navigate(path);
    };

    return (
        <Button type="button" variant="back" size="slim" onClick={handleClick}>
            <img className="w-4 mr-2" src={assets.picto.arrowO} alt={texts.generic.back}/>
            {children ? children : <>{texts.generic.back}</>}
        </Button>
    )
}


export default Back;