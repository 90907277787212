import { useEffect, useState } from 'react';
import { useFetch, useProject } from './index';
import { formatObjectivesGetDatas } from '../api/objectives';

import * as yup from 'yup';

import { ObjectivesPostDatasType, ObjectivesType } from '../../types';

import { globals } from '../../config';
import texts from '../../assets/data/contents.json';



const useObjectives = () => {
    const { project, seuils } = useProject();

    const [initialValues, setInitialValues] = useState<ObjectivesType>({
        step3_answer_sage_1: '',
        step3_answer_sage_2: '',
        objectives: Array(seuils?.levels.length).fill(''),
    });

    const { data: getDatas } = useFetch<ObjectivesPostDatasType>(globals.api.steps.objectives.get, { project_id: project?.id}, true);

    useEffect(() => {
        if (getDatas && seuils) setInitialValues(formatObjectivesGetDatas(getDatas, seuils?.levels.length));
    }, [getDatas, seuils]);

    const validation = yup.object().shape({
        step3_answer_sage_1: yup.string(),
        step3_answer_sage_2: yup.string(),
        objectives: yup.array().of(yup.string().required(texts.generic.form.required)).required(texts.generic.form.required)
    });


    return { initialValues, validation };
}


export default useObjectives;
