import axiosInstance from '../../../helpers/api/axios';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import * as yup from 'yup';

import { FormWrap, Error as CustomError, Password } from '../../common/Forms';
import { Title } from '../../common/Typos';
import { Back } from '../../common/Buttons';

import texts from '../../../assets/data/contents.json';
import { assets, globals } from '../../../config';



const Reset = (): JSX.Element => {

    const [searchParams] = useSearchParams();
    const hash = { hash: searchParams.get("hash") };
    const [invalid, setInvalid] = useState(null);
    const [isValid, setIsValid] = useState(false);


    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const handleVerify = async () => {
            try {
                await axiosInstance({
                    method: 'post',
                    url: globals.api.verify,
                    signal: controller.signal,
                    withCredentials: true,
                    headers: { accept: 'application/json', 'Content-Type': 'application/json' },
                    data: JSON.stringify(hash),
                });

                isMounted && setIsValid(true);
            } catch (err: any) {
                console.log(err);
                setInvalid(err?.response?.data.message || err?.response?.data.error);
            }
        };

        handleVerify();

        return () => {
            isMounted = false;
            controller.abort();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const validation = yup.object({
        password: yup.string().required(texts.generic.form.required).matches(/^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{8,})/, texts.generic.form.pattern),
        confirm_password: yup.string().required(texts.generic.form.required).oneOf([yup.ref('password'), null], texts.generic.form.match),
    });

    const initial = { password: '', confirm_password: '', hash: hash.hash };


    return (
        <div className="w-full max-w-login">
            <Title className="text-center mt-8 mb-4" size="subtitle">{texts.login.password.reset}</Title>

            {invalid &&
                <div className="my-16">
                    <CustomError className="text-center" full={true}>{invalid}</CustomError>
                </div>
            }

            {isValid && (
                <FormWrap
                    api={globals.api.reset}
                    validation={validation}
                    initial={initial}
                    cta={texts.generic.valid}
                    successContent={texts.login.password.resetdone}
                    className={"max-w-login"}
                    isLoginTunnel={true}
                    reset
                >
                    <Password
                        label={texts.login.password.new}
                        name="password"
                        type="password"
                        placeholder={texts.login.password.placeholder}
                        picto={assets.picto.lock}
                    />
                    <Password
                        label={texts.login.password.valid}
                        name="confirm_password"
                        type="password"
                        placeholder={texts.login.password.placeholder}
                        picto={assets.picto.lock}
                    />
                </FormWrap>
            )}

            <div className="text-center">
                <Back path={globals.routes.login}>
                    {texts.login.connect}
                </Back>
            </div>
        </div>
    );
}


export default Reset;