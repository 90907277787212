import React from 'react';
import { useField } from 'formik';

import { Error } from './index';
import { Label } from '../Typos';


const Input = ({ label, labelSize, picto, toggle, variant, className, floatErrors = false, showTitle = false, forwardRef, ...props }: any) => {
    const [field, meta] = useField(props);

    const float = meta.touched && meta.error && floatErrors;

    const classes = () => {
        let classes = { variant: '', base: 'block w-full rounded-sm text-sm p-4 placeholder-c-gray outline-none outline-0 text-ellipsis overflow-hidden transition-shadow duration-200' };

        switch (variant) {
            case 'inner':
                classes.variant = 'shadow-c-input focus:shadow-c-input-hover';
                break;
            case 'blue':
                classes.variant = 'shadow-c-input focus:shadow-c-input-hover !rounded border border-c-light-cyan !p-3.25';
                break;
            default:
                classes.variant = 'shadow-c-shadow focus:shadow-c-shadow-hover';
                break;
        }

        return `${classes.base} ${classes.variant} ${className || ''}`;
    }


    const input = <input
        {...field}
        {...props}
        ref={forwardRef}
        value={field.value || ''}
        title={((props.disabled || showTitle) && field.value) ? field.value : undefined}
        className={`${classes()} ${picto ? 'pl-11' : ''} ${float ? '!border-red-400 !placeholder-red-400' : ''}`}
    />;

    return (
        <div>
            {label && <Label size={labelSize} htmlFor={props.id || props.name}>{label}</Label>}

            { picto || toggle ? (
                <div className="relative">
                    { picto && <img className="vertical-center left-4" src={picto} alt={props.placeholder}/>}
                    { input }
                    { toggle && toggle }
                </div>
            ) : (
                <>{ input }</>
            )}

            { meta.touched && meta.error && !floatErrors ? (
                <Error>{meta.error}</Error>
            ) : null}
        </div>
    );
};

export default Input;