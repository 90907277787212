import { useEffect, useState } from 'react';
import { useFetch, useProject } from './index';
import { formatImpactsGetDatas } from '../api/impacts';
import { ImpactBlueprint } from '../../components/pages/Impacts';

import * as yup from 'yup';

import { ImpactsAndCausesPostDatasType, ImpactsAndCausesType } from '../../types';

import { globals } from '../../config';
import texts from '../../assets/data/contents.json';




const useImpacts = () => {
    const { project, setSeuils } = useProject();

    const [initialValues, setInitialValues] = useState<ImpactsAndCausesType>({
        step2_answer_sage: '',
        seuils: { name: '', levels: ['', '']},
        impacts: [ImpactBlueprint, ImpactBlueprint],
        causes: [ {id: null, name: ''} ]
    });

    const { data: getDatas } = useFetch<ImpactsAndCausesPostDatasType>(globals.api.steps.impacts.get, { project_id: project?.id}, true);

    useEffect(() => {
        if (getDatas) setInitialValues(formatImpactsGetDatas(getDatas));
    }, [getDatas]);

    useEffect(() => {
        setSeuils({name: initialValues.seuils.name, levels: Array(initialValues.seuils.levels.length + 1).fill('')});
    }, [initialValues, setSeuils])

    useEffect(() => {
        setSeuils({name: '', levels: Array(3).fill('')});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const validation = yup.object().shape({
        step2_answer_sage: yup.string(),
        seuils: yup.object().shape({
            name: yup.string().required(texts.generic.form.required),
            levels: yup.array().of(yup.string()).required(texts.generic.form.required).min(2, 'Au minimum 2 seuils').max(3, 'Au maximum 3 seuils')
        }),
        impacts: yup.array().of(
            yup.object().shape({
                name: yup.string().required(texts.generic.form.required),
                descriptions: yup.array().of(yup.string()).required(texts.generic.form.required).min(3, 'Au minimum 3 description').max(4, 'Au maximum 4 description'),
            })
        ).required(texts.generic.form.required).min(1, 'Au minimum 1 impact').max(5, 'Au maximum 5 impacts'),
        causes: yup.array().of(
            yup.object().shape({
                name: yup.string().required(texts.generic.form.required)
            })
        ).required(texts.generic.form.required).min(1, 'Au minimum 1 cause').max(50, 'Au maximum 50 causes')
    });


    return { initialValues, validation };
}


export default useImpacts;
