import { ActionBlueprint } from '../../components/pages/Actions';

import {
    ActionCreateDataType,
    ActionsCreatePostDatasType,
    ActionsCreateType,
    ActionType
} from '../../types';


export const formatActionsCreateGetDatas = (datas: ActionsCreatePostDatasType) => {

    const getDatas: ActionsCreateType = {
        actions: [ActionBlueprint, ActionBlueprint]
    }

    if (datas.project_actions.length > 0) {
        getDatas.actions = [];

        datas.project_actions.map((action: ActionCreateDataType) => {
            getDatas.actions.push({
                id: action.id,
                name: action.name,
                description: action.description,
                matches: [
                    action.level1_activated,
                    action.level2_activated,
                    action.level3_activated,
                    action.level4_activated,
                ]
            });
            return null;
        });
    }

    return getDatas;
}


export const formatActionsCreatePostDatas = (datas: ActionsCreateType) => {

    const postDatas: ActionsCreatePostDatasType = {
        project_actions: []
    }

    datas.actions.map((action: ActionType) => {
        postDatas.project_actions.push({
            id: action.id || null,
            name: action.name,
            description: action.description,
            level1_activated: action.matches[0],
            level2_activated: action.matches[1],
            level3_activated: action.matches[2],
            level4_activated: action.matches[3],
        });
        return null;
    });

    return postDatas;
}