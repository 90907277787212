import { assets } from '../../../config';
import texts from '../../../assets/data/contents.json';


type LogoType = {
    src: string,
    alt?: string,
    size?: string
}

const Logos = (): JSX.Element => {

    const logos: LogoType[] = [
        { src: assets.logos.ademe, alt: texts.generic.ademe, size: "max-w-ademe" },
        { src: assets.logos.acterra, alt: texts.generic.acterra, size: "max-w-acterra" },
        { src: assets.logos.oi, alt: texts.generic.oi, size: "max-w-oi" }
    ];

    return (
        <div className="text-center">
            <img src={assets.logos.lifeEauBlue} alt={texts.generic.lifeEau} className="max-w-logo inline-block"/>

            <ul className="flex flex-wrap justify-center items-center m-4 ">
                { logos.map((logo, idx) => (
                    <li key={idx} className="px-4 py-2">
                        <img src={logo.src} alt={logo.alt} className={logo.size}/>
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default Logos;