import { useState, ReactNode } from 'react';
import { Formik, Form } from 'formik';
import { useFormLog } from '../../../helpers/hooks';

import { FormatPostDatasType } from '../../../types';

import { Box } from '../Box';
import { Back, Button } from '../Buttons';
import { Title } from '../Typos';
import { Error } from './index';
import { ConfirmModal } from '../Modal';

import { SVG } from '../../../config';
import texts from '../../../assets/data/contents.json';



type FormWrapType = {
    children: ReactNode,
    api: string,
    validation: any,
    initial: object,
    onSubmit?: ((res: any) => void) | undefined,
    successContent?: string
    cta: string,
    title?: string,
    back?: string,
    backLabel?: string,
    preChild?: ReactNode,
    className?: string,
    submitVariant?: string,
    additionalParams?: object,
    formatPostDatas?: FormatPostDatasType,
    reset?: boolean,
    guardSubmit?: () => string | null
    formRef?: any,
    hideCtas?: boolean,
    isLoginTunnel?: boolean
}

const FormWrap = (props: FormWrapType): JSX.Element => {

    const { children, back, api, validation, initial, cta, title, preChild, onSubmit, successContent, className, submitVariant, backLabel, additionalParams, formatPostDatas, reset = false, guardSubmit, formRef, hideCtas, isLoginTunnel = false } = props;

    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const [open, setOpen] = useState(false);
    const [needGuard, setNeedGuard] = useState(true);

    const handleSubmit = useFormLog(api, setError, setSuccess, onSubmit, additionalParams, formatPostDatas, reset);

    const handleGuard = () => {
        if (guardSubmit && guardSubmit()) setOpen(true);
        else forceSubmit();
    }
    const forceSubmit = () => {
        setNeedGuard(false);
        formRef.current && formRef.current.handleSubmit();
    }


    return (
        <>
            <div className={`${className || ''} w-full`}>

                { title && <Title className="text-center mt-8 mb-4" size="subtitle">{title}</Title>}

                <Formik
                    innerRef={formRef}
                    initialValues={initial}
                    validationSchema={validation}
                    validateOnMount={true}
                    onSubmit={(guardSubmit && needGuard) ? handleGuard : handleSubmit}
                    enableReinitialize
                >
                    {
                        ({isSubmitting, isValid, dirty}) => (
                            <Form className="py-6 space-y-5" onChange={() => {
                                setError(null);
                                setSuccess(false);
                            }}>
                                {children}

                                {error && <Error full={true}>{error}</Error>}

                                {(success && successContent) &&
                                    <Box type="inline" size="full" className="max-w-xs mx-auto !mt-8" picto={<SVG.Info className="fill-c-blue w-4.5 h-4.5"/>}>
                                        <p className="text-center italic text-sm text-c-blue">{successContent}</p>
                                    </Box>
                                }

                                { !hideCtas && (
                                    <>
                                        <div className="text-center !mt-16 space-x-2.5">
                                            { back && <Back path={back}>{backLabel}</Back> }

                                            <Button variant={submitVariant || ''} type="submit" size="slim" disabled={isSubmitting || !(isValid)}>
                                                {cta}{isSubmitting ? '...' : ''}
                                            </Button>
                                        </div>

                                        { !isLoginTunnel && (
                                            <div className="fixed top-[1.375rem] right-24 z-2500 !m-0">
                                                <Button variant={submitVariant || ''} type="submit" size="picto" disabled={isSubmitting || !(isValid)}>
                                                    <SVG.Save className={`${submitVariant === 'green' ? 'group-hover:fill-c-green' : 'group-hover:fill-c-blue'} transition-all mr-2 w-4 h-4`}/>
                                                    {texts.generic.save}{isSubmitting ? '...' : ''}
                                                </Button>
                                            </div>
                                        )}
                                    </>
                                )}
                            </Form>
                        )
                    }
                </Formik>

                {preChild && preChild}

            </div>

            { guardSubmit && (
                <ConfirmModal
                    open={open}
                    setOpen={setOpen}
                    doneCallback={forceSubmit}
                    cancelCallback={() => { setOpen(false); formRef.current && formRef.current.setSubmitting(false) }}
                    title={guardSubmit() ?? ''}
                    subtitle=''
                    confirm={texts.generic.valid}
                />
            )}
        </>
    );
}

export default FormWrap;