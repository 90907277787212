import { useImpacts, useObjectives, useProject } from '../../../helpers/hooks';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { formatObjectivesPostDatas } from '../../../helpers/api/objectives';

import { ProjectWrap } from '../../layouts';
import { FormWrap, ReadonlyForm } from '../../common/Forms';
import { Reminder, SageQuestions } from '../../common/Miscs';
import { Seuils, Table } from '../../common/Table';
import { CausesList, ImpactsList } from '../Impacts';
import { Collapse } from '../../common/Collapse';
import { ImpactsListHeader } from '../Impacts/ImpactsList';
import { CausesListHeader } from '../Impacts/CausesList';
import { ObjectivesList } from './index';

import { globals } from '../../../config';
import texts from '../../../assets/data/contents.json';



const Objectives = (): JSX.Element => {

    const containerRef = useRef(null);
    const { project, setProject, seuils } = useProject();
    const { initialValues: impactsValues } = useImpacts();
    const { initialValues: objectivesValues, validation } = useObjectives();

    const navigate = useNavigate();

    const onSubmit = (res: any) => {
        if (res.step_position && project) {
            setProject({ ...project, step_position: res.step_position });
            navigate(`${globals.routes.project}/${res.project_id}${globals.routes.staticSteps.actions.create}`);
        }
    };


    return (
        <ProjectWrap index={3} title={texts.objectives.title} desc={texts.objectives.desc} containerRef={containerRef}>

            <Reminder/>

            <FormWrap
                api={globals.api.steps.objectives.post}
                validation={validation}
                initial={objectivesValues}
                cta={texts.generic.continue}
                onSubmit={onSubmit}
                formatPostDatas={(datas) => formatObjectivesPostDatas(datas, seuils?.levels.length)}
                additionalParams={{ project_id: project?.id, step_position: 3 }}
                back={`${globals.routes.project}/${project?.id}${globals.routes.staticSteps.impacts}`}
            >

                { project && (<SageQuestions projectId={project.id} questions={texts.sage.objectives} stepIndex={3}/>)}

                <Table>
                    <ReadonlyForm initial={impactsValues}>
                        <Seuils containerRef={containerRef} locked/>

                        <Collapse toggle={<ImpactsListHeader containerRef={containerRef} locked={true}/>}>
                            <ImpactsList containerRef={containerRef} locked/>
                        </Collapse>

                        <Collapse toggle={<CausesListHeader containerRef={containerRef} locked={true}/>} className={"!mt-7"}>
                            <CausesList containerRef={containerRef} locked/>
                        </Collapse>
                    </ReadonlyForm>

                    <ObjectivesList containerRef={containerRef}/>
                </Table>

            </FormWrap>

        </ProjectWrap>
    )
}


export default Objectives;