import { useEffect, useState } from 'react';
import { useFetch, useProject } from './index';
import * as yup from 'yup';

import { TrajectoriesType } from '../../types';

import { globals } from '../../config';



const useTrajectories = () => {
    const { project } = useProject();

    const [initialValues, setInitialValues] = useState<TrajectoriesType>({
        step5_answer_sage_1: '',
        step5_answer_sage_2: '',
        step5_answer_sage_3: '',
        project_trajectories: []
    });

    const { data: getDatas } = useFetch<TrajectoriesType>(globals.api.steps.trajectories.get, { project_id: project?.id}, true);

    useEffect(() => {
        if (getDatas) setInitialValues(getDatas);
    }, [getDatas]);

    const validation = yup.object().shape({
        step5_answer_sage_1: yup.string(),
        step5_answer_sage_2: yup.string(),
        step5_answer_sage_3: yup.string(),
        project_trajectories: yup.array().of(yup.object()).min(1, 'Vous devez choisir au moins une trajectoire')
    });

    return { initialValues, setInitialValues, validation };
}


export default useTrajectories;
