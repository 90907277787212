import { ElementType, ReactNode } from 'react';


type Props = {
    className?: string,
    sub?: boolean,
    variant?: string,
    size?: string,
    children?: ReactNode,
    escaped?: string,
    titleScrollRef?: any,
    as?: ElementType;
}

const Title = (props: Props): JSX.Element => {

    const { as: Tag = 'h1', className, children, variant, size, escaped } = props;


    const classes = () => {
        let classes = { variant: '', size: '', base: 'font-bold' };

        switch (variant) {
            case 'blue':
                classes.variant = 'text-c-blue';
                break;
            case 'black':
                classes.variant = 'text-c-light-black';
                break;
            case 'white':
                classes.variant = 'text-white';
                break;
            default:
                break;
        }

        switch (size) {
            case 'step':
                classes.size = 'text-stepTitle';
                break;
            case 'subtitle':
                classes.size = 'text-subtitle';
                break;
            case 'small':
                classes.size = 'text-lg';
                break;
            case 'xsmall':
                classes.size = 'text-base';
                break;
            default:
                classes.size = 'text-title';
                break;
        }

        return `${classes.base} ${classes.size} ${classes.variant} ${className || ''}`;
    }


    return (
        <>
            { (children && !escaped) ? (
                <Tag className={`${classes()}`} ref={props.titleScrollRef}>{ children }</Tag>
            ) : (
                <Tag className={`${classes()}`} ref={props.titleScrollRef} dangerouslySetInnerHTML={{__html: escaped}}/>
            )}
        </>
    )
}


export default Title;