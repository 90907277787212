import { useProject } from '../../../helpers/hooks';
import { FieldArray, useFormikContext, useField } from 'formik';

import { ImpactType } from '../../../types';

import { LevelBox } from '../Miscs';
import { TableRow } from './index';
import { Tooltip } from '../Tooltip';
import { Paragraph, Title } from '../Typos';
import { TableActions, TableHead, TableLeft, TableRight } from './Table';
import { Input, Textarea } from '../Forms';
import { Button } from '../Buttons';

import { SVG } from '../../../config';
import texts from '../../../assets/data/contents.json';




type SeuilLevelProps = {
    idle?: boolean,
    idx: number,
    containerRef: any,
    locked: boolean,
    tight: boolean
}

const SeuilLevel = (props: SeuilLevelProps) => {

    const { idle, idx, containerRef, locked, tight } = props;
    const index = idle ? idx : idx + 1;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [field, meta] = useField(`seuils.levels[${index-1}]`);
    const hasDesc = field?.value !== '';

    const seuilButton = <>
        { locked ? (
            <SVG.Eye className={'stroke-c-light-cyan w-5 h-5 mb-1'}/>
        ) : (
            <SVG.Plus className={`${meta.touched && meta.error ? 'fill-red-400' : 'fill-c-light-cyan'} w-5 h-5 mb-1`}/>
        )}

        <span className={`text-xs font-bold ${meta.touched && meta.error ? 'text-red-400' : 'text-c-blue'}`}>{texts.seuils.label}</span>
    </>;

    return (
        <li className="relative w-full">
            <LevelBox index={index} className={`${!tight ? '' : 'py-4'} block w-full`}/>

            { !idle && !tight && (
                <div className="vertical-center w-12 -left-12">
                    <Tooltip
                        tooltipContainer={containerRef}
                        content={seuilButton}
                        title={texts.seuils.label}
                        color="fill-c-light-black"
                        triggerClasses="flex flex-col items-center w-full"
                        standalone
                        valid={!locked}
                        className={`${(!hasDesc && locked) ? 'opacity-25 pointer-events-none' : '' }`}
                    >
                        <Textarea
                            className="mt-3"
                            name={`seuils.levels[${index-1}]`}
                            variant="tooltip"
                            placeholder={texts.seuils.desc}
                            disabled={locked}
                        />
                    </Tooltip>
                </div>
            )}
        </li>
    )
}



type Props = {
    locked?: boolean,
    containerRef: any,
    tight?: boolean
}

const Seuils = ({ locked = false, containerRef, tight = false }: Props) => {

    const { seuils, setSeuils } = useProject();
    const { values } = useFormikContext<any>();

    const title = <>
        <Tooltip tooltipContainer={containerRef} content={<Title as="h5" size="xsmall" variant="blue">{texts.seuils.title}</Title>} color="fill-c-light-cyan">
            <Paragraph className="text-center">{texts.seuils.tooltip}</Paragraph>
        </Tooltip>
    </>;

    const addSeuil = (arrayHelpers: any) => {
        arrayHelpers.push('');
        setSeuils({...seuils, name: seuils?.name || '', levels: [...seuils?.levels || [], ''] });
    };
    const removeSeuil = (arrayHelpers: any) => {
        const arr = seuils?.levels;
        if (arr) arr.pop();

        arrayHelpers.remove(values.seuils.levels.length - 1);
        setSeuils({...seuils, name: seuils?.name || '', levels: arr ? arr : [] });
    };


    return (
        <div>
            { !tight && <TableHead title={!locked ? title : undefined} subtitle={texts.seuils.subtitle}/> }

            <div className="mt-3">
                <TableRow>

                    <TableLeft>
                        {!locked && (
                            <Input
                                name="seuils.name"
                                type="text"
                                variant="blue"
                                placeholder={texts.seuils.libelle}
                                floatErrors
                                disabled={locked}
                                showTitle
                            />
                        )}
                    </TableLeft>

                    <FieldArray name="seuils.levels" render={(arrayHelpers: any) => {
                        const length = values.seuils.levels.length;

                        return (
                            <>
                                <TableRight tight={tight}>
                                    { values.seuils.levels && length > 0 && (
                                        <ul className={`flex items-center ${ !tight ? 'space-x-12' : 'space-x-2.5'}`}>
                                            <SeuilLevel idx={0} containerRef={containerRef} idle locked={locked} tight={tight}/>

                                            { values.seuils.levels.map((seuil: ImpactType, index: number) => (
                                                <SeuilLevel idx={index} key={index} containerRef={containerRef} locked={locked} tight={tight}/>
                                            ))}
                                        </ul>
                                    )}

                                </TableRight>

                                <TableActions>
                                    {!locked && (
                                        <>
                                            { length === 2 ? (
                                                <Button className="w-seuils h-seuils" variant="light-green" size="picto" rounded onClick={() => length === 2 && addSeuil(arrayHelpers)}>
                                                    <SVG.BigPlus className="w-4 h-4"/>
                                                </Button>
                                            ) : (
                                                <Button className="w-seuils h-seuils" size="picto" inline onClick={() => length === 3 && removeSeuil(arrayHelpers)}>
                                                    <SVG.Trash className="w-7 h-7"/>
                                                </Button>
                                            )}
                                        </>
                                    )}
                                </TableActions>
                            </>
                        )
                    }}/>
                </TableRow>
            </div>
        </div>
    )

}

export default Seuils;