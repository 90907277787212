import SimpleBar from 'simplebar-react';
import { Outlet } from 'react-router-dom';

import { Logos } from '../pages/Login';
import { Title } from '../common/Typos';

import texts from '../../assets/data/contents.json';
import { assets } from "../../config";



const LoginLayout = (): JSX.Element => {
    return (
        <div className="grid grid-cols-2 h-screen">
            <SimpleBar className="max-h-screen">
                <div className="flex flex-col items-center px-8 pt-16 pb-12 lg:px-12 lg:pt-28 lg:pb-20">
                    <Logos/>

                    <Outlet/>
                </div>
            </SimpleBar>

            <div className="flex items-center justify-center p-12 text-center login-bg">
                <Title className="relative z-20 max-w-md" variant="white">
                    {texts.login.head}
                </Title>

                <img className="absolute inset-0 object-cover object-center w-full h-full" src={assets.loginBg} alt={texts.login.alt}/>
            </div>
        </div>
    );
}


export default LoginLayout;