import { useLocation, Navigate, Outlet } from 'react-router-dom';
import { isLoggedIn } from 'axios-jwt'
import { globals } from '../../config';


const RequireAuth = () => {
    const location = useLocation();

    return (
        isLoggedIn()
            ? <Outlet/>
            : <Navigate to={globals.routes.login} state={{ from: location }} replace />
    );
}

export default RequireAuth;