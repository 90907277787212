import { Link } from 'react-router-dom';
import { withFetch } from '../../../helpers/hoc';

import ProjectList from './ProjectsList';
import UserInfo from './UserInfo';
import { Button } from '../../common/Buttons';
import { Paragraph } from '../../common/Typos';

import { SVG, globals } from '../../../config';
import texts from '../../../assets/data/contents.json';


const UserWithFetch = withFetch(UserInfo, globals.api.profile);
const ProjectsWithFetch = withFetch(ProjectList, globals.api.projects);

const Dashboard = (): JSX.Element => {
    return (
        <div className="py-20 px-10 lg:px-14">

            <UserWithFetch/>

            <div className="mt-7 mb-16">
                <Button path={globals.routes.steps.create} variant="green">
                    <SVG.Plus className="w-4 mr-2.5 group-hover:fill-c-green"></SVG.Plus>
                    {texts.generic.project.create}
                </Button>

                <Paragraph className="max-w-2xl mt-8">
                    Il est impératif de s’imprégner de la méthode décrite dans le guide disponible au téléchargement depuis l'<Link to={globals.routes.tuto} className="underline hover:text-c-blue transition-colors">onglet tutoriel</Link> avant d’entamer la démarche dans l'outil web.
                </Paragraph>
            </div>

            <ProjectsWithFetch/>

        </div>
    )
}


export default Dashboard;