import { useState, useEffect } from 'react';
import { useFetch, useProject } from './index';
import { formatActionsRankGetDatas } from '../api/actionsrank';
import * as yup from 'yup';

import { ActionsRankType, ActionRankType, ActionsRankPostDatasType } from '../../types';

import { globals } from '../../config';
import texts from '../../assets/data/contents.json';



export const ActionRankBlueprint: ActionRankType = {
    id: null,
    anticipation_level: 0,
    categories: Array(8).fill(1),
    average: 1
};



const useActionsRank = () => {
    const { project } = useProject();

    const [initialValues, setInitialValues] = useState<ActionsRankType>({
        categories: Array(8).fill(1),
        rankActions: [ActionRankBlueprint, ActionRankBlueprint],
    });

    const { data: getDatas } = useFetch<ActionsRankPostDatasType>(globals.api.steps.actions.rank.get, { project_id: project?.id}, true);

    useEffect(() => {
        if (getDatas) setInitialValues(formatActionsRankGetDatas(getDatas));
    }, [getDatas]);

    const validation = yup.object().shape({
        categories: yup.array().of(yup.number().required(texts.generic.form.required)).max(8, 'Au maximum 8 catégories'),
        rankActions: yup.array().of(
            yup.object().shape({
                id: yup.number().required(texts.generic.form.required),
                anticipation_level: yup.number().required(texts.generic.form.required),
                categories: yup.array().of(yup.number().required(texts.generic.form.required)).max(8, 'Au maximum 8 catégories'),
                average: yup.number().required(texts.generic.form.required)
            })
        )
    });

    return { initialValues, validation };
}


export default useActionsRank;
