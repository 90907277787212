import { useEffect, useState } from 'react';
import { useFetch, useProject } from './index';
import * as yup from 'yup';

import { CadrageType } from '../../types';

import { globals } from '../../config';
import texts from '../../assets/data/contents.json';



const useCadrage = () => {
    const { project } = useProject();

    const [initialValues, setInitialValues] = useState<CadrageType>({
        step1_answer_diagnosis_climate_impacts: '',
        step1_answer_sage: '',
        step1_answer_strategic_objectives: ''
    });

    const { data: getDatas } = useFetch<CadrageType>(globals.api.steps.cadrage.get, { project_id: project?.id}, true);

    useEffect(() => {
        if (getDatas) setInitialValues(getDatas);
    }, [getDatas]);


    const validation = yup.object().shape({
        step1_answer_diagnosis_climate_impacts: yup.string().required(texts.generic.form.required),
        step1_answer_sage: yup.string().required(texts.generic.form.required),
        step1_answer_strategic_objectives: yup.string().required(texts.generic.form.required),
    });


    return { initialValues, validation };
}


export default useCadrage;
