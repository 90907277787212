import { ActionsCreateType } from '../../../../types';

import { Square } from '../../../common/Box';



const IncompatiblesHeader = ({ actions }: { actions: ActionsCreateType }) => {

    return (
        <div className="flex items-end mb-2.5 min-h-results">
            <ul className="flex-none flex gap-2.5 mb-2.5">

                { actions.actions.map((action, index) => (
                    <li key={index} className="w-action">
                        <Square className="text-sm !px-4 !py-4.5">
                            <span className="whitespace-nowrap overflow-ellipsis overflow-hidden">{action.name}</span>
                        </Square>
                    </li>
                ))}

            </ul>
        </div>
    )
}

export default IncompatiblesHeader;