import { useEffect, useState } from 'react';
import { useFetch } from '../../../helpers/hooks';

import { CadrageType } from '../../../types';

import { Title } from '../Typos';
import { Textarea } from '../Forms';
import { Box } from '../Box';
import { Loader } from '../Loader';

import { globals, SAGE } from '../../../config';
import texts from '../../../assets/data/contents.json';



const Sage = ({ label, name, light, small }: { label: string, name: string, light: boolean, small: boolean }) => {
    return (
        <Textarea
            label={label}
            labelSize="text-sm"
            name={name}
            variant={`${light ? 'light' : 'inner'}`}
            placeholder={texts.generic.answer}
            size={small ? 'small' : null}
        />
    )
}


type SageQuestionType = {
    questions: { index: null | number, matches: number[], label: string }[],
    stepIndex: number,
    sageIndex: number,
    light: boolean,
    small: boolean
}

const SageQuestion = ({ questions, sageIndex, stepIndex, light, small }: SageQuestionType) => {
    return (
        <Box variant="spread" type={light ? 'light' : ''}>
            { !light && (
                <Title size="subtitle" variant="blue" as="h4">{texts.generic.help}</Title>
            )}

            { questions.map((question, index) => {
                if (question.matches.includes(sageIndex))
                    return <Sage
                        key={index}
                        label={question.label}
                        name={`step${stepIndex}_answer_sage${question.index ? `_${question.index}` : ''}`}
                        light={light}
                        small={small}
                    />;
                else
                    return null;
            }) }
        </Box>
    )
}


type PropsType = {
    projectId?: number,
    stepIndex: number,
    questions: { index: null | number, matches: number[], label: string }[],
    light?: boolean,
    small?: boolean
}

const SageQuestions = ({ projectId, questions, stepIndex, light = false, small = false }: PropsType): JSX.Element => {

    const { data, error } = useFetch<CadrageType>(globals.api.steps.cadrage.get, { project_id: projectId}, true);

    const [sageIndex, setSageIndex] = useState<number | null>(null);

    useEffect(() => {
        data && setSageIndex(SAGE.indexOf(data.step1_answer_sage));
    }, [data]);

    if (error) return <p>{texts.generic.error}</p>
    if (!data) return <Loader/>


    return (
        <>
            { sageIndex !== null && questions.filter(question => question.matches.includes(sageIndex)).length > 0 && (
                <SageQuestion questions={questions} sageIndex={sageIndex} stepIndex={stepIndex} light={light} small={small}/>
            )}
        </>
    );
}


interface PlanningPropsType extends PropsType {
    cadrageValues: CadrageType
}

export const PlanningSageQuestions = ({ cadrageValues, questions, stepIndex, light = false }: PlanningPropsType): JSX.Element => {

    const [sageIndex, setSageIndex] = useState<number | null>(null);

    useEffect(() => {
        cadrageValues && setSageIndex(SAGE.indexOf(cadrageValues.step1_answer_sage));
    }, [cadrageValues]);

    return (
        <>
            { sageIndex !== null && questions.filter(question => question.matches.includes(sageIndex)).length > 0 && (
                <SageQuestion questions={questions} sageIndex={sageIndex} stepIndex={stepIndex} light={light} small={false}/>
            )}
        </>
    );
}



export default SageQuestions;