import { Fragment } from 'react';
import { FieldArray } from 'formik';
import { useProject } from '../../../helpers/hooks';

import { Input } from '../../common/Forms';
import { TableRow } from '../../common/Table';
import { ListHeader, TableActions, TableLeft, TableRight } from '../../common/Table/Table';

import texts from '../../../assets/data/contents.json';



const TemporalityField = ({ idx }: { idx: number }): JSX.Element => {
    return (
        <li className={`w-[25.3%]`}>
            <TemporalityInput index={idx+1} name={`temporality[${idx+1}]`} placeholder={texts.planning.temporality.date}/>
        </li>
    );
}

export const TemporalityListHeader = (props: any) => (
    <ListHeader
        tooltipContainer={props.containerRef}
        title={texts.planning.temporality.periods}
        tooltip={texts.planning.temporality.tooltip}
        {...props}
    />
);


const TemporalityInput = ({ index, name, placeholder }: { index: number, name: string, placeholder: string }) => {
    return (
        <Input
            className="max-h-date"
            name={name}
            type="text"
            variant="blue"
            placeholder={placeholder}
            floatErrors
        />
    )
}

const TemporalityList = ({ containerRef }: { containerRef: any }): JSX.Element => {

    const { seuils } = useProject();

    return (
        <div>
            <TemporalityListHeader containerRef={containerRef}/>

            <FieldArray name="temporality" render={() => (
                <div className="space-y-3 mt-3">
                    <TableRow>

                        <TableLeft>
                            <TemporalityInput index={0} name={"temporality[0]"} placeholder={texts.planning.temporality.initial}/>
                        </TableLeft>

                        <TableRight>
                            <ul className={`flex items-center justify-center px-[6%] ${seuils?.levels.length === 3 ? 'space-x-[18%]' : 'space-x-[6%]'}`}>

                                { seuils && seuils.levels.map((level, idx) => (
                                    <Fragment key={idx}>
                                        { idx < seuils.levels.length - 1 && (
                                            <TemporalityField idx={idx}/>
                                        )}
                                    </Fragment>
                                ))}

                            </ul>
                        </TableRight>

                        <TableActions/>

                    </TableRow>
                </div>
            )}/>
        </div>
    );
}


export default TemporalityList;