import { ProjectType } from '../../../types';

import PdfBlobGen from "./PdfBlobGen";
import PdfContent from "./PdfContent";
import { Loader } from '../Loader';
import { Button } from '../Buttons';

import texts from '../../../assets/data/contents.json';
import { SVG } from '../../../config';


type PropsType = {
    fetchData: () => void,
    data: any,
    error: boolean,
    requesting: boolean,
    project: ProjectType | null,
    attempts: number
}


const PdfCta = ({ fetchData, data, error, requesting, attempts, project }: PropsType) => {

    return (
        <>
            { !requesting && !data && !error && (
                <Button variant="blue" size="picto" onClick={() => fetchData()}>
                    <span>{texts.synthesis.generate}</span>
                    <SVG.Hand className="fill-white group-hover:fill-c-blue w-5 h-5 ml-1.5"/>
                </Button>
            )}

            { requesting && <Loader/> }

            { project && data && !requesting && !error && (
                <PdfBlobGen
                    title={project.name}
                    document={<PdfContent data={data}/>}
                />
            )}

            { !requesting && error && (
                <Button variant="blue" size="picto" onClick={() => attempts < 3 && fetchData()}>
                    {texts.generic.error}&nbsp;<br/>
                    <span>{ attempts < 3 ? <>{texts.generic.retry}</> : <>{texts.generic.later}</>}</span>
                </Button>
            )}
        </>
    );
};

export default PdfCta;