import { Paragraph } from '../Typos';

import { getRankColor } from '../../../config/globals';
import texts from '../../../assets/data/contents.json';


const Colors = (): JSX.Element => {

    const legends = [
        { color: getRankColor(4), label: texts.trajectories.ranks[0] },
        { color: getRankColor(3), label: texts.trajectories.ranks[1] },
        { color: getRankColor(2), label: texts.trajectories.ranks[2] },
        { color: getRankColor(1), label: texts.trajectories.ranks[3] },
    ];

    return (
        <>
            <Paragraph className="text-base" variant="blue" weight="bold">{texts.trajectories.results}</Paragraph>

            <ul className="pl-3.5 mt-7 space-y-7 text-sm text-c-light-black">
                { legends.map((legend, index) => (
                    <li key={index} className="flex items-center">
                        <div className="flex-none flex items-center justify-center w-step mr-5">
                            <span className={`${legend.color} w-5 h-5 rounded-full`}/>
                        </div>
                        <span>{legend.label}</span>
                    </li>
                ))}
            </ul>
        </>
    );
}


export default Colors;