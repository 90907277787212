import { useImpacts } from '../../../helpers/hooks';

import TemporalityList from './TemporalityList';
import { Paragraph, Title } from '../../common/Typos';
import { Box } from '../../common/Box';
import { ReadonlyForm } from '../../common/Forms';
import { Seuils, Table } from '../../common/Table';

import { SVG } from '../../../config';
import texts from '../../../assets/data/contents.json';



const Temporality = ({ containerRef }: { containerRef: any }) => {

    const { initialValues: impactsValues } = useImpacts();

    return (
        <div className="!mt-20">
            <Title size="step" variant="blue" as="h2" className="mb-8">{texts.planning.temporality.title}</Title>

            <Box type="inline" size="full" picto={<SVG.Info className="fill-c-light-cyan w-5 h-5"/>}>
                <Paragraph weight="bold" variant="blue" className="py-3 max-w-4xl">{texts.planning.temporality.desc}</Paragraph>
            </Box>

            <Table planning>
                <ReadonlyForm initial={impactsValues}>
                    <Seuils containerRef={containerRef} locked/>
                </ReadonlyForm>

                <TemporalityList containerRef={containerRef}/>
            </Table>
        </div>
    )

}

export default Temporality;