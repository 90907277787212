import { ReactNode } from 'react';

import { Box } from '../common/Box';
import { BackLight } from '../common/Buttons';



const ContentWrap = ({ children, size }: { children: ReactNode, size?: string }): JSX.Element => {

    return (
        <div className={`py-20 px-10 lg:px-14 mx-auto ${size === 'small' ? 'max-w-5xl' : ''} ${size === 'xsmall' ? 'max-w-3xl' : ''}`}>
            <BackLight/>

            <Box className="!p-12">
                {children}
            </Box>
        </div>
    );
}


export default ContentWrap;