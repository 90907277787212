import { FieldArray } from 'formik';
import { useFieldArray } from '../../../helpers/hooks';

import { CauseType } from '../../../types';

import { Input } from '../../common/Forms';
import { ListHeader, TableActions, TableLeft } from '../../common/Table/Table';
import { Add, Button } from '../../common/Buttons';
import { TableRow } from '../../common/Table';
import { ConfirmModal } from '../../common/Modal';

import { SVG } from '../../../config';
import texts from '../../../assets/data/contents.json';



export const CausesListHeader = (props: { locked: boolean, containerRef: any }) => (
    <ListHeader
        tooltipContainer={props.containerRef}
        title={texts.causes.title}
        tooltip={texts.causes.tooltip}
        {...props}
    />
);

const CausesList = ({ locked = false, containerRef }: { locked?: boolean, containerRef: any }): JSX.Element => {

    const { values, open, setOpen, handleRemove, doneRemove } = useFieldArray();

    return (
        <div>
            { !locked && <CausesListHeader locked={locked} containerRef={containerRef}/>}

            <FieldArray name="causes" render={(arrayHelpers: any) => (
                <>
                    <div className="space-y-3 mt-3">

                        { values.causes && values.causes.length > 0 && (
                            values.causes.map((cause: CauseType, index: number) => (

                                <TableRow key={index}>

                                    <TableLeft wide>
                                        <Input
                                            name={`causes[${index}].name`}
                                            type="text"
                                            variant="blue"
                                            placeholder={texts.causes.libelle}
                                            disabled={locked}
                                            floatErrors
                                            showTitle
                                        />
                                    </TableLeft>

                                    <TableActions>
                                        { !locked && index > 0 && (
                                            <Button className="w-seuils h-seuils" size="picto" inline onClick={() => handleRemove(index)}>
                                                <SVG.Trash className="w-6 h-6"/>
                                            </Button>
                                        )}
                                    </TableActions>

                                </TableRow>

                            ))
                        )}
                    </div>

                    {!locked && (
                        <>
                            <Add onClick={() => values.causes.length < 50 && arrayHelpers.push('')} text={texts.causes.add} disabled={values.causes.length >= 50}/>

                            <ConfirmModal title={texts.causes.delete} open={open} setOpen={setOpen} doneCallback={() => doneRemove(arrayHelpers)}/>
                        </>
                    )}
                </>
            )}/>
        </div>
    );
}


export default CausesList;