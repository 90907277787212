import { Dispatch, ReactNode, SetStateAction } from 'react';

import { Modal } from '../Modal';
import { Title } from '../Typos';
import { Portal } from '../../layouts';

import texts from '../../../assets/data/contents.json';



type PropsType = {
    children: ReactNode,
    open: boolean,
    setOpen: Dispatch<SetStateAction<boolean>>
}

const Legend = ({children, open, setOpen}: PropsType) => {

    return (
        <Portal wrapperId="legend-modal-container">
            <Modal isVisible={open} onClose={() => setOpen(false)} variant={"wide"}>
                <div className="max-w-login mr-24">
                    <Title variant={"blue"} as="h4" size="step" className="leading-8">
                        {texts.trajectories.legend}
                    </Title>

                    <div className="space-y-10 mt-10">
                        { children }
                    </div>
                </div>
            </Modal>
        </Portal>
    );

}

export default Legend;