import React, { useLayoutEffect, useRef } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import App from './components/routes';

import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';

import './assets/styles/global.css';


const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);


const Wrapper = () => {
    const { pathname } = useLocation();
    const simpleBarRef = useRef<any>(null);

    useLayoutEffect(() => {
        if (simpleBarRef?.current) {
            const scroller = simpleBarRef.current.getScrollElement();

            scroller?.scrollTo({
                top: 0,
                left: 0,
                behavior: "instant",
            });
        }
    }, [pathname]);

    return (
        <SimpleBar className="max-h-screen" ref={simpleBarRef}>
            <Routes>
                <Route path="/*" element={<App/>}/>
            </Routes>
        </SimpleBar>
    );
}


root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Wrapper/>
        </BrowserRouter>
    </React.StrictMode>
);