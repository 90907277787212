import { ReactNode } from 'react';
import { Box } from '../Box';
import { SVG } from '../../../config';


type ErrorType = {
    children: ReactNode,
    className?: string,
    full?: boolean
}

const Error = ({children, className, full}: ErrorType) => {

    return (
        <Box variant="error" type="inline" size={`${full ? 'full' : ''}`} picto={<SVG.Info className="fill-red-400 w-4.5 h-4.5"/>} className={`${full ? '!mt-10' : 'inline-block mx-4 mt-4'}`}>
            <p className={`${className || ''} italic text-red-500 text-sm`}>
                {children}
            </p>
        </Box>
    )
};


export default Error;