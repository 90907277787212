import { useState, useRef, useEffect, Dispatch, SetStateAction, RefObject } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormikContext } from 'formik';
import { scrollToRef } from '../../../helpers/helpers';
import { formatTrajectoriesPostDatas } from '../../../helpers/api/trajectories';
import {
    useImpacts,
    useActionsCreate,
    useProject,
    useObjectives,
    useActionsRank,
    useTrajectories,
    useActionsIncompatibles
} from '../../../helpers/hooks';

import { TrajectoriesType, TrajectoryType } from '../../../types';

import TrajectoriesCtas from './TrajectoriesCtas';
import TrajectoriesChart from './TrajectoriesChart';
import CheckList from './CheckList';
import { ProjectWrap } from '../../layouts';
import { FormWrap, ReadonlyForm } from '../../common/Forms';
import { Reminder, SageQuestions } from '../../common/Miscs';
import { Seuils, Table, TableRow } from '../../common/Table';
import { CausesList, ImpactsList } from '../Impacts';
import { Collapse } from '../../common/Collapse';
import { ImpactsListHeader } from '../Impacts/ImpactsList';
import { CausesListHeader } from '../Impacts/CausesList';
import { ObjectivesListHeader } from '../Objectives/ObjectivesList';
import { ObjectivesList } from '../Objectives';
import { TableActions, TableLeft, TableRight } from '../../common/Table/Table';
import { ActionsColumn } from '../Actions';
import { Button } from '../../common/Buttons';

import { globals } from '../../../config';
import texts from '../../../assets/data/contents.json';




function usePrevious(value: any) {
    const ref = useRef<TrajectoriesType>();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}


type AddTrajectoryButtonType = {
    trajectoriesValues: TrajectoriesType,
    setTrajectoriesValues: Dispatch<SetStateAction<TrajectoriesType>>,
    edit: TrajectoryType | null,
    setShow: Dispatch<SetStateAction<TrajectoryType | null>>,
    setEdit: Dispatch<SetStateAction<TrajectoryType | null>>,
    scrollRef: RefObject<HTMLDivElement>
}

const AddTrajectoryButton = ({ trajectoriesValues, setTrajectoriesValues, edit, setShow, setEdit, scrollRef }: AddTrajectoryButtonType) => {

    const { values: formikValues } = useFormikContext<any>();

    const addTrajectory = () => {
        if (edit) {
            const exists = trajectoriesValues.project_trajectories.findIndex((traj) => traj.id === edit.id);

            if (exists > -1) trajectoriesValues.project_trajectories.splice(exists, 1);

            setTrajectoriesValues({
                step5_answer_sage_1: formikValues.step5_answer_sage_1,
                step5_answer_sage_2: formikValues.step5_answer_sage_2,
                step5_answer_sage_3: formikValues.step5_answer_sage_3,
                project_trajectories: [ ...trajectoriesValues.project_trajectories, edit ]
            });

            setShow(edit);
            setEdit(null);

            scrollToRef(scrollRef);
        }
    }

    return (
        <>
            { edit && (
                <Button
                    type="button"
                    size="slim"
                    onClick={() => addTrajectory()}
                    disabled={edit.name === '' || edit.project_trajectory_actions.length === 0}
                >
                    {texts.generic.valid}
                </Button>
            )}
        </>
    )
}


const Trajectories = (): JSX.Element => {

    const containerRef = useRef(null);
    const { project, setProject } = useProject();
    const scrollRef = useRef<HTMLDivElement>(null);

    const [edit, setEdit] = useState<TrajectoryType | null>(null);
    const [show, setShow] = useState<TrajectoryType | null>(null);

    const navigate = useNavigate();

    const { initialValues: impactsValues } = useImpacts();
    const { initialValues: objectivesValues } = useObjectives();
    const { initialValues: actionsValues } = useActionsCreate();
    const { initialValues: rankValues } = useActionsRank();
    const { initialValues: incompatiblesValues } = useActionsIncompatibles();
    const { initialValues: trajectoriesValues, setInitialValues: setTrajectoriesValues, validation } = useTrajectories();

    const prevValues = usePrevious(trajectoriesValues);

    const onSubmit = (res: any) => {
        if (res.step_position && project) {
            setProject({ ...project, step_position: res.step_position });
            navigate(`${globals.routes.project}/${res.project_id}${globals.routes.staticSteps.planning}`);
        }
    };

    // set select value after sucessfull get
    useEffect(() => {
        if (prevValues && prevValues['project_trajectories'].length === 0 && trajectoriesValues['project_trajectories'].length > 0) {
            setShow(trajectoriesValues['project_trajectories'][0]);
        }
    }, [prevValues, trajectoriesValues])

    const cancelAdd = () => {
        setEdit(null);
        scrollToRef(scrollRef);
    }


    return (
        <ProjectWrap
            index={5}
            title={texts.trajectories.title}
            desc={texts.trajectories.desc}
            titleScrollRef={scrollRef}
            containerRef={containerRef}
        >

            <Reminder/>

            <FormWrap
                api={globals.api.steps.trajectories.post}
                validation={validation}
                initial={trajectoriesValues}
                cta={texts.generic.continue}
                onSubmit={onSubmit}
                formatPostDatas={formatTrajectoriesPostDatas}
                additionalParams={{ project_id: project?.id, step_position: 5 }}
                back={`${globals.routes.project}/${project?.id}${globals.routes.staticSteps.actions.incompatible}`}
                hideCtas={!!edit}
            >

                <TrajectoriesCtas
                    trajectoriesValues={trajectoriesValues}
                    setTrajectoriesValues={setTrajectoriesValues}
                    show={show}
                    edit={edit}
                    setShow={setShow}
                    setEdit={setEdit}
                />

                <Table className="pl-9">
                    <ReadonlyForm initial={impactsValues}>
                        <Seuils containerRef={containerRef} locked tight/>

                        <Collapse toggle={<ImpactsListHeader containerRef={containerRef} locked={true}/>}>
                            <ImpactsList containerRef={containerRef} locked tight/>
                        </Collapse>

                        <Collapse toggle={<CausesListHeader containerRef={containerRef} locked={true}/>} className={"!mt-7"}>
                            <CausesList containerRef={containerRef} locked/>
                        </Collapse>
                    </ReadonlyForm>

                    <ReadonlyForm initial={objectivesValues}>
                        <Collapse toggle={<ObjectivesListHeader containerRef={containerRef} locked={true}/>}>
                            <ObjectivesList containerRef={containerRef} locked tight/>
                        </Collapse>
                    </ReadonlyForm>

                    <TableRow>
                        <TableLeft>
                            <ActionsColumn
                                actions={actionsValues}
                                ranks={rankValues}
                                containerRef={containerRef}
                                trajectories
                                tooltipContent={texts.actions.create.form.tooltip}
                            />
                        </TableLeft>

                        <TableRight className="relative" tight>
                            <TrajectoriesChart
                                edit={edit}
                                show={show}
                                actions={actionsValues.actions}
                                ranks={rankValues.rankActions}
                                incompatibles={incompatiblesValues.project_action_incompatibilities}
                            />

                            { edit && (
                                <CheckList
                                    actions={actionsValues.actions}
                                    edit={edit}
                                    setEdit={setEdit}
                                    incompatibles={incompatiblesValues.project_action_incompatibilities}
                                />
                            )}
                        </TableRight>

                        <TableActions/>
                    </TableRow>

                </Table>

                { edit && (
                    <div className="text-center !mt-12 space-x-2.5">
                        <Button type="button" variant="back" size="slim" onClick={() => cancelAdd()}>
                            {texts.generic.cancel}
                        </Button>

                        <AddTrajectoryButton
                            trajectoriesValues={trajectoriesValues}
                            setTrajectoriesValues={setTrajectoriesValues}
                            edit={edit}
                            setShow={setShow}
                            setEdit={setEdit}
                            scrollRef={scrollRef}
                        />
                    </div>
                )}

                { project && (
                    <div className="!mt-28">
                        <SageQuestions projectId={project.id} questions={texts.sage.trajectories} stepIndex={5} small/>
                    </div>
                )}

            </FormWrap>
        </ProjectWrap>
    )
}


export default Trajectories;