import Tooltip from "rc-tooltip";
import 'rc-tooltip/assets/bootstrap_white.css';

import { useState, useRef, ReactNode, ReactElement } from 'react';
import { useOnClickOutside } from 'usehooks-ts'

import { assets, SVG } from '../../../config';
import texts from '../../../assets/data/contents.json';
import { Button } from '../Buttons';


type PropsType = {
    content?: ReactNode,
    title?: string,
    children: ReactElement | undefined,
    color?: string,
    className?: string,
    standalone?: boolean,
    triggerClasses?: string,
    tooltipContainer?: any,
    valid?: boolean
}


const RCTooltip = (props: PropsType) => {
    const { content, title, children, color, className, standalone = false, triggerClasses, tooltipContainer, valid } = props;
    const [visible, setVisible] = useState(false);
    const ref = useRef<HTMLDivElement>(null);

    const handleClickOutside = () => {
        setVisible(false);
    }
    useOnClickOutside(ref, handleClickOutside)


    const wrap = <div className="pt-4 px-4.5 pb-6 max-w-xs" ref={ref}>
        <div className="flex items-center justify-between mb-1">
            {title && (
                <span className="block text-black text-base font-bold mr-3">{title}</span>
            )}
            <button onClick={() => setVisible(false)} className="flex-shrink-0 ml-auto p-1 hover:opacity-60 transition-opacity duration-200">
                <img src={assets.picto.cross} alt={texts.generic.close}/>
            </button>
        </div>

        {children}

        { valid && (
            <div className="text-center mt-3 -mb-1">
                <Button size="slim" variant="light-green" onClick={() => setVisible(!visible)}>
                    {texts.generic.valid}
                    <span className="rounded-full bg-c-green h-5 w-5 flex items-center justify-center ml-2.5"><SVG.Check/></span>
                </Button>
            </div>
        )}
    </div>


    return (
        <div className={`${className || ''} ${!standalone ? 'inline-flex items-center justify-center' : ''}`}>
            { !standalone && content && (<>{content}</>)}

            <Tooltip
                getTooltipContainer={() => (tooltipContainer && tooltipContainer.current) ? tooltipContainer.current : document.body }
                overlayClassName={visible ? 'opacity-100' : undefined}
                visible={visible}
                overlay={wrap}
                trigger="click"
                placement="top"
            >
                <button type="button" onClick={() => setVisible(!visible)} className={`${triggerClasses || ''} p-1 cursor-pointer hover:opacity-60 transition-opacity duration-200`}>
                    { standalone && content && (<>{content}</>)}
                    { !standalone && (<SVG.Tooltip className={`${color ?? ''}`}/>)}
                </button>
            </Tooltip>
        </div>
    )

}

export default RCTooltip;