import { ObjectivesPostDatasType, ObjectivesType } from '../../types';


export const formatObjectivesGetDatas = (datas: ObjectivesPostDatasType, levels: number) => {

    const getDatas: ObjectivesType = {
        step3_answer_sage_1: datas.step3_answer_sage_1,
        step3_answer_sage_2: datas.step3_answer_sage_2,
        objectives: [
            datas.step3_objective_level1_description,
            datas.step3_objective_level2_description,
            datas.step3_objective_level3_description,
        ]
    }

    if (levels === 4) getDatas.objectives.push(datas.step3_objective_level4_description);

    return getDatas;
}


export const formatObjectivesPostDatas = (datas: ObjectivesType, levels: number | undefined) => {

    const postDatas: ObjectivesPostDatasType = {
        step3_answer_sage_1: datas.step3_answer_sage_1,
        step3_answer_sage_2: datas.step3_answer_sage_2,
        step3_objective_level1_description: datas.objectives[0],
        step3_objective_level2_description: datas.objectives[1],
        step3_objective_level3_description: datas.objectives[2],
        step3_objective_level4_description: (levels === 4) ? datas.objectives[3] : '',
    }

    return postDatas;
}