import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { ActionType, IncompatiblesType, TrajectoryType } from '../../../types';



type CheckerType = {
    actions:  ActionType[],
    actionId: number | null,
    edit: TrajectoryType | null,
    setEdit: Dispatch<SetStateAction<TrajectoryType | null>>,
    disabled: boolean
}

const Checker = (props: CheckerType) => {

    const { actions, actionId, edit, setEdit, disabled } = props;
    const [checked, setChecked] = useState(false);

    useEffect(() => {
        const exists = edit?.project_trajectory_actions.find((i) => i.project_action_id === actionId);
        exists && setChecked(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [edit])

    useEffect(() => {
        if (edit) {
            if (checked && actionId) {

                const exists = edit.project_trajectory_actions.findIndex((traj) => traj.project_action_id === actionId);
                if (exists > -1) edit.project_trajectory_actions.splice(exists, 1);

                const filtered = actions.filter((action) => edit.project_trajectory_actions.find((i) => i.project_action_id === action.id) || action.id === actionId);
                const insertionIndex = filtered.findIndex((i) => i.id === actionId);
                const spliced = edit.project_trajectory_actions;

                spliced.splice(insertionIndex, 0, { project_action_id: actionId });

                setEdit({
                    ...edit,
                    project_trajectory_actions: spliced
                });
            } else {
                setEdit({
                    ...edit,
                    project_trajectory_actions: edit.project_trajectory_actions.filter((i) => i.project_action_id !== actionId)
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checked]);


    return (
        <label className={`checkbox-trajectory ${disabled ? 'disabled' : ''}`} htmlFor={`check-${actionId}`}>
            <input
                className="checkbox-input peer"
                id={`check-${actionId}`}
                type="checkbox"
                onChange={() => !disabled && setChecked(!checked)}
                checked={checked}
            />

            <span className="checkbox-label"/>
        </label>
    );
}


export const checkActionDisabled = (incompatibles: IncompatiblesType[], edit: TrajectoryType | null, actionId: number | null): boolean => {
    if (edit) {
        const matches = incompatibles.filter((i) => {
                return edit?.project_trajectory_actions.filter((j) =>
                    (j.project_action_id === i.project_action_1_id) || (j.project_action_id === i.project_action_2_id)
                ).length > 0;
            }
        );

        return matches.filter((i) =>
            (i.project_action_1_id === actionId || i.project_action_2_id === actionId) &&
            (edit?.project_trajectory_actions.filter((k) =>
                (k.project_action_id === actionId)).length === 0)
        ).length > 0;
    }

    return false;
}



type PropsType = {
    incompatibles: IncompatiblesType[],
    actions: ActionType[],
    edit: TrajectoryType | null,
    setEdit: Dispatch<SetStateAction<TrajectoryType | null>>
};

const CheckList = ({ incompatibles, actions, edit, setEdit }: PropsType) => {

    return (
        <ul className="absolute -left-11 top-[2.313rem] flex flex-col space-y-[10px]">
            { actions.map((action, index) => (
                <li key={index}>
                    <Checker
                        actions={actions}
                        actionId={action.id}
                        edit={edit}
                        setEdit={setEdit}
                        disabled={checkActionDisabled(incompatibles, edit, action.id)}
                    />
                </li>
            ))}
        </ul>
    )
}

export default CheckList;