import { Dispatch, ReactNode, SetStateAction } from 'react';

import { Portal } from '../../layouts';
import { Modal } from './index';
import { Title } from '../Typos';
import { Button } from '../Buttons';

import texts from '../../../assets/data/contents.json';


type ModalProps = {
    open: boolean,
    setOpen: Dispatch<SetStateAction<boolean>>,
    doneCallback: () => void,
    cancelCallback?: () => void,
    title: string,
    subtitle?: string | ReactNode,
    confirm?: string
}


const ConfirmModal = ({ open, setOpen, doneCallback, cancelCallback, title, subtitle, confirm }: ModalProps) => {

    return (
        <Portal wrapperId="modal-container">
            <Modal isVisible={open} onClose={cancelCallback ?? (() => setOpen(false))}>
                <div className="text-center">
                    <Title as="h4" size="small" className="leading-8">
                        <span dangerouslySetInnerHTML={{__html: title}}/><br/>
                        {subtitle ?? texts.generic.warning}
                    </Title>

                    <div className="flex items-center justify-center flex-wrap mt-4 -mx-1.5 -mb-1.5">
                        <Button className="m-1.5" onClick={doneCallback}>{confirm ?? texts.generic.delete}</Button>
                        <Button variant="back" className="m-1.5" onClick={cancelCallback ?? (() => setOpen(false))}>{texts.generic.cancel}</Button>
                    </div>
                </div>
            </Modal>
        </Portal>
    );
}

export default ConfirmModal;