import { ReactNode, useRef } from 'react';
import { useFetch, usePdf, useProject } from '../../../helpers/hooks';

import { CauseType, ImpactsPostDatasType, SynthesisLevelType, SynthesisType } from '../../../types';

import { ProjectWrap } from '../../layouts';
import { Loader } from '../../common/Loader';
import { LevelBox } from '../../common/Miscs';
import { Box } from '../../common/Box';
import { Title } from '../../common/Typos';
import { Button } from '../../common/Buttons';
import { PdfCta } from '../../common/Pdf';

import texts from '../../../assets/data/contents.json';
import { globals } from '../../../config';




const SynthesisBlock = ({ title, children }: { title: string, children: ReactNode }) => (
    <div className="flex-1 pt-5 pl-5 pr-4 pb-7 rounded-box shadow-c-shadow space-y-5 flex flex-col">
        <Title as="h4" size="subtitle" variant="black">{title}</Title>

        { children }
    </div>
);

const SynthesisBox = ({ title, children, background }: { title: string, children: ReactNode, background: string }) =>  (
    <div className={`flex-1`}>
        <Box className={`${background} space-y-2.5 !px-4.5 !py-3 h-full`}>
            <Title as="h5" size="xsmall" variant="black">{title}</Title>

            <ul className="text-sm list-disc pl-4">
                { children }
            </ul>
        </Box>
    </div>
);


type PropsType = { level: SynthesisLevelType, causes: CauseType[], levelIndex: number };

const SynthesisLevel = ({ level, causes, levelIndex }: PropsType) => (
    <>
        <LevelBox index={levelIndex} className="min-w-level mb-7"/>

        <div className="flex gap-4">
            <SynthesisBlock title={texts.synthesis.problems}>

                { level.impacts.length > 0 && (
                    <SynthesisBox title={texts.synthesis.impacts} background={"bg-c-synthesis-impacts"}>

                        { level.impacts.map((impact, index) => (
                            <li key={index}>
                                { impact.name }
                                <span className="block text-gray-600 mt-0.5 mb-2">
                                    { impact[`level${levelIndex + 1}_description` as keyof ImpactsPostDatasType]}
                                </span>
                            </li>
                        )) }

                    </SynthesisBox>
                )}

                { causes.length > 0 && (
                    <SynthesisBox title={texts.synthesis.causes} background={"bg-c-synthesis-causes"}>

                        { causes.map((cause, index) => (
                            <li key={index}>{cause.name}</li>
                        )) }

                    </SynthesisBox>
                )}

            </SynthesisBlock>

            <SynthesisBlock title={texts.synthesis.solutions}>
                { level.objective && (
                    <SynthesisBox title={texts.synthesis.objectives} background={"bg-c-synthesis-objectives"}>

                        <li>{level.objective}</li>

                    </SynthesisBox>
                )}

                { level.actions.length > 0 && (
                    <SynthesisBox title={texts.synthesis.actions} background={"bg-c-synthesis-actions"}>

                        { level.actions.map((action, index) => (
                            <li key={index}>{action.name}</li>
                        )) }

                    </SynthesisBox>
                )}

            </SynthesisBlock>

        </div>
    </>
);


const Synthesis = (): JSX.Element => {

    const containerRef = useRef(null);
    const { project } = useProject();
    const { fetchData, data: pdfData, error: pdfError, requesting, attempts } = usePdf();

    const { data, error } = useFetch<SynthesisType>(globals.api.steps.synthesis.get, { project_id: project?.id}, true);

    const PdfCtaFrag = <>
        <PdfCta fetchData={fetchData} data={pdfData} error={pdfError} requesting={requesting} attempts={attempts} project={project}/>
    </>;

    const cta = <>
        <div className="min-w-trajectories min-h-anticipate flex items-center justify-end">
            {PdfCtaFrag}
        </div>
    </>;


    if (error) return <p>{texts.generic.error}</p>
    if (!data) return <Loader/>


    return (
        <ProjectWrap
            index={7}
            title={texts.synthesis.title}
            desc={texts.synthesis.desc}
            containerRef={containerRef}
            cta={cta}
        >

            <ul className="mt-24 space-y-20">

                { data && data.levels.map((level, index) => (
                    <li key={index}>
                        <SynthesisLevel level={level} causes={data.causes} levelIndex={index}/>
                    </li>
                ))}

            </ul>

            <div className="text-center mt-24 mb-6 space-x-3 flex items-center justify-center">
                <Button type="button" variant="back" size="slim" path={globals.routes.index}>
                    {texts.generic.end}
                </Button>

                {PdfCtaFrag}
            </div>

        </ProjectWrap>
    )
}


export default Synthesis;