import { ReactNode } from 'react';


type PropsType = {
    children: ReactNode,
    className?: string,
}


const Square = (props: PropsType) => {
    const { children, className } = props;

    return (
        <div className={`${className || ''} text-white font-bold text-center p-2.5 leading-tight bg-c-blue rounded-sm flex items-center justify-center`}>
            {children}
        </div>
    )

}

export default Square;