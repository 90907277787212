import { Dispatch, SetStateAction } from 'react';

import { RadioQuestionType } from '../../../types';

import { Paragraph } from '../Typos';
import { Radio } from './index';



interface RadioGroupProps extends RadioQuestionType {
    spread: boolean,
    setSelected: Dispatch<SetStateAction<number | null>>
    selected: number | null,
    className: string,
    locked: boolean
}

const RadioGroup = (props: RadioGroupProps): JSX.Element => {
    const { spread, label, name, choices, setSelected, selected, className, locked } = props;


    return (
        <div className={`${className || ''}`}>
            <Paragraph id={`${name}-group`} variant="blue" weight="bold">{label}</Paragraph>

            <div role="group" aria-labelledby={`${name}-group`} className={`${spread ? 'flex-row space-x-7' : 'flex-col space-y-7'} ${locked ? 'grayscale' : ''} flex items-start m-4`}>
                {choices.map((choice, idx) => (
                    <Radio label={choice.label} name={name} value={choice.label} key={idx} idx={idx} setSelected={setSelected} selected={selected}/>
                ))}
            </div>
        </div>
    );
}

export default RadioGroup;