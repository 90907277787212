import { useField } from 'formik';
import Error from './Error';
import { Label } from '../Typos';



const Checkbox = ({ className, label, action, error, ...props }: any) => {
    const [field, meta] = useField({ ...props, type: 'checkbox' });

    return (
        <div className={`${className || ''} ${action ? 'action' : ''}`}>
            <Label checkbox>
                <input type="checkbox" {...field} {...props} className="checkbox-input peer"/>
                <span className={`checkbox-label ${error ? 'error' : ''}`}>{label}</span>
            </Label>

            { meta.touched && meta.error && !action ? (
                <Error>{meta.error}</Error>
            ) : null}
        </div>
    );
};

export default Checkbox;