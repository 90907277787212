import * as yup from 'yup';

import { FormWrap, Input } from '../../common/Forms';

import { globals } from '../../../config';
import texts from '../../../assets/data/contents.json';



const Forgotten = (): JSX.Element => {

    const validation = yup.object({
        email: yup.string().email(texts.generic.form.email).required(texts.generic.form.required),
    });

    const initial = { email: '' };

    return (
        <FormWrap
            api={globals.api.forget}
            validation={validation}
            initial={initial}
            title={texts.login.password.forgotten}
            cta={texts.generic.valid}
            successContent={texts.login.password.mailsent}
            className={"max-w-login"}
            back={globals.routes.login}
            isLoginTunnel={true}
            reset
        >
            <Input
                label={texts.login.mail.label}
                name="email"
                type="email"
                placeholder={texts.login.mail.placeholder}
            />
        </FormWrap>
    );
}


export default Forgotten;