import React from 'react';
import { Outlet } from 'react-router-dom';
import { Menu } from './index';


const MainLayout = (): JSX.Element => {
    return <>
        <Menu/>

        <div className="pl-menu">
            <Outlet/>
        </div>
    </>
}

export default MainLayout;