import { Page, Text, View, Document, StyleSheet, Font, Image, Svg, Line, Circle } from '@react-pdf/renderer';
import { Fragment, ReactNode } from 'react';
import { format } from 'date-fns';

import {
    ActionRankDataType,
    ActionType,
    CauseType, ImpactsPostDatasType,
    SvgGroupType,
    SynthesisLevelType, TrajectoryActionType,
    TrajectoryType
} from '../../../types';

import { GenJointure, getMiddleLine } from '../../pages/Trajectories/TrajectoriesChart';


import texts from '../../../assets/data/contents.json';

import Logo from '../../../assets/images/logos/logo.jpg';
import Cover from '../../../assets/images/medias/pdf.jpg';
import Legend from '../../../assets/images/medias/legend.png';
import LegendDeploy from '../../../assets/images/medias/deploy.png';

import DMSansRegular from '../../../assets/fonts/DMSans-Regular/DMSans-Regular.ttf'
import DMSansMedium from '../../../assets/fonts/DMSans-Medium/DMSans-Medium.ttf'
import DMSansBold from '../../../assets/fonts/DMSans-Bold/DMSans-Bold.ttf'


Font.register({
    family: 'DMSans',
    fonts: [
        { src: DMSansRegular },
        { src: DMSansMedium, fontWeight: 'medium' },
        { src: DMSansBold, fontWeight: 'bold' }
    ]
})

const blue = '#0C55A2';

const rankColors = {
    none: '#DADADA',
    low: '#F46112',
    medium: '#F2A430',
    good: '#F2CE30',
    high: '#2BC062'
}

const getRankColor = (average: number) => {
    switch (average) {
        case 0:
            return rankColors.none;
        case 1:
            return rankColors.low;
        case 2:
            return rankColors.medium;
        case 3:
            return rankColors.good;
        case 4:
            return rankColors.high;
        default:
            return rankColors.none;
    }
}

const styles = StyleSheet.create({
    page: { fontFamily: 'DMSans', backgroundColor: 'white', padding: '125 45 55', letterSpacing: '0.015', position: 'relative' },
    trajectoryPage: { fontFamily: 'DMSans', backgroundColor: 'white', padding: '115 25 40', letterSpacing: '0.015', position: 'relative' },
    fixedWrap: { position: 'absolute', left: 38, top: 25, right: 38, bottom: 42 },
    headWrap: { display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' },
    logo: { width: 118, height: 53, marginBottom: 10 },
    headTitle: { maxWidth: 550, fontFamily: 'DMSans', fontSize: 16, color: blue, fontWeight: 700, marginLeft: 50 },
    coverContainer: { minHeight: '100%', display: 'flex', flexDirection: 'column' },
    coverTitle: { fontSize: 37, lineHeight: 1.11, color: blue, fontWeight: 700, marginBottom: 30 },
    coverDesc: { fontSize: 14, lineHeight: 1.57, color: blue, fontWeight: 500, marginBottom: 45, maxWidth: 400 },
    coverImg: { width: 337, height: 431, marginTop: 'auto', marginBottom: 5 },
    dateWrap: { position: 'absolute', bottom: 35, right: -10 },
    date: { transform: 'rotate(90deg)', width: 1, fontSize: 10, lineHeight: 1.83, color: blue },
    level: { textAlign: 'center', borderRadius: 4, fontWeight: 700, color: 'white', padding: '11', maxWidth: 145, width: '100%', marginBottom: 25, fontSize: 15 },
    synthesisWrap: { marginTop: 10, marginBottom: 30 },
    synthesisCols: { marginHorizontal: -5, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start' },
    synthesisBlock: { width: '100%', height: '100%', marginHorizontal: 5, padding: '18', borderRadius: 10, border: '1 solid #8c8c8c' },
    title: { color: 'rgba(50, 50, 50, 0.9)', fontSize: 19, fontWeight: 700 },
    subtitle: { color: 'rgba(50, 50, 50, 0.9)', fontSize: 14, fontWeight: 700, marginBottom: 7 },
    boxWrap: { marginTop: 18 },
    box: { borderRadius: 10, padding: '15' },
    list: { fontSize: 12, paddingLeft: 16 },
    listDisc: { marginRight: 5, flexGrow: 0, flexShrink: 0 },
    listContent: { flexGrow: 1, flexShrink: 1 },
    listWrap: { display: 'flex', flexDirection: 'row', marginBottom: 3 },
    impactDesc: { fontSize: 11, color: '#4b5563', marginTop: 3, marginBottom: 6 },
    pageNumber: { position: 'absolute', fontSize: 10, bottom: -20, left: 0, right: 0, textAlign: 'center', color: 'grey' },
    trajectoryTitle: { fontFamily: 'DMSans', fontSize: 20, color: blue, fontWeight: 700, marginBottom: 35 },
    actionsList: { display: 'flex', flexDirection: 'column', width: '100%', maxWidth: 200 },
    actionWrap: { display: 'flex', alignItems: 'center', flexDirection: 'row' },
    actionItem: { padding: '11 9', border: `1 solid ${blue}`, borderRadius: 4, width: '100%' },
    actionName: { color: blue, fontSize: 11, fontWeight: 700, width: '100%', textOverflow: 'ellipsis', maxLines: 1 },
    trajectoryOuter: { marginTop: 30, marginBottom: 55 },
    trajectoryWrap: { display: 'flex', flexDirection: 'row', marginTop: 12 },
    svgWrap: { flexGrow: 1, paddingHorizontal: 20 },
    trajectoryLevels: { display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: 215, marginRight: 20, marginBottom: 25 },
    trajectoryLevel: { textAlign: 'center', alignItems: 'center', justifyContent: 'center', borderRadius: 4, fontWeight: 700, color: 'white', padding: '12 8', flexGrow: 1, width: '100%', fontSize: 14, marginHorizontal: 5 },
    averageWrap: { width: 25, height: 25, display: 'flex', alignItems: 'center', justifyContent: 'center' },
    average: { width: 13, height: 13, borderRadius: '100%' },
    detailsWrap: { marginTop: 52, paddingVertical: 18, paddingHorizontal: 23, borderRadius: 10, backgroundColor: 'rgba(12, 85, 162, 0.05)' },
    detailsTitle: { fontFamily: 'DMSans', fontSize: 14, color: blue, fontWeight: 500, marginBottom: 16 },
    detailName: { fontSize: 12, color: blue, fontWeight: 500, flexGrow: 1, flexShrink: 1 },
    detailDesc: { fontSize: 11, marginTop: 1, marginLeft: 11 },
    legendColumns: { marginTop: 30, display: 'flex', flexDirection: 'row' },
    legendWrap: {  paddingVertical: 18, paddingHorizontal: 23, borderRadius: 10, backgroundColor: 'rgba(12, 85, 162, 0.05)', maxWidth: 340 },
    legendOuter: { padding: 14, borderRadius: 10, backgroundColor: 'rgba(12, 85, 162, 0.05)', maxWidth: 200 },
    legendLogo: { width: 25, height: 25 },
});



const SynthesisBlock = ({ title, children }: { title: string, children: ReactNode }) => (
    <View style={styles.synthesisBlock}>
        <Text style={styles.title}>{title}</Text>

        { children }
    </View>
);
const SynthesisBox = ({ title, children, bgIndex }: { title: string, children: ReactNode, bgIndex: number }) =>  {
    const backgrounds = ['rgba(242, 164, 48, 0.15)', 'rgba(242, 164, 48, 0.05)', 'rgba(12, 85, 162, 0.15)', 'rgba(12, 85, 162, 0.05)'];

    return (
        <View style={ styles.boxWrap }>
            <View style={[styles.box, { backgroundColor: backgrounds[bgIndex]}]}>
                <Text style={styles.subtitle}>{title}</Text>

                <View style={styles.list}>
                    { children }
                </View>
            </View>
        </View>
    )
}
const LevelBox = ({ index, levelStyle }: { index: number, levelStyle: object }) => {
    const colors = ['#FFC978', '#F2A430', '#EF8211', '#F46112'];

    return (
        <View style={[levelStyle, { backgroundColor: colors[index] }]}>
            <Text>Niveau {index + 1}</Text>
        </View>
    )
}

const SynthesisSection = ({ level, causes, levelIndex }: { level: SynthesisLevelType, causes: CauseType[], levelIndex: number }) => (
    <View style={styles.synthesisWrap}>
        <LevelBox levelStyle={styles.level} index={levelIndex}/>

        <View style={styles.synthesisCols}>
            <SynthesisBlock title={texts.synthesis.problems}>
                { level.impacts.length > 0 && (
                    <SynthesisBox title={texts.synthesis.impacts} bgIndex={0}>

                        { level.impacts.map((impact, index) => (
                            <View key={index} style={styles.listWrap}>
                                <Text style={styles.listDisc}>•</Text>
                                <View style={styles.listContent}>
                                    <Text>{ impact.name }</Text>
                                    <Text style={styles.impactDesc}>{ impact[`level${levelIndex + 1}_description` as keyof ImpactsPostDatasType]}</Text>
                                </View>
                            </View>
                        )) }

                    </SynthesisBox>
                )}

                { causes.length > 0 && (
                    <SynthesisBox title={texts.synthesis.causes} bgIndex={1}>

                        { causes.map((cause, index) => (
                            <View key={index} style={styles.listWrap}>
                                <Text style={styles.listDisc}>•</Text>
                                <Text style={styles.listContent}>{cause.name}</Text>
                            </View>
                        )) }

                    </SynthesisBox>
                )}
            </SynthesisBlock>

            <SynthesisBlock title={texts.synthesis.solutions}>
                { level.objective && (
                    <SynthesisBox title={texts.synthesis.objectives} bgIndex={2}>

                        <View style={styles.listWrap}>
                            <Text style={styles.listDisc}>•</Text>
                            <Text style={styles.listContent}>{level.objective}</Text>
                        </View>

                    </SynthesisBox>
                )}

                { level.actions.length > 0 && (
                    <SynthesisBox title={texts.synthesis.actions} bgIndex={3}>

                        { level.actions.map((action, index) => (
                            <View key={index} style={styles.listWrap}>
                                <Text style={styles.listDisc}>•</Text>
                                <Text style={styles.listContent}>{action.name}</Text>
                            </View>
                        )) }

                    </SynthesisBox>
                )}
            </SynthesisBlock>
        </View>

    </View>
);


const PdfPage = ({ data, children, pageStyles, orientation }: { data: any, children: ReactNode, pageStyles: object, orientation: any }) =>  (
    /*Landscape A4 width*/
    <Page size={{ width: 841.89 }} style={pageStyles} orientation={orientation}>
        <PageHeader data={data}>
            <Text style={styles.headTitle}>{data.project.name}</Text>
        </PageHeader>

        {children}
    </Page>

);


const PageHeader = ({ data, children, cover = false }: { data: any, children?: ReactNode, cover?: boolean }) => {
    const formatedDate = format(new Date(data.project.created_at), "dd/MM/yyyy");

    return (
        <View style={styles.fixedWrap} fixed>
            <View style={styles.headWrap}>
                <Image src={Logo} style={styles.logo}/>

                {children && children}
            </View>

            { cover && (
                <View style={styles.dateWrap}>
                    <Text style={styles.date}>{formatedDate}</Text>
                </View>
            )}

            <Text style={styles.pageNumber} fixed render={({pageNumber, totalPages}) => (
                `${pageNumber} / ${totalPages}`
            )}/>
        </View>
    )
}




type ChartJoinType = {
    trajectory: TrajectoryType | null,
    datas: SvgGroupType[],
    actions: TrajectoryActionType[] | undefined,
    totalRows: number,
    svgHeight: number,
    levels: number | undefined
}

const PdfChartJoin = ({ trajectory, datas, actions, totalRows, svgHeight, levels }: ChartJoinType) => {

    const selectedIds = actions?.map((action) => action.project_action_id);

    return (
        <>
            { datas.map((firstAction: any, mainIndex: number) => {
                return (
                    <Fragment key={mainIndex}>
                        { datas.map((secondAction: any, innerIndex: number) => {

                            const pairExists = [firstAction.id, secondAction.id].every(value => selectedIds?.includes(value));

                            if (firstAction.id !== secondAction.id && mainIndex < innerIndex && pairExists) {

                                const curr = datas.find((data) => data.id === firstAction.id);
                                const currIndex = datas.findIndex((data) => data.id === firstAction.id);
                                const next = datas.find((data) => data.id === secondAction.id);
                                const nextIndex = datas.findIndex((data) => data.id === secondAction.id);

                                if (curr && next) {

                                    const { sharedLevels, currMiddle, nextMiddle } = GenJointure(
                                        levels,
                                        firstAction,
                                        secondAction,
                                        currIndex,
                                        nextIndex,
                                        svgHeight,
                                        totalRows
                                    );
                                    const color = trajectory?.color;


                                    return (
                                        <Fragment key={innerIndex}>
                                            { sharedLevels.map((level: boolean, index: number) => {
                                                    if (level && levels) {

                                                        const stepWidth = 552 / levels;
                                                        const startX = index * stepWidth;

                                                        return (
                                                            <Fragment key={index}>
                                                                <Line
                                                                    x1={startX}
                                                                    x2={startX}
                                                                    y1={`${currMiddle}%`}
                                                                    y2={`${nextMiddle}%`}
                                                                    strokeWidth={2.5}
                                                                    stroke={color}
                                                                />
                                                            </Fragment>
                                                        )
                                                    }
                                                    return null;
                                                }
                                            )}
                                        </Fragment>
                                    )
                                }
                            }
                            return null;
                        } )}
                    </Fragment>
                )
            })}
        </>
    )
}


const PdfChartLine = ({ trajectory, data, index, totalRows, svgHeight, levels }: any) => {

    const { middle } = getMiddleLine(svgHeight, totalRows, index);
    const color = trajectory?.color;

    return (
        <>
            { !isNaN(middle) && levels && [...Array(levels)].map((segment, index) => {

                const firstTrue = data.levels.indexOf(true);
                const actionIsActive = data.levels[index];

                const anticipateTreshold = ( (data.anticipation - 1 < 0) ? 4 : (data.anticipation - 1) );
                const isAnticipate = !actionIsActive && (index >= anticipateTreshold) && (index < firstTrue);

                const stepWidth = 552 / levels;

                const startX = index * stepWidth;
                const endX = (index + 1) * stepWidth;

                const realStartX = index === 0 ? startX + 5 : startX;
                const realEndX = index === (levels - 1) ? endX - 5 : endX;


                if (isAnticipate || actionIsActive) {
                    return (
                        <Fragment key={index}>

                            { !isAnticipate && (
                                <>
                                    <Circle cx={realStartX} cy={`${middle}%`} r="5" fill={color}/>
                                    <Circle cx={realEndX} cy={`${middle}%`} r="5" fill={color}/>
                                </>
                            )}

                            <Line
                                x1={startX}
                                x2={endX}
                                y1={`${middle}%`}
                                y2={`${middle}%`}
                                strokeWidth={2.5}
                                strokeDasharray={isAnticipate ? '10' : '0'}
                                stroke={color}
                            />

                        </Fragment>
                    )
                }

                return null;
            })}
        </>
    )
}



const TrajectoryAverage = ({ average, addstyles = {}}: { average: number, addstyles?: object }) => {
    return (
        <View style={[styles.averageWrap, addstyles]}>
            <View style={[styles.average, { backgroundColor: getRankColor(average) }]}/>
        </View>
    )
}



const ActionDetails = ({ datas }: { datas: PdfSvgGroupType[] }) => {

    const half = Math.ceil(datas.length / 2);

    const firstHalf = datas.slice(0, half)
    const secondHalf = datas.slice(half)

    return (
        <View style={styles.detailsWrap}>
            <Text style={styles.detailsTitle}>{texts.synthesis.actionsList}</Text>

            { datas && datas.length > 0 && (
                <View style={styles.synthesisCols}>
                    <View style={[styles.synthesisBlock, { backgroundColor: 'rgba(12, 85, 162, 0.05)', border: 0 }]}>
                        { firstHalf.map((data, index) => (
                            <ActionItem name={data.name} description={data.description} index={index} key={index}/>
                        ))}
                    </View>

                    { secondHalf.length > 0 && (
                        <View style={[styles.synthesisBlock, { backgroundColor: 'rgba(12, 85, 162, 0.05)', border: 0 }]}>
                            { secondHalf.map((data, index) => (
                                <ActionItem name={data.name} description={data.description} index={index} key={index}/>
                            ))}
                        </View>
                    )}
                </View>
            )}
        </View>
    )

}

const ActionItem = ({ name, description, index }: { name: string, description: string, index: number }) => {

    return (
        <View style={{ marginTop: index !== 0 ? 11 : 0 }}>
            <View style={styles.listWrap}>
                <Text style={[styles.listDisc, { fontSize: 13 }]}>•</Text>
                <Text style={styles.detailName}>{name}</Text>
            </View>

            { description !== '' && <Text style={styles.detailDesc}>{description}</Text> }
        </View>
    )

}

interface PdfSvgGroupType extends SvgGroupType {
    name: string,
    description: string,
    average: number
}

const TrajectorySection = ({ data: mainData, trajectory, levels }: { data: any, trajectory: TrajectoryType, levels: [] }) => {

    const datas: PdfSvgGroupType[] = mainData.actions.actions
        .filter((action: ActionType) =>
            trajectory.project_trajectory_actions.find((item) => item.project_action_id === action.id))
        .map((action: ActionType): PdfSvgGroupType => {
            return {
                id: action.id,
                name: action.name,
                description: action.description,
                levels: action.matches,
                anticipation: mainData.rank.rankActions.find((rank: ActionRankDataType) => rank.id === action.id).anticipation_level,
                average: mainData.rank.rankActions.find((rank: ActionRankDataType) => rank.id === action.id).average
            }
        });


    const actionsNbr = datas.length;
    const gap = 7;
    const svgHeight = ((actionsNbr * 38) + (actionsNbr - 1) * gap) + 1; // +1 ftw :D

    return (
        <View style={styles.trajectoryOuter}>
            <Text style={styles.trajectoryTitle}>{trajectory.name}</Text>

            <View style={styles.trajectoryLevels}>
                { levels.map((level, index) => (
                    <LevelBox levelStyle={styles.trajectoryLevel} index={index} key={index}/>
                )) }
            </View>

            <View style={styles.trajectoryWrap}>

                <View style={styles.actionsList}>
                    { datas.map((action: PdfSvgGroupType, index: number) => (

                        <View style={[styles.actionWrap, { marginTop: index > 0 ? gap : 0 }]} key={index}>
                            <TrajectoryAverage average={action.average} addstyles={{marginRight: 6}}/>

                            <View style={styles.actionItem}>
                                <Text style={styles.actionName}>{action.name}</Text>
                            </View>
                        </View>
                    ))}
                </View>

                <View style={styles.svgWrap}>
                    {/*@ts-ignore*/}
                    <Svg width="100%" height={svgHeight}>
                        <>
                            { datas && datas.length > 0 && (
                                <>
                                    { datas.map((data, index) => (
                                        <PdfChartLine
                                            key={index}
                                            data={data}
                                            index={index}
                                            totalRows={datas.length}
                                            svgHeight={svgHeight}
                                            levels={mainData.synthesis.levels.length}
                                            trajectory={trajectory}
                                        />
                                    ))}
                                </>
                            )}

                            <PdfChartJoin
                                datas={datas}
                                actions={trajectory.project_trajectory_actions}
                                totalRows={datas.length}
                                svgHeight={svgHeight}
                                levels={mainData.synthesis.levels.length}
                                trajectory={trajectory}
                            />
                        </>
                    </Svg>
                </View>

            </View>

            <ActionDetails datas={datas}/>

            <PdfLegend/>

        </View>
    )
}


const PdfLegend = () => {

    const legends = [
        { average: 4, label: texts.trajectories.ranks[0] },
        { average: 3, label: texts.trajectories.ranks[1] },
        { average: 2, label: texts.trajectories.ranks[2] },
        { average: 1, label: texts.trajectories.ranks[3] },
    ]

    return (
        <View style={styles.legendColumns}>
            <View style={styles.legendWrap}>
                <Text style={styles.detailsTitle}>{texts.trajectories.results}</Text>

                <View style={styles.legendOuter}>
                    { legends.map((legend, index) => (
                        <View key={index} style={[styles.actionWrap, { marginTop: index !== 0 ? 5 : 0 }]}>
                            <TrajectoryAverage average={legend.average}/>

                            <Text style={[styles.detailDesc, { marginLeft: 6 }]}>{legend.label}</Text>
                        </View>
                    ))}
                </View>
            </View>

            <View style={[styles.legendWrap, { marginLeft: 16 }] }>
                <Text style={styles.detailsTitle}>{texts.trajectories.trajectory}</Text>

                <View style={styles.legendOuter}>
                    <View style={[styles.actionWrap]}>
                        <Image src={Legend} style={styles.legendLogo}/>

                        <Text style={styles.detailDesc}>{texts.actions.rank.form.anticipate.name}</Text>
                    </View>

                    <View style={[styles.actionWrap, { marginTop: 10 }]}>
                        <Image src={LegendDeploy} style={styles.legendLogo}/>

                        <Text style={styles.detailDesc}>{texts.trajectories.deploy}</Text>
                    </View>
                </View>
            </View>
        </View>
    )
}

const PdfContent = ({ data }: { data: any }) => {
    if (data) {
        return (
            <Document>
                {/*A4 width*/}
                <Page size={{ width: 595.28 }} style={styles.page}>
                    <PageHeader data={data} cover/>

                    <View style={styles.coverContainer}>
                        <Text style={styles.coverTitle}>{data.project.name}</Text>
                        <Text style={styles.coverDesc}>{data.project.description}</Text>

                        <Image src={Cover} style={styles.coverImg} />
                    </View>
                </Page>

                { data && data.synthesis.levels.map((level: any, index: number) => (
                    <PdfPage data={data} key={index} pageStyles={styles.page} orientation={'portrait'}>
                        <SynthesisSection level={level} causes={data.synthesis.causes} levelIndex={index}/>
                    </PdfPage>
                ))}

                { data && data.trajectories.project_trajectories.map((trajectory: any, index: number) => (
                    <PdfPage data={data} pageStyles={styles.trajectoryPage} orientation={'portrait'} key={index}>
                        <TrajectorySection data={data} trajectory={trajectory} levels={data.synthesis.levels}/>
                    </PdfPage>
                ))}

            </Document>
        );
    }

    return (
        <Document>
            <Page size="A4">
                <View>
                    <Text>{texts.generic.error}</Text>
                </View>
            </Page>
        </Document>
    );
};

export default PdfContent;