import { Dispatch, SetStateAction } from 'react';

import { Legend, Colors } from '../../../common/Legend';


type PropsType = {
    open: boolean,
    setOpen: Dispatch<SetStateAction<boolean>>
}

const RankResultsLegend = ({ open, setOpen }: PropsType) => (
    <Legend open={open} setOpen={setOpen}>
        <div>
            <Colors/>
        </div>
    </Legend>
);

export default RankResultsLegend;