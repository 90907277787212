import LoginBg from '../assets/images/medias/login.jpg';
import Legend from '../assets/images/medias/legend.png';
import LegendDeploy from '../assets/images/medias/deploy.png';
import LifeEauLogo from '../assets/images/logos/lec.svg';
import LifeEauLogoBlue from '../assets/images/logos/lec_blue.png';
import AdemeLogo from '../assets/images/logos/ademe.png';
import ActerraLogo from '../assets/images/logos/acterra.png';
import OiLogo from '../assets/images/logos/oieau.png';
import LifeLogo from '../assets/images/logos/life.png';
import MailPicto from '../assets/images/pictos/mail.svg';
import LockPicto from '../assets/images/pictos/lock.svg';
import EyePictoG from '../assets/images/pictos/eye_g.svg'
import CrossPicto from '../assets/images/pictos/cross.svg'
import CrossBluePicto from '../assets/images/pictos/cross_b.svg'
import ArrowBlack from '../assets/images/pictos/arrow_b.svg'
import ArrowOrange from '../assets/images/pictos/arrow_o.svg'
import Home from "../assets/images/menu/home.svg";
import Faq from "../assets/images/menu/faq.svg";
import Tuto from "../assets/images/menu/tuto.svg";
import Profile from "../assets/images/menu/profile.svg";
import Logout from "../assets/images/menu/logout.svg";
import Loader from '../assets/images/pictos/loader.svg';
import Hand from '../assets/images/pictos/hand.svg';

import {ReactComponent as EyeSVG} from '../assets/images/pictos/eye_w.svg';
import {ReactComponent as EditSVG} from '../assets/images/pictos/edit.svg';
import {ReactComponent as TrashSVG} from '../assets/images/pictos/trash.svg';
import {ReactComponent as TooltipSVG} from '../assets/images/pictos/tooltip.svg';
import {ReactComponent as InfoSVG} from '../assets/images/pictos/info.svg';
import {ReactComponent as PlusSVG} from '../assets/images/pictos/plus.svg';
import {ReactComponent as BigPlusSVG} from '../assets/images/pictos/plus_big.svg';
import {ReactComponent as ChevronSVG} from '../assets/images/pictos/chevron.svg';
import {ReactComponent as CheckSVG} from '../assets/images/pictos/check.svg';
import {ReactComponent as DetailsSVG} from '../assets/images/pictos/details.svg';
import {ReactComponent as StarSVG} from '../assets/images/pictos/star.svg';
import {ReactComponent as DownloadSVG} from '../assets/images/pictos/download.svg';
import {ReactComponent as HandSVG} from '../assets/images/pictos/hand.svg';
import {ReactComponent as SaveSVG} from '../assets/images/pictos/save.svg';

import Logo from '../assets/images/logos/logo';



export const assets = {
    loginBg: LoginBg,
    legend: Legend,
    deploy: LegendDeploy,
    logos: {
        lifeEau: LifeEauLogo,
        lifeEauBlue: LifeEauLogoBlue,
        ademe: AdemeLogo,
        acterra: ActerraLogo,
        oi: OiLogo,
        life: LifeLogo
    },
    picto: {
        mail: MailPicto,
        lock: LockPicto,
        eyeG: EyePictoG,
        cross: CrossPicto,
        crossB: CrossBluePicto,
        arrowB: ArrowBlack,
        arrowO: ArrowOrange,
        loader: Loader,
        hand: Hand
    },
    menu: {
        home: Home,
        faq: Faq,
        tuto: Tuto,
        profile: Profile,
        logout: Logout,
    }
}

export const SVG = {
    Tooltip: TooltipSVG,
    Info: InfoSVG,
    Plus: PlusSVG,
    BigPlus: BigPlusSVG,
    Eye: EyeSVG,
    Edit: EditSVG,
    Trash: TrashSVG,
    Chevron: ChevronSVG,
    Check: CheckSVG,
    Details: DetailsSVG,
    Star: StarSVG,
    Download: DownloadSVG,
    Hand: HandSVG,
    Save: SaveSVG,
    Logo: Logo
}