import { NavLink, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useProject } from '../../helpers/hooks';
import { Path } from 'history';

import { ConfirmModal } from '../common/Modal';
import { Title } from '../../components/common/Typos';

import { assets, globals, SVG } from '../../config';
import texts from '../../assets/data/contents.json';



type StepProp = {
    name: string,
    url: string | Partial<Path> | null,
    index: number,
}

interface StepPropWithPosition extends StepProp {
    projectPosition: number | undefined,
    currentStep: number | null,
}


const StepEntry = (props: StepPropWithPosition): JSX.Element => {
    const { name, url, index, projectPosition = -1, currentStep } = props;

    const [classes, setClasses] = useState({
        dot: 'bg-c-light-cyan text-white border-c-light-cyan',
        text: 'text-c-light-cyan',
        link: 'before:bg-c-light-cyan before:bg-opacity-25 pointer-events-none'
    });

    useEffect(() => {
        if (currentStep !== null) {
            if (index <= projectPosition + 1) {
                if (index <= currentStep) {
                    setClasses({dot: 'bg-c-green text-white border-c-green', text: 'text-c-green font-bold', link: 'before:bg-c-green before:bg-opacity-100'});
                } else {
                    setClasses({dot: 'bg-c-light-cyan text-white border-c-light-cyan', text: 'text-c-light-cyan font-bold', link: 'before:bg-c-light-cyan before:bg-opacity-100'});
                }
            } else {
                if (index <= currentStep) {
                    setClasses({dot: 'bg-white text-c-green border-c-green', text: 'text-c-green font-bold', link: 'before:bg-c-green before:bg-opacity-100 pointer-events-none'});
                } else {
                    setClasses({dot: 'bg-white text-c-light-cyan border-c-light-cyan', text: 'text-c-light-cyan font-medium', link: 'before:bg-c-light-cyan before:bg-opacity-25 pointer-events-none'});
                }
            }
        }
    }, [currentStep, projectPosition, index]);


    return (
        <>
            { url && (
                <NavLink to={url} className={`${classes.link} h-full flex flex-col items-center justify-start text-center relative before:absolute before:h-0.5 before:w-step before:-left-8 before:top-2.5 z-10 group`}>
                    <span className={`${classes.dot} relative z-10 mb-1.5 w-5 h-5 flex items-center justify-center border block rounded-full text-xxs`}>
                        {(index > projectPosition) ? <>{index}</> : <SVG.Check/>}
                    </span>
                    <span className={`${classes.text} text-xs group-hover:underline`} dangerouslySetInnerHTML={{__html: name}}/>
                </NavLink>
            )}
        </>
    )
}


const Steps = (): JSX.Element => {

    const navigate = useNavigate();
    const { project, currentStep } = useProject();
    const [open, setOpen] = useState(false);

    const setUrl = (step: string) => {
        return `${globals.routes.project}/${project?.id}${step}`;
    }

    const handleInfos = () => {
        if (project?.id) {
            return setUrl(globals.routes.staticSteps.edit);
        } else {
            return globals.routes.steps.create;
        }
    }

    const items: StepProp[] = [
        { name: texts.menu.project, url: handleInfos(), index: 0 },
        { name: texts.menu.cadrage, url: setUrl(globals.routes.staticSteps.cadrage), index: 1 },
        { name: texts.menu.impacts, url: setUrl(globals.routes.staticSteps.impacts), index: 2 },
        { name: texts.menu.objectives, url: setUrl(globals.routes.staticSteps.objectives), index: 3 },
        { name: texts.menu.actions, url: setUrl(globals.routes.staticSteps.actions.create), index: 4 },
        { name: texts.menu.trajectories, url: setUrl(globals.routes.staticSteps.trajectories), index: 5 },
        { name: texts.menu.planning, url: setUrl(globals.routes.staticSteps.planning), index: 6 },
        { name: texts.menu.synthesis, url: setUrl(globals.routes.staticSteps.synthesis), index: 7 },
    ];


    return (
        <div className="border-b border-gray-200 sticky top-0 pt-7 pb-5 bg-white z-2000 px-10 lg:px-14">

            <div className="flex mb-6">
                {project && (
                    <Title size="xsmall" variant="blue" className="mr-44 mt-1.5">{project.name}</Title>
                )}

                <div className="flex-shrink-0 ml-auto custom:fixed custom:right-12">
                    <button type="button" className="block p-2.5 transition-all duration-500 hover:opacity-60" onClick={() => setOpen(true)}>
                        <img src={assets.picto.crossB} alt={texts.generic.back}/>
                    </button>
                </div>
            </div>

            <ul className="flex flex-wrap -mx-3.5 lg:-mx-6">
                {items.map((item, idx) => (
                    <li key={idx} className="step min-w-stepTablet lg:min-w-step m-1.5">
                        <StepEntry name={item.name} url={item.url} index={item.index} projectPosition={project?.step_position} currentStep={currentStep}/>
                    </li>
                ))}
            </ul>

            <ConfirmModal
                open={open}
                setOpen={setOpen}
                doneCallback={() => navigate(globals.routes.index)}
                title={texts.generic.parcours.out}
                subtitle={<span dangerouslySetInnerHTML={{__html: texts.generic.parcours.unsaved}}/>}
                confirm={texts.generic.yes}
            />

        </div>
    );
}


export default Steps;