import { ImpactBlueprint } from '../../components/pages/Impacts';

import {
    CausesPostDatasType, CauseType,
    ImpactsAndCausesPostDatasType,
    ImpactsAndCausesType,
    ImpactsPostDatasType, ImpactType
} from '../../types';


export const formatImpactsGetDatas = (datas: ImpactsAndCausesPostDatasType) => {

    const getDatas: ImpactsAndCausesType = {
        step2_answer_sage: datas.step2_answer_sage || '',
        seuils: { name: datas.step2_level_name || '', levels: [datas.step2_level1_limit || '', datas.step2_level2_limit || ''] },
        impacts: [ImpactBlueprint, ImpactBlueprint],
        causes: [{id: null, name: ''}]
    }

    // eslint-disable-next-line
    if (datas.step2_level4_activated == 1) {
        getDatas.seuils.levels.push(datas.step2_level3_limit);
    }

    if (datas.impacts.length > 0) {
        getDatas.impacts = [];

        datas.impacts.map((impact: ImpactsPostDatasType) => {
            getDatas.impacts.push({
                id: impact.id,
                name: impact.name,
                descriptions: [
                    impact.level1_description,
                    impact.level2_description,
                    impact.level3_description,
                    impact.level4_description
                ]
            });
            return null;
        });
    }

    if (datas.causes.length > 0) {
        getDatas.causes = [];

        datas.causes.map((cause: CausesPostDatasType) => {
            getDatas.causes.push({
                id: cause.id,
                name: cause.name
            });
            return null;
        });
    }

    return getDatas;
}


export const formatImpactsPostDatas = (datas: ImpactsAndCausesType) => {

    const postDatas: ImpactsAndCausesPostDatasType = {
        step2_answer_sage: datas.step2_answer_sage,
        step2_level_name: datas.seuils.name,
        step2_level1_limit: datas.seuils.levels[0],
        step2_level2_limit: datas.seuils.levels[1],
        step2_level3_limit: datas.seuils.levels[2] || '',
        step2_level4_activated: datas.seuils.levels.length === 3,
        impacts: [],
        causes: []
    }

    datas.impacts.map((impact: ImpactType) => {
        postDatas.impacts.push({
            id: impact.id || null,
            name: impact.name,
            level1_description: impact.descriptions[0],
            level2_description: impact.descriptions[1],
            level3_description: impact.descriptions[2],
            level4_description: impact.descriptions[3] || ''
        });
        return null;
    });

    datas.causes.map((cause: CauseType) => {
        postDatas.causes.push({ id: cause.id || null, name: cause.name });
        return null;
    });

    return postDatas;
}