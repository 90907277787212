import { ReactNode } from 'react';
import SimpleBar from 'simplebar-react';

import { Paragraph, Title } from '../Typos';
import { Tooltip } from '../Tooltip';


type ListHeaderProps = {
    title: string,
    suffix?: string,
    subtitle?: string,
    tooltip?: string,
    locked: boolean,
    additionalPicto?: ReactNode,
    tooltipContainer: any
}

export const ListHeader = ({ title, suffix, subtitle, tooltip, locked, additionalPicto, tooltipContainer }: ListHeaderProps ) => {
    const tooltipFrag = <>
        <Tooltip tooltipContainer={tooltipContainer} content={<Title as="h5" size="xsmall" variant="blue">{title} {(!locked && suffix) && suffix}</Title>} color="fill-c-light-cyan">
            <>{ tooltip && <Paragraph className="text-center">{tooltip}</Paragraph> }</>
        </Tooltip>
    </>;

    const frag = <>
        { additionalPicto ? (
            <div className="flex items-center">
                {tooltipFrag}
                {additionalPicto}
            </div>
        ) : (
            tooltipFrag
        )}
    </>;

    return <TableHead title={frag} subtitle={subtitle} hasCollapse={locked}/>;
}


type TableLeftPropsType = {
    children?: ReactNode,
    wide?: boolean,
    hasCollapse?: boolean,
    className?: string
}

export const TableLeft = ({ children, wide, hasCollapse, className }: TableLeftPropsType): JSX.Element => (
    <div className={`flex-none ${wide ? 'w-80' : 'w-52'} ${hasCollapse ? '!w-auto' : ''} ${className || ''}`}>
        {children && children}
    </div>
);
export const TableRight = ({ children, stars, tight = false, className }: { children: ReactNode, stars?: boolean, tight?: boolean, className?: string }): JSX.Element => (
    <div className={`${className || ''} ${stars ? 'ml-2.5' : 'ml-7'} ${tight ? '!ml-14' : ''} flex-1 w-full`}>
        {children}
    </div>
);
export const TableActions = ({ children, stars }: { children?: ReactNode, stars?: boolean }): JSX.Element => (
    <div className={`${stars ? 'w-36 ml-2.5' : 'w-seuils h-seuils ml-4'} flex-none`}>
        {children && children}
    </div>
);


type TableHeadTypes = {
    title?: ReactNode,
    subtitle?: string,
    hasCollapse?: boolean
}

export const TableHead = ({ title, subtitle, hasCollapse }: TableHeadTypes) => {
    return (
        <div className="flex items-center">
            <TableLeft hasCollapse={hasCollapse}>
                {title}
            </TableLeft>

            { subtitle && !hasCollapse && (
                <TableRight>
                    <Title as="h5" size="xsmall" variant="blue">{subtitle}</Title>
                </TableRight>
            )}
        </div>
    )
}
export const TableRow = ({ children, alignTop }: { children: ReactNode, alignTop?: boolean }) => {
    return (
        <div className={`${!alignTop ? 'items-center' : ''} flex`}>
            {children}
        </div>
    )
}


const Table = (props: { children: ReactNode, className?: string, planning?: boolean }) => {
    const { children, className, planning } = props;

    return (
        <div className="!my-12">
            <SimpleBar className="table-simplebar">
                <div className={`${className || ''} space-y-7 ${!planning ? 'min-w-table' : 'min-w-planning'}`}>
                    {children}
                </div>
            </SimpleBar>
        </div>
    )

}

export default Table;