import { Dispatch, FormEvent, SetStateAction, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { TrajectoriesType, TrajectoryType } from '../../../types';

import TrajectoriesLegend from './TrajectoriesLegend';
import TrajectorySelect from './TrajectorySelect';
import ColorSelect from './ColorSelect';
import { Button } from '../../common/Buttons';
import { ConfirmModal } from '../../common/Modal';

import { TRAJECTORIES_COLORS } from '../../../config/globals';
import { SVG } from '../../../config';
import texts from '../../../assets/data/contents.json';



type PropsType = {
    trajectoriesValues: TrajectoriesType,
    setTrajectoriesValues: Dispatch<SetStateAction<TrajectoriesType>>,
    show: TrajectoryType | null,
    setShow: Dispatch<SetStateAction<TrajectoryType | null>>
    edit: TrajectoryType | null,
    setEdit: Dispatch<SetStateAction<TrajectoryType | null>>
}

const TrajectoriesCtas = ({ trajectoriesValues, setTrajectoriesValues, show, setShow, edit, setEdit }: PropsType) => {

    const [open, setOpen] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);

    const inputChange = (event: FormEvent<HTMLInputElement>) => {
        const val = event.currentTarget.value;
        edit && setEdit({...edit, name: val})
    }

    const handleDelete = () => {
        const total = trajectoriesValues.project_trajectories.length > 1;
        const rest = trajectoriesValues.project_trajectories.filter((traj) => traj.id !== show?.id);

        setTrajectoriesValues({
            ...trajectoriesValues,
            project_trajectories: rest
        });

        setShow(total ? rest[0] : null);
        setOpenDelete(false);
    }

    return (
        <>
            <div className="flex flex-col lg:flex-row items-center gap-3 !mt-12 min-h-trajectories">

                { !edit ? (
                    <>
                        <Button
                            variant="green"
                            className="!text-sm min-h-anticipate"
                            onClick={() => !edit && setEdit({
                                id: uuidv4(),
                                name: '',
                                color: TRAJECTORIES_COLORS[0],
                                project_trajectory_actions: []
                            })}
                        >
                            <SVG.Plus className="w-4 mr-2.5 group-hover:fill-c-green"></SVG.Plus>
                            {texts.trajectories.create}
                        </Button>

                        <TrajectorySelect
                            show={show}
                            setShow={setShow}
                            trajectoriesList={trajectoriesValues.project_trajectories}
                        />

                        { show && (
                            <div className="flex items-center gap-2 lg:ml-5">
                                <Button className="w-14 h-14" variant="white" size="picto" onClick={() => setEdit(show)}>
                                    <SVG.Edit className={`fill-c-gray group-hover:fill-c-blue`}/>
                                </Button>

                                <Button className="w-14 h-14" variant="white" size="picto" onClick={() => setOpenDelete(true)}>
                                    <SVG.Trash className={`stroke-c-gray group-hover:stroke-c-blue w-6`}/>
                                </Button>

                                <ConfirmModal title={texts.trajectories.delete} open={openDelete} setOpen={setOpenDelete} doneCallback={handleDelete}/>
                            </div>
                        )}
                    </>
                ) : (
                    <>
                        <input
                            type="text"
                            className="input-trajectory"
                            placeholder={texts.trajectories.name}
                            onChange={(e) => inputChange(e)}
                            defaultValue={edit.name}
                        />

                        <ColorSelect edit={edit} setEdit={setEdit}/>
                    </>
                )}

                <Button variant={"hollow-white"} className="!ml-0 lg:!ml-auto !text-sm min-h-anticipate" onClick={() => setOpen(true)}>
                    {texts.trajectories.legend}
                    <SVG.Tooltip className="fill-c-light-cyan ml-2 group-hover:fill-white"/>
                </Button>

            </div>

            <TrajectoriesLegend trajectoriesList={trajectoriesValues.project_trajectories} open={open} setOpen={setOpen}/>
        </>

    )
}

export default TrajectoriesCtas;