import React from 'react';
import { useField } from 'formik';
import { Error } from './index';
import { Label, Paragraph } from '../Typos';
import { Tooltip } from '../Tooltip';


const Textarea = ({ label, labelSize, labelTooltip, toggle, variant, className, containerRef, size, ...props }: any) => {
    const [field, meta] = useField(props);

    const frag = <Label size={labelSize} htmlFor={props.id || props.name}>{label}</Label>;
    const toolFrag = <Label className="!mb-0 mr-2" size={labelSize} htmlFor={props.id || props.name}>{label}</Label>;

    const classes = () => {
        let classes = { variant: '', size: '', base: 'min-h-textarea resize-none block w-full rounded-sm text-sm p-4 placeholder-c-gray outline-none outline-0 transition-shadow duration-200' };

        switch (variant) {
            case 'inner':
                classes.variant = 'shadow-c-input focus:shadow-c-input-hover';
                break;
            case 'light':
                classes.variant = 'border border-c-light-cyan !rounded-box !min-h-tooltip';
                break;
            case 'tooltip':
                classes.variant = 'bg-c-light-blue !rounded !placeholder-c-light-black font-medium !min-h-tooltip w-80 max-w-full';
                break;
            default:
                classes.variant = 'shadow-c-shadow focus:shadow-c-shadow-hover';
                break;
        }

        switch (size) {
            case 'small':
                classes.size = '!min-h-anticipate';
                break;
            default:
                classes.size = '';
                break;
        }

        return `${classes.base} ${classes.size} ${classes.variant} ${className || ''}`;
    }


    return (
        <div>
            { label && (
                <>
                    { labelTooltip ? (
                        <Tooltip tooltipContainer={containerRef} className="mb-4" content={toolFrag} color="fill-c-light-cyan">
                            <Paragraph className="text-center">{labelTooltip}</Paragraph>
                        </Tooltip>
                    ) : frag}
                </>
            )}


            <textarea
                {...field}
                {...props}
                className={`${classes()}`}
            />

            { meta.touched && meta.error ? (
                <Error>{meta.error}</Error>
            ) : null}
        </div>
    );
};

export default Textarea;