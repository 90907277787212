import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useActionsCreate, useActionsRank, useImpacts, useProject } from '../../../../helpers/hooks';
import { formatActionsRankPostDatas } from '../../../../helpers/api/actionsrank';

import { ProjectWrap } from '../../../layouts';
import { FormWrap } from '../../../common/Forms';
import { RankList } from '../index';
import { Reminder } from '../../../common/Miscs';

import { globals } from '../../../../config';
import texts from '../../../../assets/data/contents.json';



const Rank = (): JSX.Element => {

    const { project } = useProject();
    const containerRef = useRef(null);
    const navigate = useNavigate();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { initialValues: rankValues, validation } = useActionsRank();
    const { initialValues: actionsValues } = useActionsCreate();
    const { initialValues: impactsValues } = useImpacts();

    const onSubmit = (res: any) => {
        if (res.step_position) {
            navigate(`${globals.routes.project}/${res.project_id}${globals.routes.staticSteps.actions.incompatible}`);
        }
    };


    return (
        <ProjectWrap
            index={4}
            title={texts.actions.rank.title}
            desc={texts.actions.rank.desc}
            containerRef={containerRef}
        >

            <Reminder/>

            <FormWrap
                api={globals.api.steps.actions.rank.post}
                validation={validation}
                initial={rankValues}
                cta={texts.generic.continue}
                onSubmit={onSubmit}
                formatPostDatas={formatActionsRankPostDatas}
                additionalParams={{ project_id: project?.id, step_position: 4 }}
                back={`${globals.routes.project}/${project?.id}${globals.routes.staticSteps.actions.create}`}
            >

                <RankList actions={actionsValues} containerRef={containerRef}/>

            </FormWrap>
        </ProjectWrap>
    )
}


export default Rank;