import { Outlet, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useProject } from '../../helpers/hooks';

import { Loader } from '../common/Loader';



const StepsWrap = (): JSX.Element => {
    const { project, setProjectId } = useProject();
    const { projectId } = useParams();

    useEffect(() => {
        if (projectId) {
            setProjectId(parseInt(projectId));
        }
    }, [setProjectId, projectId]);

    return project ? <Outlet/> : <Loader/>;
}


export default StepsWrap;