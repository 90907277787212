import { useEffect, useState } from 'react';

import { SVG } from '../../../config';
import { Input } from './index';
import { useFormikContext } from 'formik';
import { getRankColor } from '../../../config/globals';




type PropsType = {
    value: number,
    average?: boolean,
    actionIdx?: number,
    categorieIdx?: number
}

const Stars = ({ value, average, actionIdx, categorieIdx }: PropsType) => {

    const stars = [ 1, 2, 3, 4 ];
    const [score, setScore] = useState(value);
    const formikContext = useFormikContext();

    useEffect(() => {
        !average && formikContext.setFieldValue(
            `rankActions[${actionIdx}].categories[${categorieIdx}]`,
            score,
            true
        );

        average && formikContext.setFieldValue(
            `rankActions[${actionIdx}].average`,
            score,
            true
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[score]);

    useEffect(() => {
        setScore(value);
    }, [value]);

    const wrapClasses = (): string => {
        if (average) {
            return getRankColor(value);
        }
        return 'border-c-light-cyan';
    }
    const starClasses = (idx: number): string => {
        if (average) {
            return (idx < value) ? 'fill-white stroke-white' : 'stroke-white';
        }
        return (idx < score) ? 'fill-c-cyan stroke-c-cyan' : 'stroke-[#9298A8]';
    }


    return (
        <>
            <div className={`${wrapClasses()} min-h-anticipate transition-all flex items-center justify-center border rounded-sm px-2 py-2.5`}>
                { stars.map((star, idx) => (
                    <button type="button" onClick={() => !average && setScore(idx+1)} key={idx} className={`${average ? 'cursor-default' : ''} p-0.5`}>
                        <SVG.Star className={`${starClasses(idx)} transition-all`}/>
                    </button>
                ))}
            </div>

            <Input
                value={value}
                className={"hidden"}
                name={`${average ? `rankActions[${actionIdx}].average` : `rankActions[${actionIdx}].categories[${categorieIdx}]` }`}
                type="number"
                floatErrors
                min={1}
                max={4}
            />
        </>
    )
}



export default Stars;