import { ReactNode } from 'react';


type PropsType = {
    variant?: string,
    type?: string,
    size?: string,
    children: ReactNode,
    className?: string,
    picto?: ReactNode
}


const Box = (props: PropsType) => {
    const { variant, type, size, children, className, picto } = props;

    const classes = () => {

        let classes = { variant: '', type: '', base: 'rounded-box bg-c-light-blue' };

        switch (type) {
            case 'inline':
                switch (size) {
                    case 'full':
                        classes.type = 'flex items-center space-x-2.5';
                        break;
                    default:
                        classes.type = 'inline-flex items-center space-x-2.5';
                        break;
                }
                break;
            case 'light':
                classes.type = '!bg-white px-[10%] space-y-7 !py-0 max-w-4xl';
                break;
            default:
                classes.type = 'space-y-7';
                break;
        }

        switch (variant) {
            case 'error':
                classes.variant = '!bg-red-500/10 py-4 px-6';
                break;
            case 'spread':
                classes.variant = 'pt-7 pb-12 px-20';
                break;
            default:
                classes.variant = 'py-5 px-8';
                break;
        }

        return `${classes.base} ${classes.variant} ${classes.type} ${className || ''}`;
    }

    return (
        <div className={`${classes()}`}>
            {picto && (
                <span className="flex-none">{picto}</span>
            )}
            {children}
        </div>
    )

}

export default Box;