import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useProject } from '../../../helpers/hooks';
import * as yup from 'yup';

import { ProjectWrap } from '../../layouts';
import { FormWrap, Input, Textarea } from '../../common/Forms';
import { Box } from '../../common/Box';

import { globals } from '../../../config';
import texts from '../../../assets/data/contents.json';



const Create = (): JSX.Element => {

    const { project, setProject } = useProject();
    const navigate = useNavigate();
    const containerRef = useRef(null);

    const validation = yup.object({
        name: yup.string().required(texts.generic.form.required),
        description: yup.string().required(texts.generic.form.required),
    });

    const initial = { name: project?.name || '', description: project?.description || '' };

    const onSubmit = (res: any) => {
        if (res.id) {
            if (project) setProject({ ...project, name: res.name, description: res.description});

            navigate(`${globals.routes.project}/${res.id}${globals.routes.staticSteps.cadrage}`);
        }
    };


    return (
        <ProjectWrap
            index={0}
            title={texts.project.title}
            desc={texts.project.desc}
            containerRef={containerRef}
        >

            <FormWrap
                api={globals.api.steps.create}
                validation={validation}
                initial={initial}
                cta={project ? texts.generic.modify : texts.project.form.submit}
                additionalParams={{ project_id: project?.id }}
                onSubmit={onSubmit}
                submitVariant="green"
                backLabel={texts.generic.cancel}
                back={globals.routes.index}
            >
                <Box variant="spread">
                    <Input
                        label={texts.project.form.title}
                        labelSize="text-subtitle"
                        name="name"
                        type="text"
                        variant="inner"
                        placeholder={texts.project.form.titlePlaceholder}
                    />
                    <Textarea
                        label={texts.project.form.desc}
                        labelSize="text-subtitle"
                        labelTooltip={texts.project.tooltip}
                        containerRef={containerRef}
                        name="description"
                        variant="inner"
                        placeholder={texts.project.form.descPlaceholder}
                    />
                </Box>
            </FormWrap>

        </ProjectWrap>
    )
}


export default Create;