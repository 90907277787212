import { useRef } from 'react';
import SimpleBar from 'simplebar-react';
import { useProject, useActionsCreate, useActionsIncompatibles } from '../../../../helpers/hooks';
import { useNavigate } from 'react-router-dom';

import IncompatiblesHeader from './IncompatiblesHeader';
import IncompatiblesList from './IncompatiblesList';
import { ActionsColumn } from '../index';
import { ProjectWrap } from '../../../layouts';
import { TableRow } from '../../../common/Table';
import { Reminder, SageQuestions } from '../../../common/Miscs';
import { TableLeft, TableRight } from '../../../common/Table/Table';
import { FormWrap } from '../../../common/Forms';

import { globals } from '../../../../config';
import texts from '../../../../assets/data/contents.json';




const Incompatibles = (): JSX.Element => {

    const { project, setProject } = useProject();
    const containerRef = useRef(null);
    const navigate = useNavigate();

    const { initialValues: actionsValues } = useActionsCreate();
    const {
        initialValues: incompatibilitiesValues,
        setInitialValues: setIncompatibilitiesValues,
        validation
    } = useActionsIncompatibles();


    const onSubmit = (res: any) => {
        if (res.step_position && project) {
            setProject({ ...project, step_position: res.step_position });
            navigate(`${globals.routes.project}/${res.project_id}${globals.routes.staticSteps.trajectories}`);
        }
    };


    return (
        <ProjectWrap
            index={4}
            title={texts.actions.incompatible.title}
            desc={texts.actions.incompatible.desc}
            containerRef={containerRef}
        >

            <Reminder/>

            <FormWrap
                api={globals.api.steps.actions.incompatibilities.post}
                validation={validation}
                initial={incompatibilitiesValues}
                cta={texts.generic.continue}
                onSubmit={onSubmit}
                //formatPostDatas={formatActionsCreatePostDatas}
                additionalParams={{ project_id: project?.id, step_position: 4 }}
                back={`${globals.routes.project}/${project?.id}${globals.routes.staticSteps.actions.rank}`}
            >

                { project && (<SageQuestions projectId={project.id} questions={texts.sage.actions} stepIndex={4}/>)}

                <TableRow alignTop>

                    <TableLeft>
                        <ActionsColumn actions={actionsValues} containerRef={containerRef} tooltipContent={texts.actions.incompatible.tooltip}/>
                    </TableLeft>

                    <TableRight stars>
                        <SimpleBar className="table-simplebar table-inner">
                            <IncompatiblesHeader actions={actionsValues}/>

                            <IncompatiblesList
                                actions={actionsValues}
                                incompatibilities={incompatibilitiesValues}
                                setIncompatibilities={setIncompatibilitiesValues}
                            />
                        </SimpleBar>
                    </TableRight>
                </TableRow>

            </FormWrap>
        </ProjectWrap>
    )
}


export default Incompatibles;