import { useNavigate } from 'react-router-dom';
import { useProject, useCadrage } from '../../../helpers/hooks';


import CadrageList from './CadrageList';
import { ProjectWrap } from '../../layouts';
import { FormWrap } from '../../common/Forms';
import { Reminder } from '../../common/Miscs';
import { Loader } from '../../common/Loader';

import { globals } from '../../../config';
import texts from '../../../assets/data/contents.json';



const Cadrage = (): JSX.Element => {

    const navigate = useNavigate();
    const { project, setProject } = useProject();
    const { initialValues, validation } = useCadrage();

    const onSubmit = (res: any) => {
        if (res.step_position && project) {
            setProject({ ...project, step_position: res.step_position });
            navigate(`${globals.routes.project}/${res.project_id}${globals.routes.staticSteps.impacts}`);
        }
    };

    if (!initialValues) return <Loader/>

    return (
        <ProjectWrap index={1} title={texts.cadrage.title} desc={texts.cadrage.desc}>

            <Reminder/>

            <FormWrap
                api={globals.api.steps.cadrage.post}
                validation={validation}
                additionalParams={{ project_id: project?.id, step_position: 1 }}
                initial={initialValues}
                cta={texts.generic.continue}
                onSubmit={onSubmit}
                back={`${globals.routes.project}/${project?.id}${globals.routes.staticSteps.edit}`}
            >

                <CadrageList/>

            </FormWrap>

        </ProjectWrap>
    )
}


export default Cadrage;