import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { setAuthTokens } from 'axios-jwt'
import { FormWrap, Input, Password } from '../../common/Forms';
import { Button } from '../../common/Buttons';

import { globals, assets } from '../../../config';
import texts from '../../../assets/data/contents.json';



const Login = (): JSX.Element => {

    const navigate = useNavigate();

    const validation = yup.object({
        email: yup.string().email(texts.generic.form.email).required(texts.generic.form.required),
        password: yup.string().required(texts.generic.form.required)
    });

    const initial = { email: '', password: '' };

    const preChild = <>
        <div className="mt-3.5 text-center">
            <Button path={globals.routes.register} inline>{texts.login.register.generic}</Button>
        </div>
    </>

    const onSubmit = (res: any) => {
        if (res.token && res.refresh_token) {
            setAuthTokens({
                accessToken: res.token,
                refreshToken: res.refresh_token
            });
        }

        navigate(globals.routes.index);
    }


    return (
        <FormWrap
            api={globals.api.login}
            validation={validation}
            initial={initial}
            cta={texts.login.connect}
            preChild={preChild}
            onSubmit={onSubmit}
            className={"max-w-login"}
            isLoginTunnel={true}
        >
            <Input
                label={texts.login.mail.label}
                name="email"
                type="email"
                placeholder={texts.login.mail.placeholder}
                picto={assets.picto.mail}
            />
            <Password
                label={texts.login.password.label}
                name="password"
                type="password"
                placeholder={texts.login.password.placeholder}
                picto={assets.picto.lock}
            />

            <Button path={globals.routes.forgotten} inline variant="black">
                {texts.login.password.forgotten}
            </Button>
        </FormWrap>
    );
}

export default Login;