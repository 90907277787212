import { useProject, useImpacts } from '../../../helpers/hooks';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { formatImpactsPostDatas } from '../../../helpers/api/impacts';

import { ImpactType } from '../../../types';

import { ProjectWrap } from '../../layouts';
import { FormWrap } from '../../common/Forms';
import { Reminder, SageQuestions } from '../../common/Miscs';
import { ImpactsList, CausesList } from './index';
import { Seuils, Table } from '../../common/Table';

import { globals } from '../../../config';
import texts from '../../../assets/data/contents.json';



export const ImpactBlueprint: ImpactType = { id: null, name: '', descriptions: ['', '', '', ''] };


const Impacts = (): JSX.Element => {

    const containerRef = useRef(null);
    const { project, setProject, seuils } = useProject();
    const navigate = useNavigate();
    const formRef = useRef<any>();

    const { initialValues, validation } = useImpacts();

    const onSubmit = (res: any) => {
        if (res.step_position && project) {
            setProject({ ...project, step_position: res.step_position });
            navigate(`${globals.routes.project}/${res.project_id}${globals.routes.staticSteps.objectives}`);
        }
    };

    const handleGuard = (): string | null => {
        let hasEmpty = false;

        if (formRef.current && formRef.current.isSubmitting) {

            hasEmpty = formRef.current.values.impacts
                .map((impact: ImpactType) => impact.descriptions.indexOf(''))
                .filter((arr: number) => {
                    if (arr === 3 && seuils && seuils.levels.length === 3) {
                        return false;
                    }
                    return arr > -1;
                }).length > 0;

            if (hasEmpty) {
                formRef.current.values.impacts.forEach((impact: ImpactType, impactIdx: number) => {
                    impact.descriptions.forEach((description: string, descIdx: number) => {
                        if (description === '') {
                            const domElem = document.querySelector("[name='impacts["+impactIdx+"].descriptions["+descIdx+"]']");
                            domElem && domElem.classList.add('field-warning');
                        }
                    });
                });
            }
        }

        return hasEmpty ? texts.impacts.form.warning : null;
    }

    return (
        <ProjectWrap index={2} title={texts.impacts.title} desc={texts.impacts.desc} containerRef={containerRef}>

            <Reminder/>

            <FormWrap
                api={globals.api.steps.impacts.post}
                validation={validation}
                initial={initialValues}
                cta={texts.generic.continue}
                onSubmit={onSubmit}
                additionalParams={{ project_id: project?.id, step_position: 2 }}
                formatPostDatas={formatImpactsPostDatas}
                back={`${globals.routes.project}/${project?.id}${globals.routes.staticSteps.cadrage}`}
                formRef={formRef}
                guardSubmit={handleGuard}
            >

                { project && (<SageQuestions projectId={project.id} questions={texts.sage.impacts} stepIndex={2}/>)}

                <Table>
                    <Seuils containerRef={containerRef}/>

                    <ImpactsList containerRef={containerRef}/>

                    <CausesList containerRef={containerRef}/>
                </Table>

            </FormWrap>

        </ProjectWrap>
    )
}


export default Impacts;