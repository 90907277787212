import { UserType, WithFetchType } from '../../../types';

import { Title } from '../../common/Typos';



interface Props extends WithFetchType {
    data: UserType
}


const UserInfo = (props: Props) => {
    const { data: user } = props;

    return (
        <Title variant="blue">Bonjour {user.name}.</Title>
    )
}


export default UserInfo;