import { ContentWrap } from '../../layouts';
import { Paragraph, Title } from '../../common/Typos';

import TutoPdf from "../../../assets/data/Tutoriel_Acterra.pdf";
import texts from '../../../assets/data/contents.json';
import { SVG } from '../../../config';



const Tuto = (): JSX.Element => {

    return (
        <ContentWrap size="small">
            <Title className="step-title" variant="blue" escaped={texts.tuto.title}/>

            <Paragraph className="text-base !mt-10" weight="medium">
                <span dangerouslySetInnerHTML={{__html: texts.tuto.content}}/><br/>

                <a className="group transition-all font-bold border-2 border-c-green bg-c-green text-white hover:text-c-green hover:bg-white px-4 py-2.5 mt-10 inline-flex items-center rounded" href={TutoPdf} target="_blank" rel="noreferrer">
                    {texts.tuto.download}
                    <SVG.Download className="fill-white group-hover:fill-c-green group-hover:stroke-c-green w-5 h-5 ml-1.5"/>
                </a>
            </Paragraph>
        </ContentWrap>
    );
}


export default Tuto;