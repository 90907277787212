import * as yup from 'yup';

import { ContentWrap } from '../../layouts';
import { FormWrap, Password } from '../../common/Forms';
import { Paragraph, Title } from '../../common/Typos';

import { assets, globals } from '../../../config';
import texts from '../../../assets/data/contents.json';


const Profile = (): JSX.Element => {

    const validation = yup.object({
        old_password: yup.string().required(texts.generic.form.required),
        password: yup.string().required(texts.generic.form.required).matches(/^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{8,})/, texts.generic.form.pattern),
        confirm_password: yup.string().required(texts.generic.form.required).oneOf([yup.ref('password'), null], texts.generic.form.match),
    });

    const initial = { old_password: '', password: '', confirm_password: '' };


    return (
        <ContentWrap size="xsmall">
            <Title className="step-title" variant="blue" escaped={texts.profile.title}/>

            <Paragraph className="text-lg !mt-10" weight="bold">{texts.profile.description}</Paragraph>

            <FormWrap
                api={globals.api.change}
                validation={validation}
                initial={initial}
                successContent={texts.login.password.changedone}
                cta={texts.generic.valid}
                className="mx-auto"
            >
                <Password
                    label={texts.login.password.currentLabel}
                    name="old_password"
                    type="password"
                    placeholder={texts.login.password.placeholder}
                    picto={assets.picto.lock}
                />
                <Password
                    label={texts.login.password.new}
                    name="password"
                    type="password"
                    placeholder={texts.login.password.placeholder}
                    picto={assets.picto.lock}
                />
                <Password
                    label={texts.login.password.validNew}
                    name="confirm_password"
                    type="password"
                    placeholder={texts.login.password.placeholder}
                    picto={assets.picto.lock}
                />
            </FormWrap>

        </ContentWrap>
    );
}

export default Profile;