import { ActionsCreateType, ActionsIncompatiblesType } from '../../../../types';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { assets } from '../../../../config';
import texts from '../../../../assets/data/contents.json';



type CheckerType = {
    colIndex: number,
    rowIndex: number,
    colId: number | null,
    rowId: number | null,
    incompatibilities: ActionsIncompatiblesType,
    setIncompatibilities: Dispatch<SetStateAction<ActionsIncompatiblesType>>
}

const Checker = (props: CheckerType) => {

    const { colIndex, rowIndex, colId, rowId, incompatibilities, setIncompatibilities } = props;
    const [checked, setChecked] = useState(false);

    useEffect(() => {
        if (colId && rowId) {
            const exists = incompatibilities.project_action_incompatibilities.find(i => i.project_action_1_id === colId && i.project_action_2_id === rowId);
            exists && setChecked(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [incompatibilities.project_action_incompatibilities])

    useEffect(() => {
        if (checked && colId && rowId) {
            setIncompatibilities({
                ...incompatibilities,
                project_action_incompatibilities: [
                    ...incompatibilities.project_action_incompatibilities,
                    { project_action_1_id: colId, project_action_2_id: rowId }
                ]
            });
        } else {
            setIncompatibilities({
                ...incompatibilities,
                project_action_incompatibilities: incompatibilities.project_action_incompatibilities.filter(
                    ({ project_action_1_id, project_action_2_id }) => !(project_action_1_id === colId && project_action_2_id === rowId)
                )
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checked]);


    return (
        <>
            { rowIndex > colIndex ? (
                <label className="checkbox-incompatible" htmlFor={`check-${colIndex}-${rowIndex}`}>
                    <input
                        className="peer"
                        id={`check-${colIndex}-${rowIndex}`}
                        type="checkbox"
                        onChange={() => setChecked(!checked)}
                        checked={checked}
                    />

                    <span className="checkbox-inner flex items-center justify-center text-xs font-bold text-c-gray">
                            <img className="mr-1.5" src={assets.picto.hand} alt={ texts.actions.incompatible.placeholder }/>
                        { texts.actions.incompatible.placeholder }
                        </span>
                </label>
            ) : (
                <div className="p-2 min-h-anticipate border rounded-sm border-c-check bg-gray-50"/>
            )}
        </>
    );
}


type PropsType = {
    actions: ActionsCreateType,
    incompatibilities: ActionsIncompatiblesType,
    setIncompatibilities: Dispatch<SetStateAction<ActionsIncompatiblesType>>
};

const IncompatiblesList = ({ actions, incompatibilities, setIncompatibilities }: PropsType) => {

    return (
        <div className="flex gap-2.5">
            { actions.actions.map((colAction, colIndex) => (
                <ul key={colIndex} className="flex-none w-action flex flex-col gap-2.5">

                    { actions.actions.map((rowAction, rowIndex) => (
                        <li key={rowIndex}>
                            <Checker
                                colIndex={colIndex}
                                rowIndex={rowIndex}
                                colId={colAction.id}
                                rowId={rowAction.id}
                                incompatibilities={incompatibilities}
                                setIncompatibilities={setIncompatibilities}
                            />
                        </li>
                    ))}

                </ul>
            ))}
        </div>
    )
}

export default IncompatiblesList;