import { ReactNode } from 'react';
import { Formik } from 'formik';



type FormWrapType = {
    children: ReactNode,
    initial: object,
    className?: string,
}

const ReadonlyForm = (props: FormWrapType): JSX.Element => {

    const { children, className, initial } = props;

    return (
        <>
            <div className={`${className || ''} w-full`}>
                <Formik
                    initialValues={initial}
                    enableReinitialize
                    onSubmit={() => {}}
                >
                    <div className="space-y-5">
                        {children}
                    </div>
                </Formik>
            </div>
        </>
    );
}

export default ReadonlyForm;