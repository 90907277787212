import { useProject } from '../../../helpers/hooks';
import { FieldArray } from 'formik';

import { Input } from '../../common/Forms';
import { TableRow } from '../../common/Table';
import { ListHeader, TableActions, TableLeft, TableRight } from '../../common/Table/Table';

import texts from '../../../assets/data/contents.json';



const ObjectiveField = ({ idx, locked }: { idx: number, locked: boolean }): JSX.Element => {

    return (
        <li className="w-full">
            <Input
                name={`objectives[${idx}]`}
                type="text"
                variant="blue"
                placeholder={texts.objectives.form.label}
                disabled={locked}
                floatErrors
                showTitle
            />
        </li>
    );
}


export const ObjectivesListHeader = (props: { locked: boolean, containerRef: any }) => (
    <ListHeader
        tooltipContainer={props.containerRef}
        title={texts.objectives.form.title}
        subtitle={texts.objectives.form.subtitle}
        tooltip={texts.objectives.form.tooltip}
        {...props}
    />
);

const ObjectivesList = ({ locked = false, tight = false, containerRef }: { locked?: boolean, tight?: boolean, containerRef: any }): JSX.Element => {

    const { seuils } = useProject();

    return (
        <div>
            { !locked && <ObjectivesListHeader containerRef={containerRef} locked={locked}/>}

            <FieldArray name="objectives" render={() => (
                <div className="space-y-3 mt-3">
                    <TableRow>

                        <TableLeft/>

                        <TableRight tight={tight}>
                            <ul className={`flex items-center ${!tight ? 'space-x-12' : 'space-x-2.5'}`}>
                                { seuils && seuils.levels.map((level, idx) => (
                                    <ObjectiveField key={idx} idx={idx} locked={locked}/>
                                ))}
                            </ul>
                        </TableRight>

                        <TableActions/>

                    </TableRow>
                </div>
            )}/>
        </div>
    );
}


export default ObjectivesList;