import React from 'react';

import { ContentWrap } from '../../layouts';
import { Button } from '../../common/Buttons';
import { Paragraph, Title } from '../../common/Typos';

import texts from '../../../assets/data/contents.json';
import { globals, SVG } from '../../../config';



const NotFound = (): JSX.Element => {
    return (
        <ContentWrap size="xsmall">

            <div className="text-center my-6">
                <SVG.Logo className="inline-block w-logo mb-10" open={true}/>

                <Title className="step-title" variant="blue" escaped={texts.notfound.title}/>

                <Paragraph className="text-lg !mt-10" weight="bold">{texts.notfound.description}</Paragraph>

                <Button className="!mt-16" path={globals.routes.index}>{texts.notfound.cta}</Button>
            </div>

        </ContentWrap>
    );
}

export default NotFound;