import { Routes, Route } from 'react-router-dom';
import RequireAuth from './RequireAuth';

import { LoginLayout, MainLayout, ProjectLayout, StepsWrap } from '../layouts';
import { Login, Register, Forgotten, Reset, Dashboard, NotFound, Profile, Faq, Tuto, Create, Cadrage, Impacts, Objectives, ActionsCreate, Rank, Incompatibles, Trajectories, Planning, Synthesis } from '../pages'

import { globals } from '../../config';


const App = () => {
    return (
        <div className="max-w-viewport mx-auto">
            <Routes>

                <Route element={<LoginLayout/>}>
                    <Route path={globals.routes.login} element={<Login/>} />
                    <Route path={globals.routes.register} element={<Register/>}/>
                    <Route path={globals.routes.forgotten} element={<Forgotten/>}/>
                    <Route path={globals.routes.reset} element={<Reset/>}/>
                </Route>

                <Route element={<RequireAuth/>}>
                    <Route element={<MainLayout/>}>

                        <Route path={globals.routes.index} element={<Dashboard/>}/>
                        <Route path={globals.routes.faq} element={<Faq/>}/>
                        <Route path={globals.routes.tuto} element={<Tuto/>}/>
                        <Route path={globals.routes.profile} element={<Profile/>}/>

                        <Route element={<ProjectLayout/>}>
                            <Route path={globals.routes.steps.create} element={<Create/>} />

                            <Route element={<StepsWrap/>}>
                                <Route path={globals.routes.steps.edit} element={<Create/>} />
                                <Route path={globals.routes.steps.cadrage} element={<Cadrage/>} />
                                <Route path={globals.routes.steps.impacts} element={<Impacts/>} />
                                <Route path={globals.routes.steps.objectives} element={<Objectives/>} />
                                <Route path={globals.routes.steps.actions.create} element={<ActionsCreate/>} />
                                <Route path={globals.routes.steps.actions.rank} element={<Rank/>} />
                                <Route path={globals.routes.steps.actions.incompatible} element={<Incompatibles/>} />
                                <Route path={globals.routes.steps.trajectories} element={<Trajectories/>} />
                                <Route path={globals.routes.steps.planning} element={<Planning/>} />
                                <Route path={globals.routes.steps.synthesis} element={<Synthesis/>} />
                            </Route>
                        </Route>

                    </Route>
                </Route>

                <Route path="*" element={<NotFound/>} />
            </Routes>
        </div>
    );
}

export default App;