import { useEffect, useState } from 'react';
import { globals } from '../../config';
import axiosInstance from '../api/axios';
import { ProjectType, SeuilType } from '../../types';


export default function useProvideProject() {

    const [project, setProject] = useState<ProjectType | null>(null);
    const [projectId, setProjectId] = useState<number | null>(null);
    const [currentStep, setCurrentStep] = useState<number | null>(null);
    const [seuils, setSeuils] = useState<SeuilType | null>({ name: '', levels: Array(3).fill('') });


    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const loadData = async () => {
            try {
                const response = await axiosInstance({
                    method: 'post',
                    url: globals.api.project,
                    signal: controller.signal,
                    withCredentials: true,
                    headers: { accept: 'application/json', 'Content-Type': 'application/json' },
                    data: { project_id: projectId },
                });

                isMounted && setProject(response.data);
            } catch (err: any) {
                console.log(err);
            }
        };

        projectId && loadData();

        return () => {
            isMounted = false;
            controller.abort();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectId]);


    return { project, setProject, projectId, setProjectId, currentStep, setCurrentStep, seuils, setSeuils }
}