import { Button } from './index';

import { SVG } from '../../../config';



type Props = {
    onClick: () => void,
    text: string,
    disabled?: boolean,
    orange?: boolean
}

const Add = ({onClick, text, disabled, orange}: Props): JSX.Element => {

    return (
        <Button variant={`${orange ? 'orange' : 'green'}`} inline className={`!no-underline mt-3.5 -ml-1 ${disabled ? 'pointer-events-none !text-gray-300' : ''}`} onClick={onClick}>
            <span className={`${disabled ? 'bg-gray-200' : (orange ? 'bg-c-orange/15' : 'bg-c-green/15')} transition-all duration-200 rounded-full w-6 h-6 block flex items-center justify-center mr-2`}>
                <SVG.BigPlus className={`${disabled ? 'fill-white stroke-white' : (orange ? 'fill-c-orange stroke-c-orange' : 'fill-c-green stroke-c-green')} transition-all duration-200 w-2 h-2`}/>
            </span>
            {text}
        </Button>
    )
}


export default Add;