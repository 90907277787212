import { Outlet, useNavigate } from 'react-router-dom';
import { useProvideProject } from '../../helpers/hooks';
import { ProjectContext } from '../../helpers/contexts';

import { Steps } from './index';
import { useEffect } from 'react';
import { globals, PROJECT_PATHS } from '../../config';



const ProjectLayout = (): JSX.Element => {
    const project = useProvideProject();
    const navigate = useNavigate();

    useEffect(() => {
        if (project.project?.step_position !== undefined && project.currentStep !== null) {
            const allowedPosition = project.project.step_position + 1;

            if (project.currentStep > allowedPosition) {
                navigate(`${globals.routes.project}/${project.project.id}${PROJECT_PATHS[allowedPosition]}`);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [project.project?.step_position]);


    return (
        <ProjectContext.Provider value={project}>
            <Steps/>

            <div className="px-10 lg:px-14 pt-10 pb-12">
                <Outlet/>
            </div>
        </ProjectContext.Provider>
    );
}


export default ProjectLayout;