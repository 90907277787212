import { useState, useLayoutEffect, ReactNode } from 'react';
import { createPortal } from 'react-dom';


const createWrapperAndAppendToBody = (wrapperId: string) => {
    const wrapperElement = document.createElement('div');

    wrapperElement.setAttribute("id", wrapperId);
    document.body.appendChild(wrapperElement);

    return wrapperElement;
}


type PortalProps = {
    children: ReactNode,
    wrapperId?: string
}

const Portal = ({ children, wrapperId = "react-portal-wrapper" }: PortalProps) => {
    const [wrapperElement, setWrapperElement] = useState<HTMLElement | null>(null);

    useLayoutEffect(() => {
        let element = document.getElementById(wrapperId);
        let systemCreated = false;

        if (!element) {
            systemCreated = true;
            element = createWrapperAndAppendToBody(wrapperId);
        }
        setWrapperElement(element);

        return () => {
            if (systemCreated && element?.parentNode) {
                element.parentNode.removeChild(element);
            }
        }
    }, [wrapperId]);

    // wrapperElement state will be null on very first render.
    if (wrapperElement === null) return null;

    return createPortal(children, wrapperElement);
}

export default Portal;