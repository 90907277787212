import { useEffect, ReactNode } from 'react';
import { useProject } from '../../helpers/hooks';
import { Paragraph, Title } from '../common/Typos';



type Props = {
    children: ReactNode,
    title: string,
    desc: string,
    descPicto?: ReactNode,
    index: number,
    containerRef?: any,
    titleScrollRef?: any,
    cta?: ReactNode
}

const ProjectWrap = (props: Props): JSX.Element => {

    const { children, title, desc, descPicto, index, containerRef, titleScrollRef, cta } = props;
    const { setCurrentStep } = useProject();

    useEffect(() => {
        setCurrentStep(index);
    }, [setCurrentStep, index]);


    return (
        <>
            <Title
                className="step-title mb-7.5"
                size="step"
                variant="blue"
                escaped={title}
                titleScrollRef={titleScrollRef}
            />

            { cta ? (
                <div className="flex items-center mt-7.5 gap-6">
                    <div className="flex-1">
                        <Paragraph className="max-w-4xl">{desc} {descPicto && descPicto}</Paragraph>
                    </div>

                    {cta}
                </div>
            ) : (
                <Paragraph className="mt-7.5 max-w-4xl">{desc} {descPicto && descPicto}</Paragraph>
            )}

            <div className="mt-8" ref={containerRef}>
                {children}
            </div>
        </>
    );
};

export default ProjectWrap;