import { useEffect, useState } from 'react';
import { useFetch, useProject } from './index';
import { formatPlanningGetDatas } from '../api/planning';

import * as yup from 'yup';

import { PlanningPostDatasType, PlanningType } from '../../types';

import { globals } from '../../config';



const usePlanning = () => {
    const { project, seuils } = useProject();

    const [initialValues, setInitialValues] = useState<PlanningType>({
        step2_answer_sage: '',
        step3_answer_sage_1: '',
        step3_answer_sage_2: '',
        step4_answer_sage_1: '',
        step4_answer_sage_2: '',
        step5_answer_sage_1: '',
        step5_answer_sage_2: '',
        step5_answer_sage_3: '',
        step6_answer_sage_1: '',
        step6_answer_sage_2: '',
        step6_answer_sage_3: '',
        step6_answer_sage_4: '',
        step6_answer_sage_5: '',
        step6_answer_sage_6: '',
        temporality: Array(4).fill(null)
    });

    const { data: getDatas } = useFetch<PlanningPostDatasType>(globals.api.steps.planification.get, { project_id: project?.id}, true);

    useEffect(() => {
        if (getDatas && seuils) setInitialValues(formatPlanningGetDatas(getDatas, seuils?.levels.length));
    }, [getDatas, seuils]);

    const validation = yup.object().shape({
        step2_answer_sage: yup.string(),
        step3_answer_sage_1: yup.string(),
        step3_answer_sage_2: yup.string(),
        step4_answer_sage_1: yup.string(),
        step4_answer_sage_2: yup.string(),
        step5_answer_sage_1: yup.string(),
        step5_answer_sage_2: yup.string(),
        step5_answer_sage_3: yup.string(),
        step6_answer_sage_1: yup.string(),
        step6_answer_sage_2: yup.string(),
        step6_answer_sage_3: yup.string(),
        step6_answer_sage_4: yup.string(),
        step6_answer_sage_5: yup.string(),
        step6_answer_sage_6: yup.string(),
        temporality: yup.array().of(yup.string().nullable(true))
});


    return { initialValues, validation };
}


export default usePlanning;
