import { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';

import { RadioQuestionType } from '../../../types';

import { RadioGroup } from '../../common/Forms';
import { Box } from '../../common/Box';
import { Paragraph } from '../../common/Typos';

import { SVG } from '../../../config';
import texts from '../../../assets/data/contents.json';




interface RadiosWithInfosProps extends RadioQuestionType {
    locked: boolean,
    idx: number
}

const RadiosWithInfos = (props: RadiosWithInfosProps): JSX.Element => {

    const { choices, name, idx, locked } = props;
    const formikContext = useFormikContext<any>();
    const initialSelected = choices.findIndex(choice => formikContext.initialValues[name] === choice.label);
    const [selected, setSelected] = useState<number | null>(initialSelected >= 0 ? initialSelected : null);
    const [desc, setDesc] = useState<string | null>(null);

    useEffect(() => {
        setSelected(initialSelected >= 0 ? initialSelected : null);
    }, [initialSelected])

    useEffect(() => {
        if (selected !== null)
            setDesc(choices[selected].desc);
    }, [choices, selected]);


    return (
        <>
            <RadioGroup
                className={`!my-10 px-[10%] ${locked ? 'pointer-events-none' : ''}`}
                spread={(idx === 0 || idx === 2)}
                setSelected={setSelected}
                selected={selected}
                {...props}
            />

            { !locked && desc && (
                <Box className="!my-10" type="inline" size="full" picto={<SVG.Info className="fill-c-light-cyan w-5 h-5"/>}>
                    <Paragraph weight="bold" variant="blue" className="py-3 max-w-4xl">{desc}</Paragraph>
                </Box>
            )}
        </>
    );
}



const CadrageList = ({ locked = false }: { locked?: boolean }): JSX.Element => {

    return (
        <>
            { texts.cadrage.form.questions.map((question, idx) => (
                <RadiosWithInfos {...question} key={idx} idx={idx} locked={locked}/>
            ))}
        </>
    )
}


export default CadrageList;