import { useCallback } from 'react';
import { useField, useFormikContext } from 'formik';

import { Error } from './index';
import { Label } from '../Typos';



const Radio = ({ label, setSelected, selected, idx, ...props }: any) => {
    const [field, meta] = useField({ ...props, type: 'radio' });
    const formikContext = useFormikContext();

    const handleChange = useCallback(() => {
        if (setSelected !== null && idx !== null) { setSelected(idx); }
        formikContext.setFieldValue(props.name, label);
    }, [setSelected, idx, formikContext, props.name, label]);


    return (
        <div>
            <Label checkbox>
                <input type="radio" {...field} {...props} className="radio-input peer" onChange={handleChange} checked={(selected === idx)}/>
                <span className="radio-label">{label}</span>
            </Label>

            { meta.touched && meta.error ? (
                <Error>{meta.error}</Error>
            ) : null}
        </div>
    );
};

export default Radio;