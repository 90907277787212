import { useState } from 'react';
import { useFormikContext } from 'formik';



export default function useFieldArray() {

    const { values } = useFormikContext<any>();
    const [open, setOpen] = useState(false);
    const [toremove, setToremove] = useState<number | null>(null);

    const handleRemove = (index: number) => {
        setToremove(index);
        setOpen(true);
    }
    const doneRemove = (arrayHelpers: any) => {
        arrayHelpers.remove(toremove);
        setToremove(null);
        setOpen(false);
    }

    return { values, open, setOpen, handleRemove, doneRemove };
}