import { setAuthTokens } from 'axios-jwt';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { FormWrap, Checkbox, Input, Password } from '../../common/Forms';
import { Paragraph } from '../../common/Typos';

import { assets, globals } from '../../../config';
import texts from '../../../assets/data/contents.json';



const Register = (): JSX.Element => {

    const navigate = useNavigate();

    const validation = yup.object({
        name: yup.string().required(texts.generic.form.required),
        email: yup.string().email(texts.generic.form.email).required(texts.generic.form.required),
        password: yup.string().required(texts.generic.form.required).matches(/^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{8,})/, texts.generic.form.pattern),
        confirm_password: yup.string().required(texts.generic.form.required).oneOf([yup.ref('password'), null], texts.generic.form.match),
        tos_checked: yup.boolean().oneOf([true], texts.login.register.accept)
    });

    const initial = { name: '', email: '', password: '', confirm_password: '', tos_checked: false };

    const onSubmit = (res: any) => {
        if (res.token && res.refresh_token) {
            setAuthTokens({
                accessToken: res.token,
                refreshToken: res.refresh_token
            });
        }

        navigate(globals.routes.index);
    }


    return (
        <FormWrap
            api={globals.api.register}
            validation={validation}
            initial={initial}
            cta={texts.login.register.create}
            title={texts.login.register.generic}
            onSubmit={onSubmit}
            className={"max-w-login"}
            isLoginTunnel={true}
            back={globals.routes.login}
        >
            <Input
                label={texts.login.name.label}
                name="name"
                type="text"
                placeholder={texts.login.name.placeholder}
            />
            <Input
                label={texts.login.mail.label}
                name="email"
                type="email"
                placeholder={texts.login.mail.placeholder}
            />
            <Password
                label={texts.login.password.label}
                name="password"
                type="password"
                placeholder={texts.login.password.placeholder}
                picto={assets.picto.lock}
            />
            <Password
                label={texts.login.password.valid}
                name="confirm_password"
                type="password"
                placeholder={texts.login.password.placeholder}
                picto={assets.picto.lock}
            />

            <Checkbox
                label={texts.login.register.check}
                name="tos_checked"
                className="!mt-12"
            />

            <Paragraph className="text-rgpd font-medium !mt-6">
                <span dangerouslySetInnerHTML={{__html: texts.login.register.rgpd}}/>
            </Paragraph>
        </FormWrap>
    );
}


export default Register;