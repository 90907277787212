import { useState } from 'react';
import { useProject, useFieldArray } from '../../../../helpers/hooks';
import { FieldArray, FormikErrors, FormikValues, useFormikContext } from 'formik';
import { ActionBlueprint, ActionsCatalog } from '../index';

import { ActionType } from '../../../../types';

import { Checkbox, Input, Textarea } from '../../../common/Forms';
import { TableRow } from '../../../common/Table';
import { ListHeader, TableActions, TableLeft, TableRight } from '../../../common/Table/Table';
import { Add, Button } from '../../../common/Buttons';
import { ConfirmModal } from '../../../common/Modal';
import { Tooltip } from '../../../common/Tooltip';

import { SVG } from '../../../../config';
import texts from '../../../../assets/data/contents.json';
import { getRankColor } from '../../../../config/globals';



const handleError = (values: FormikValues, errors: FormikErrors<any>, actionIndex: number, idx: number) => {
    // @ts-ignore
    if (errors.actions !== undefined && errors.actions.length > 0) {
        // @ts-ignore
        if (errors.actions[actionIndex] !== undefined) {
            const arr = values.actions[actionIndex].matches
            const firstIdx = arr.indexOf(true);
            const lastIdx = arr.lastIndexOf(true);

            return arr
                ?.map((item: boolean, idx: number) => (!item && idx > firstIdx && idx < lastIdx) ? idx : null)
                ?.filter((item: boolean | null) => (item !== null))
                ?.includes(idx);
        }
    }
    return false;
}


const ActionField = ({ idx, actionIndex, locked }: { idx: number, actionIndex: number, locked: boolean }): JSX.Element => {

    const { values, errors } = useFormikContext<any>();
    const hasError = handleError(values, errors, actionIndex, idx);

    return (
        <li className="w-full">
            <Checkbox name={`actions[${actionIndex}].matches[${idx}]`} action error={hasError}/>
        </li>
    );
}

type ActionContentProps = {
    index: number,
    locked: boolean,
    containerRef: any,
    rank?: number
}

export const ActionContent = ({ index, locked, containerRef, rank }: ActionContentProps) => {

    const { values } = useFormikContext<any>();
    const hasDesc = values.actions[index]?.description !== '';

    const descButton = <>
        <div className="relative">
            <SVG.Details className={`w-4 h-4 ${locked ? 'fill-c-blue' : ''}`}/>

            { !locked && (
                <span className={`${hasDesc ? 'bg-c-green' : 'bg-c-cyan'} absolute -bottom-1 -right-1 rounded-full p-1`}>
                    { hasDesc ? <SVG.Check className="w-1.5 h-1.5 fill-white"/> : <SVG.BigPlus className="w-1.5 h-1.5 stroke-white"/> }
                </span>
            )}
        </div>
    </>;

    return (
        <div className="relative">

            { rank && (
                <div className={`${getRankColor(rank)} vertical-center w-5 h-5 rounded-full -left-9`}></div>
            )}

            <Input
                name={`actions[${index}].name`}
                type="text"
                variant="blue"
                placeholder={texts.actions.create.form.libelle}
                disabled={locked}
                className={`!pr-10 !py-3 min-h-anticipate ${locked ? 'border-c-blue text-c-blue font-bold bg-white' : ''}`}
                floatErrors
                showTitle
            />

            <Tooltip
                content={descButton}
                title={locked ? values.actions[index]?.name : texts.actions.create.form.tooltipTitle}
                color="fill-c-light-black"
                className={`flex vertical-center right-2 ${(!hasDesc && locked) ? 'opacity-25 pointer-events-none' : '' }`}
                tooltipContainer={containerRef}
                standalone
                valid={!locked}
            >
                <Textarea
                    className="mt-3"
                    name={`actions[${index}].description`}
                    variant="tooltip"
                    placeholder={texts.actions.create.form.tooltipPlaceholder}
                    disabled={locked}
                />
            </Tooltip>
        </div>
    )
}


export const ActionsCreateHeader = (props: { locked: boolean, containerRef: any, tooltipContent?: string, hasCatalog?: boolean }) => {

    const [catalogOpen, setCatalogOpen] = useState(false);

    const catalogFrag = <>
        <button className="w-[1.35rem] lg:w-[1.225rem] hover:opacity-60 transition-opacity duration-200" type="button" onClick={() => setCatalogOpen(true)}>
            <SVG.Info className="w-full fill-c-light-cyan"/>
        </button>
    </>;

    return (
        <>
            <ListHeader
                tooltipContainer={props.containerRef}
                title={texts.actions.create.form.title}
                tooltip={props.tooltipContent}
                additionalPicto={props.hasCatalog ? catalogFrag : null}
                {...props}
            />

            { props.hasCatalog && <ActionsCatalog open={catalogOpen} setOpen={setCatalogOpen}/> }
        </>
    )
};

const CreateList = ({ locked = false, containerRef }: { locked?: boolean, containerRef: any }): JSX.Element => {

    const { seuils } = useProject();
    const { values, open, setOpen, handleRemove, doneRemove } = useFieldArray();


    return (
        <div>
            { !locked && <ActionsCreateHeader containerRef={containerRef} locked={locked} tooltipContent={texts.actions.create.form.tooltip} hasCatalog/>}

            <FieldArray name="actions" render={(arrayHelpers: any) => (
                <>
                    <div className={"space-y-3 mt-3"}>

                        { values.actions && values.actions.length > 0 && (
                            values.actions.map((action: ActionType, index: number) => (

                                <TableRow key={index}>

                                    <TableLeft>
                                        <ActionContent index={index} locked={locked} containerRef={containerRef}/>
                                    </TableLeft>

                                    <TableRight>
                                        <ul className="flex items-center space-x-12">
                                            { seuils && seuils.levels.map((level, idx) => (
                                                <ActionField key={idx} actionIndex={index} idx={idx} locked={locked}/>
                                            ))}
                                        </ul>
                                    </TableRight>

                                    <TableActions>
                                        { !locked && index > 0 && (
                                            <Button className="w-seuils h-seuils" size="picto" inline onClick={() => handleRemove(index)}>
                                                <SVG.Trash className="w-6 h-6"/>
                                            </Button>
                                        )}
                                    </TableActions>
                                </TableRow>

                            ))
                        )}
                    </div>

                    {!locked && (
                        <>
                            <Add onClick={() => values.actions.length < 50 && arrayHelpers.push(ActionBlueprint)} text={texts.actions.create.form.add} disabled={values.actions.length >= 50} orange/>

                            <ConfirmModal title={texts.impacts.delete} open={open} setOpen={setOpen} doneCallback={() => doneRemove(arrayHelpers)}/>
                        </>
                    )}
                </>
            )}/>
        </div>
    );
}


export default CreateList;