import { Button } from './index';

import { assets, globals } from '../../../config';
import texts from '../../../assets/data/contents.json';



const BackLight = (): JSX.Element => {
    return (
        <Button className="mb-12" type="button" variant="back" size="picto" path={globals.routes.index}>
            <img className="w-4.5 h-4.5 m-2" src={assets.picto.arrowO} alt={texts.generic.back}/>
        </Button>
    )
}


export default BackLight;