import { useEffect, useState } from 'react';
import { useFetch, useProject } from '../hooks';
import * as yup from 'yup';

import { ActionsIncompatiblesType } from '../../types';
import { globals } from '../../config';





const useActionsIncompatibles = () => {

    const { project } = useProject();

    const [initialValues, setInitialValues] = useState<ActionsIncompatiblesType>({
        step4_answer_sage_1: '',
        step4_answer_sage_2: '',
        project_action_incompatibilities: [],
    });

    const { data: getDatas } = useFetch<ActionsIncompatiblesType>(globals.api.steps.actions.incompatibilities.get, { project_id: project?.id}, true);

    useEffect(() => {
        if (getDatas) setInitialValues(getDatas);
    }, [getDatas]);

    const validation = yup.object().shape({
        step4_answer_sage_1: yup.string(),
        step4_answer_sage_2: yup.string(),
    });


    return { initialValues, setInitialValues, validation };
}


export default useActionsIncompatibles;
