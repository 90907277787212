import { FC, useEffect, useState } from 'react';
import { BlobProvider } from "@react-pdf/renderer";

import { Button } from '../Buttons';
import { Loader } from '../Loader';

import { SVG } from '../../../config';
import texts from '../../../assets/data/contents.json';



interface PdfDocumentProps {
    title: string;
    document: JSX.Element;
}

const PdfBlobGen: FC<PdfDocumentProps> = ({ title, document }) => {

    const [ready, setReady] = useState(false);

    // this is hacky but helps set the render to the back of event queue https://github.com/diegomura/react-pdf/issues/420
        useEffect(() => {
            setTimeout(() => { setReady(true); }, 0);
        }, []);
    // end of hacky stuff

    if (!ready) {
        return null;
    } else {
        return (
            <BlobProvider document={document}>
                {({ url, loading, error }) => {

                    if (loading) { return <Loader/>; }
                    if (error) { return <Button variant="blue" size="picto" disabled={true}>{texts.generic.error}</Button>; }

                    if (!loading && url) {
                        return <a href={url} download={title} className="pdf-download group">
                            <span className="mr-1.5">{texts.synthesis.download}</span>
                            <SVG.Download className="group-hover:fill-c-blue group-hover:stroke-c-blue w-5 h-5"/>
                        </a>;
                    }

                    return null;

                }}
            </BlobProvider>
        );
    }
};

export default PdfBlobGen;
