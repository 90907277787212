import Select from 'react-select'
import { Field } from 'formik';

import texts from '../../../assets/data/contents.json';


type PropsType = {
    options: any,
    field: any,
    form: any,
    variant?: string,
    placeholder?: string
}


const CustomSelect = ({ options, field, form, variant, placeholder }: PropsType) => (
    <Select
        options={options}
        name={field.name}
        value={options ? options.find((option: any) => option.value === field.value) : ''}
        onChange={(option: any) => form.setFieldValue(field.name, option.value)}
        onBlur={field.onBlur}
        className={`react-select-container ${variant ? `react-select-${variant}` : ''}`}
        classNamePrefix="react-select"
        isSearchable={false}
        hideSelectedOptions={true}
        placeholder={placeholder ?? null}
        menuPlacement={variant === 'anticipate' ? 'top' : 'bottom'}
        noOptionsMessage={() => texts.generic.void}
    />
)


type SelectFieldProps = {
    name: string,
    options: any,
    variant?: string,
    placeholder?: string
}

const SelectField = ({ name, options, variant, placeholder }: SelectFieldProps) => (
    <Field
        name={name}
        component={CustomSelect}
        options={options}
        variant={variant}
        placeholder={placeholder}
    />
);

export default SelectField;
