import { useCallback, useEffect, useState } from 'react';
import { ProjectType, WithFetchType } from '../../../types';
import axiosInstance from '../../../helpers/api/axios';

import { ConfirmModal } from '../../common/Modal';
import { Project } from './index';

import texts from '../../../assets/data/contents.json';
import { globals } from '../../../config';



interface Props extends WithFetchType {
    data: ProjectType[]
}

const ProjectList = (props: Props) => {
    const [projects, setProjects] = useState(props.data);

    const [removeId, setRemoveId] = useState<number | null>(null);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (removeId) setOpen(true);
    }, [removeId]);

    useEffect(() => {
        if (!open) setRemoveId(null);
    }, [open]);


    const handleDelete = useCallback(async () => {
        try {
            await axiosInstance({
                method: 'post',
                url: globals.api.delete,
                withCredentials: true,
                headers: { accept: 'application/json', 'Content-Type': 'application/json' },
                data: JSON.stringify({ project_id: removeId }),
            });

            setProjects(projects.filter(pro => pro.id !== removeId));
            setOpen(false);
        } catch (err: any) {
            console.log(err);
        }
    }, [projects, removeId]);


    return (
        <>
            <ul className="grid grid-cols-2 lg:grid-cols-3 gap-x-2.5 gap-y-12">

                { [...projects].reverse().map((project, idx) => (
                    <li key={idx}>
                        <Project {...project} onRemove={setRemoveId}/>
                    </li>
                ))}

            </ul>

            <ConfirmModal title={texts.generic.project.delete} open={open} setOpen={setOpen} doneCallback={handleDelete}/>
        </>

    )
}


export default ProjectList;
