import { useState, useEffect } from 'react';
import { useFetch, useProject } from './index';
import { formatActionsCreateGetDatas } from '../api/actionscreate';
import { ActionBlueprint } from '../../components/pages/Actions';
import * as yup from 'yup';

import { ActionsCreatePostDatasType, ActionsCreateType } from '../../types';

import { globals } from '../../config';
import texts from '../../assets/data/contents.json';



type MatchValuesType = (boolean | undefined)[] | undefined;


const validateActions = (value: MatchValuesType) => {
    const firstIdx = value?.indexOf(true) !== -1;

    if (firstIdx) {
        const trueIdxes = value
            ?.map((item, idx) => item === true ? idx : null)
            ?.filter(item => item !== null);

        let isValid = true;
        if (trueIdxes)
            for (let i = 0; i <= trueIdxes.length - 1; i++) {
                // @ts-ignore
                if (i > 0 && (trueIdxes[i] !== (trueIdxes[i-1] + 1)))
                    isValid = false;
            }
        return isValid;
    }
    return false;
}


const useActionsCreate = () => {
    const { project } = useProject();

    const [initialValues, setInitialValues] = useState<ActionsCreateType>({
        actions: [ActionBlueprint, ActionBlueprint]
    });

    const { data: getDatas } = useFetch<ActionsCreatePostDatasType>(globals.api.steps.actions.create.get, { project_id: project?.id}, true);

    useEffect(() => {
        if (getDatas) setInitialValues(formatActionsCreateGetDatas(getDatas));
    }, [getDatas]);

    const validation = yup.object().shape({
        actions: yup.array().of(yup.object().shape({
            name: yup.string().required(texts.generic.form.required),
            description: yup.string(),
            matches: yup.array().of(
                yup.boolean()
            )
                .test('is-true', 'Actions doivent se suivre', (value) => validateActions(value))
                .required(texts.generic.form.required).max(4, 'Au maximum 4 actions')
        }))
    });


    return { initialValues, validation };
}


export default useActionsCreate;
