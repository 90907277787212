import { Dispatch, SetStateAction } from 'react';
import { format } from 'date-fns';
import { ProjectType } from '../../../types';

import { Status } from './index';
import { Title, Paragraph } from '../../common/Typos';
import { Button } from '../../common/Buttons';

import { globals, SVG, PROJECT_STATUS, PROJECT_PATHS } from '../../../config';
import texts from '../../../assets/data/contents.json';



const setPath = (projectId: number, currentStep: number | undefined): string => {
    if (projectId !== undefined && currentStep !== undefined) return `${globals.routes.project}/${projectId}${PROJECT_PATHS[currentStep < 7 ? currentStep + 1 : currentStep]}`;
    else return '';
}


const EditCTA = ({ done, path }: {done: boolean, path: string}) => {
    return (
        <Button path={path} className={`${done ? 'w-14 h-14' : ''} m-1`} variant={`${done ? 'white' : 'hollow-blue'}`} size={`${done ? 'picto' : 'big'}`}>
            { !done && <span className="mr-2">{texts.generic.continue}</span>}
            <SVG.Edit className={`${done ? 'fill-c-gray group-hover:fill-c-blue' : 'fill-c-blue'} w-4.5`}/>
        </Button>
    );
}



const DeleteCTA = ({ id, onRemove }: {id: number, onRemove: Dispatch<SetStateAction<number | null>>}) => {
    return (
        <Button className="m-1 w-14 h-14" variant="white" size="picto" onClick={() => onRemove(id)}>
            <SVG.Trash className={`stroke-c-gray group-hover:stroke-c-blue w-6`}/>
        </Button>
    )
}


const Project = (props: ProjectType) => {
    const { id, name, description, status, created_at: date, onRemove, step_position } = props;

    const formatedDate = format(new Date(date), "dd/MM/yyyy");
    const isDone = status === PROJECT_STATUS.DONE;


    return (
        <div className="h-full rounded-1.5xl shadow-c-shadow pt-8 px-7 pb-10 flex flex-col">
            <div>
                <Status type={status}/>
            </div>

            <div className="mt-2.75 mb-7.5">
                <Title as="h3" size="subtitle" variant="blue" className="mb-3 truncate">{name}</Title>
                <Paragraph className="mb-4 project-desc">{description}</Paragraph>
                <span className="text-xs text-c-overlay">{formatedDate}</span>
            </div>

            <div className="mt-auto flex flex-wrap items-center -mx-1 -mb-1">

                { isDone && (
                    <Button className="m-1" size="big" path={`${globals.routes.project}/${id}${globals.routes.staticSteps.synthesis}`}>
                        <SVG.Eye className="w-4.5 mr-2.75 -ml-1.5 group-hover:stroke-c-blue"/>
                        {texts.generic.pdf.see}
                    </Button>
                )}

                <EditCTA path={setPath(id, step_position)} done={isDone}/>

                <DeleteCTA id={id} onRemove={onRemove}/>

            </div>
        </div>
    )
}

export default Project;