import React, { ReactNode, useRef } from 'react';
import { useOnClickOutside } from 'usehooks-ts';

import { assets } from '../../../config';
import texts from '../../../assets/data/contents.json';



const Header = ({ children, onClose }: {children: ReactNode, onClose?: () => void; }) => (
    <div className="flex items-center justify-between mb-4">
        {!!children && <p className="mr-2">{children}</p>}

        {!!onClose &&
            <button onClick={onClose} className="ml-auto p-1 hover:opacity-60 transition-opacity duration-200">
                <img className="w-4" src={assets.picto.cross} alt={texts.generic.close}/>
            </button>
        }
    </div>
);


type ModalProps = {
    isVisible?: boolean,
    onClose?: () => void,
    title?: ReactNode,
    children: ReactNode,
    variant?: string
}

const Modal = ({ children, isVisible, onClose, title, variant }: ModalProps) => {
    const ref = useRef<HTMLDivElement>(null);

    const handleClickOutside = () => { onClose && onClose(); }

    useOnClickOutside(ref, handleClickOutside)

    return (
        <div className={`${isVisible ? 'opacity-100 pointer-events-auto' : ''} pointer-events-none opacity-0
         transition-opacity duration-200 fixed z-20 w-full h-full top-0 left-0 flex items-center justify-center py-10
         before:absolute before:inset-0 before:bg-c-overlay before:transition-opacity before:pointer-events-none`}>

            <div className={`z-50 max-w-[90%] mx-auto overflow-y-auto max-h-full rounded-box bg-white ${variant === 'wide' ? 'p-20 pr-16 pt-12 w-legend' : 'p-10 pt-8 pb-12'}`} ref={ref}>
                <Header onClose={onClose}>{title}</Header>

                {children}
            </div>

        </div>
    );
}

export default Modal;