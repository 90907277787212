import { Dispatch, SetStateAction } from 'react';

import { Portal } from '../../layouts';
import { Modal } from '../../common/Modal';
import { Title } from '../../common/Typos';
import { Box } from '../../common/Box';

import texts from '../../../assets/data/contents.json';



const ActionsCatalog = ({ open, setOpen }: { open: boolean, setOpen: Dispatch<SetStateAction<boolean>> }) => {

    const actionsList = texts.actions.catalog.list;

    return (
        <Portal wrapperId="catalog-modal-container">
            <Modal isVisible={open} onClose={() => setOpen(false)}>
                <>
                    <Title
                        className="step-title ml-6"
                        size="step"
                        variant="blue"
                        escaped={texts.actions.catalog.title}
                    />

                    { actionsList.length > 0 && (
                        <ul className="mt-10 space-y-8 text-md text-c-light-black">

                            { actionsList.map((item, index) => {
                                const half = Math.ceil(item.list.length / 2);

                                const firstHalf = item.list.slice(0, half)
                                const secondHalf = item.list.slice(half)

                                return (
                                    <li key={index}>

                                        <Box>
                                            <Title variant="blue" size="subtitle" className="ml-4 mb-5">{item.thematic}</Title>

                                            <div className="flex space-x-7 !mt-0">
                                                <Box className="w-1/2">
                                                    <ul className="space-y-2 list-disc pl-4">
                                                        { firstHalf.map((listItem, index) => (
                                                                <li key={index}>
                                                                    {listItem}
                                                                </li>
                                                            )
                                                        )}
                                                    </ul>
                                                </Box>
                                                <Box className="w-1/2">
                                                    <ul className="!mt-0 space-y-2 list-disc pl-4">
                                                        { secondHalf.map((listItem, index) => (
                                                                <li key={index}>
                                                                    {listItem}
                                                                </li>
                                                            )
                                                        )}
                                                    </ul>
                                                </Box>
                                            </div>
                                        </Box>
                                    </li>
                                )}
                            )}
                        </ul>
                    )}
                </>
            </Modal>
        </Portal>
    )

}

export default ActionsCatalog;