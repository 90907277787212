import { useOnClickOutside } from 'usehooks-ts';

import { TrajectoryType } from '../../../types';
import { Dispatch, SetStateAction, useRef, useState } from 'react';

import { TRAJECTORIES_COLORS } from '../../../config/globals';
import { SVG } from '../../../config';




type PropsType = {
    edit: TrajectoryType | null,
    setEdit: Dispatch<SetStateAction<TrajectoryType | null>>
}

const ColorSelect = ({ edit, setEdit }: PropsType) => {

    const [open, setOpen] = useState(false);
    const ref = useRef<HTMLDivElement>(null);

    const handleClickOutside = () => { setOpen(false) }
    useOnClickOutside(ref, handleClickOutside)

    const colorChange = (color: string) => {
        if (edit) setEdit({...edit, color: color})
        setOpen(false);
    }

    return (
        <div className="relative z-5" ref={ref}>
            <button
                className="bg-white flex gap-2.5 items-center shadow-c-shadow px-4 py-3 min-h-anticipate rounded-box"
                type="button"
                onClick={() => setOpen(!open)}
            >
                <span className="w-5 h-5 rounded-full" style={{ backgroundColor: edit?.color }}/>
                <SVG.Chevron className={`duration-300 ${open ? 'rotate-180' : ''}`}/>
            </button>

            <ul className={`absolute mt-2 w-full transition-all shadow-c-shadow bg-white rounded-box py-2.5 ${open ? '' : 'opacity-0 pointer-events-none'}`}>

                { TRAJECTORIES_COLORS.map((color: string, index) => (
                    <li key={index}>
                        <button
                            className="transition-all hover:bg-c-light-cyan/25 flex items-center justify-center px-3 py-2 w-full text-center"
                            type="button"
                            onClick={() => colorChange(color)}
                        >
                            <span className="w-5 h-5 rounded-full" style={{ backgroundColor: color }}/>
                        </button>
                    </li>
                )) }

            </ul>
        </div>
    )
}

export default ColorSelect;