
import { FC, ElementType } from 'react';
import { useFetch } from '../hooks';

import texts from '../../assets/data/contents.json';
import { Loader } from '../../components/common/Loader';



const withFetch = (Component: ElementType, url: string, payload?: object, post?: boolean): FC => (props: object) => {

    const { data, error } = useFetch(url, payload, post);

    if (error) return <p>{texts.generic.error}</p>
    if (!data) return <Loader/>

    return (
        <Component
            data={data}
            isLoading={!data}
            isError={error}
            {...props}
        />
    );
};

export default withFetch;