import { useContext } from 'react';
import { ProjectContext } from '../contexts';


export default function useProject() {
    const context = useContext(ProjectContext);

    if (context === undefined) throw new Error("useProject must be within ProjectContext.Provider");

    return context;
}