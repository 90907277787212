

const LevelBox = ({ index, className }: { index: number, className?: string }) => {

    const colors = ['bg-c-seuil1', 'bg-c-seuil2', 'bg-c-seuil3', 'bg-c-seuil4'];

    return (
        <span className={`${colors[index]} ${className || ''} inline-block text-center rounded font-bold text-white p-3.25`}>
            Niveau {index + 1}
        </span>
    )
}

export default LevelBox;