import { useMemo } from 'react';
import { FieldArray, useFormikContext } from 'formik';

import { ActionType } from '../../../../types';

import { Stars } from '../../../common/Forms';
import { useFieldArray } from '../../../../helpers/hooks';



const calcResult = (categories: any, values: any) => {
    let sum = 0;

    values && categories.map((cat: number, idx: number) => {
        if (cat !== 0)
            sum += cat * values[idx];
        return false;
    });

    const divider = categories.reduce((acc: number, cur: number) => acc + cur);

    return Math.round(sum / divider);
}

const Result = ({ index }: { index: number }) => {
    const { values } = useFormikContext<any>();

    const result = useMemo(() =>
            calcResult(values.categories, values.rankActions[index]?.categories),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [values.categories, values.rankActions[index]?.categories]
    );

    return (
        <Stars value={result} average={true} actionIdx={index}/>
    )
}

const RankResults = ({ actions }: { actions: ActionType[] }) => {

    const { values } = useFieldArray();

    return (

        <FieldArray name="rankActions.categories" render={() => (
            <ul className="space-y-2.5">

                { values.rankActions && values.rankActions.length > 0 && (
                    values.rankActions.map((action: number, index: number) => (
                        <li key={index}>
                            <Result index={index}/>
                        </li>
                    ))
                )}

            </ul>
        )}/>


    )
}

export default RankResults;