import { Dispatch, SetStateAction, useRef, useState } from 'react';
import { useOnClickOutside } from 'usehooks-ts';

import { TrajectoryType } from '../../../types';

import texts from '../../../assets/data/contents.json';
import { SVG } from '../../../config';



const TrajectorySelect = ({ trajectoriesList, show, setShow }: { trajectoriesList: TrajectoryType[], show: TrajectoryType | null, setShow: Dispatch<SetStateAction<TrajectoryType | null>> }) => {
    const [open, setOpen] = useState(false);
    const ref = useRef<HTMLDivElement>(null);

    const handleClickOutside = () => { setOpen(false) }
    useOnClickOutside(ref, handleClickOutside)

    const list = trajectoriesList.filter(trajectory => (trajectory.id !== show?.id) || (trajectory.name.toLowerCase() !== show?.name.toLowerCase()));
    const hasItems = list.length > 0;


    return (
        <div className="z-5 text-sm text-c-blue" ref={ref}>
            <div className="relative max-w-trajectories min-w-select">
                <button
                    className={`${hasItems ? '' : 'pointer-events-none'} ${show ? 'font-medium border-c-light-cyan' : 'font-bold text-c-gray border-c-gray/50'} relative bg-white border pr-10 pl-4 py-3 min-h-anticipate rounded-sm w-full`}
                    type="button"
                    onClick={() => hasItems && setOpen(!open)}
                >
                    <>
                    <span className="flex items-center gap-2.5">
                        { show?.color && <span className="flex-none w-5 h-5 rounded-full" style={{ backgroundColor: show?.color }}/> }
                        <span className="whitespace-nowrap overflow-ellipsis overflow-hidden">
                            { show?.name || texts.trajectories.none }
                        </span>
                    </span>
                        { hasItems && <SVG.Chevron className={`vertical-center right-4 fill-c-blue duration-300 ${open ? 'rotate-180' : ''}`}/> }
                    </>
                </button>

                <ul className={`absolute -mt-[2px] w-full border border-t-0 border-c-light-cyan bg-white rounded-sm rounded-t-none py-2.5 ${open ? '' : 'opacity-0 pointer-events-none'}`}>

                    { list.map((item, index) => (
                        <li key={index}>
                            <button
                                className="transition-all hover:bg-c-light-cyan/25 flex items-center gap-2.5 text-left px-4 py-2 w-full"
                                type="button"
                                onClick={() => { setShow(item); setOpen(false); }}
                            >
                                <span className="flex-none w-5 h-5 rounded-full" style={{ backgroundColor: item.color }}/>
                                <span>{ item.name }</span>
                            </button>
                        </li>
                    )) }

                </ul>
            </div>
        </div>
    )
}

export default TrajectorySelect;
