import { ReactNode, useState } from 'react';

import { SVG } from '../../../config';



type CollapseProps = {
    children: ReactNode,
    toggle: ReactNode,
    className?: string
}

const Collapse = ({ children, toggle, className }: CollapseProps) => {

    const [collapse, setCollapse] = useState<boolean>(true);

    return (
        <div className={`${className || ''}`}>
            <div className="flex items-center">
                {toggle}

                <button type={"button"} className={`px-1.5 py-2 cursor-pointer transition-all ${collapse ? '' : 'rotate-180'}`} onClick={() => setCollapse(!collapse)}>
                    <SVG.Chevron className="fill-c-blue"/>
                </button>
            </div>

            <div className={`${collapse ? 'hidden' : ''}`}>
                {children}
            </div>
        </div>
    )

}

export default Collapse;