import axiosInstance from '../api/axios';
import { useState } from 'react';
import { useProject } from './index';
import { formatActionsCreateGetDatas } from '../api/actionscreate';
import { formatActionsRankGetDatas } from '../api/actionsrank';

import { globals } from '../../config';



const usePdf = () => {
    const { project } = useProject();

    const [error, setError] = useState(false);
    const [requesting, setRequesting] = useState(false);
    const [data, setData] = useState<any>(undefined);
    const [attempts, setAttempts] = useState(0);

    const fetchData = () => {

        if (!project?.id) return false;

        setRequesting(true);

        const synthesisRequest = axiosInstance({
            url: globals.api.steps.synthesis.get,
            method: 'post',
            withCredentials: true,
            headers: { accept: 'application/json', 'Content-Type': 'application/json' },
            data: JSON.stringify({ project_id: project?.id })
        });
        const trajectoriesRequest = axiosInstance({
            url: globals.api.steps.trajectories.get,
            method: 'post',
            withCredentials: true,
            headers: { accept: 'application/json', 'Content-Type': 'application/json' },
            data: JSON.stringify({ project_id: project?.id })
        });
        const actionsRequest = axiosInstance({
            url: globals.api.steps.actions.create.get,
            method: 'post',
            withCredentials: true,
            headers: { accept: 'application/json', 'Content-Type': 'application/json' },
            data: JSON.stringify({ project_id: project?.id })
        });
        const rankRequest = axiosInstance({
            url: globals.api.steps.actions.rank.get,
            method: 'post',
            withCredentials: true,
            headers: { accept: 'application/json', 'Content-Type': 'application/json' },
            data: JSON.stringify({ project_id: project?.id })
        });
        const incompatiblesRequest = axiosInstance({
            url: globals.api.steps.actions.incompatibilities.get,
            method: 'post',
            withCredentials: true,
            headers: { accept: 'application/json', 'Content-Type': 'application/json' },
            data: JSON.stringify({ project_id: project?.id })
        });

        Promise.all([
            synthesisRequest,
            trajectoriesRequest,
            actionsRequest,
            rankRequest,
            incompatiblesRequest
        ])
            .then((values) => {
                setData({
                    ...data,
                    project: project,
                    synthesis: values[0].data,
                    trajectories: values[1].data,
                    actions: formatActionsCreateGetDatas(values[2].data),
                    rank: formatActionsRankGetDatas(values[3].data),
                    incompatibles: values[4].data
                });

                setRequesting(false);
            })
            .catch((e => {
                setError(true);
                setRequesting(false);
                setAttempts(attempts + 1);
                console.error(e);
            }));
    };

    return { fetchData, data, error, requesting, attempts };
};

export default usePdf;
