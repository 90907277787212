import { useState } from 'react';
import SimpleBar from 'simplebar-react';

import { ActionsColumnType } from '../../../../types';

import RankHeader from './RankHeader';
import RankBody from './RankBody';
import RankResults from './RankResults';
import RankResultsLegend from './RankResultsLegend';
import { Square } from '../../../common/Box';
import { TableRow } from '../../../common/Table';
import { TableActions, TableLeft, TableRight } from '../../../common/Table/Table';
import { ActionsColumn } from '../../Actions';

import texts from '../../../../assets/data/contents.json';
import { SVG } from '../../../../config';




const RankList = ({ actions: actionsValues, containerRef }: ActionsColumnType): JSX.Element => {

    const [open, setOpen] = useState(false);

    return (
        <>
            <TableRow alignTop>

                <TableLeft>
                    <ActionsColumn actions={actionsValues} containerRef={containerRef} tooltipContent={texts.actions.create.form.tooltip}/>
                </TableLeft>

                <TableRight stars>
                    <SimpleBar className="table-simplebar table-inner">
                        <RankHeader containerRef={containerRef}/>

                        <RankBody actions={actionsValues.actions}/>
                    </SimpleBar>
                </TableRight>

                <TableActions stars>
                    <Square className="min-h-results mb-2.5 flex-col">
                        {texts.actions.rank.form.result}

                        <SVG.Tooltip onClick={() => setOpen(true)} className="mt-2 cursor-pointer fill-c-light-cyan hover:fill-white"/>
                    </Square>

                    <RankResults actions={actionsValues.actions}/>
                </TableActions>

            </TableRow>

            <RankResultsLegend open={open} setOpen={setOpen}/>
        </>
    );
}


export default RankList;