import { Paragraph } from '../Typos';

import texts from '../../../assets/data/contents.json';
import { SVG } from '../../../config';


const Reminder = () => {

    return (
        <div className="mb-6">
            <div className="flex items-center space-x-1.5 mb-2">
                <SVG.Info className="w-4.5 h-4.5 fill-c-light-cyan flex-shrink-0"/>
                <Paragraph weight="bold" variant="blue" className="">{texts.generic.reassure}</Paragraph>
            </div>
            <Paragraph weight="bold" variant="blue">{texts.generic.edit}</Paragraph>
        </div>
    )

}

export default Reminder;