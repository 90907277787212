import { FieldArray } from 'formik';

import { ActionsColumnType, ActionType } from '../../../types';

import { ActionContent, ActionsCreateHeader } from './Create/CreateList';
import { ReadonlyForm } from '../../common/Forms';



const ActionsColumn = ({ actions, containerRef, trajectories, ranks, tooltipContent }: ActionsColumnType) => {

    return (
        <>
            <div className={`${!trajectories ? 'min-h-results' : ''} flex items-end`}>
                <ActionsCreateHeader locked={false} containerRef={containerRef} tooltipContent={tooltipContent}/>
            </div>

            <ReadonlyForm initial={actions}>

                <FieldArray name="actions" render={() => (
                    <div className={`${!trajectories ? 'space-y-2.5' : 'space-y-[10px]' } mt-2.5`}>

                        { actions.actions && actions.actions.length > 0 && (
                            actions.actions.map((action: ActionType, index: number) => {
                                const average = ranks?.rankActions.find(rank => rank.id === action.id)?.average;

                                return (
                                    <ActionContent
                                        index={index}
                                        locked={true}
                                        containerRef={containerRef}
                                        key={index}
                                        rank={average}
                                    />
                                )
                            })
                        )}

                    </div>
                )}/>

            </ReadonlyForm>
        </>
    )

}

export default ActionsColumn;