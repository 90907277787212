import { PROJECT_STATUS } from '../../../config';
import texts from '../../../assets/data/contents.json';


const Status = ({ type }: { type: string }) => {

    const getStatus = (type: string) => {
        let status = { text: '', color: ''};

        switch (type) {
            case PROJECT_STATUS.ONGOING:
                status.text = texts.generic.project.status.inprogress;
                status.color = 'bg-c-orange';
                break;
            case PROJECT_STATUS.DONE:
                status.text = texts.generic.project.status.done;
                status.color = 'bg-c-green';
                break;
        }

        return status;
    }

    const { text, color } = getStatus(type);

    return (
        <span className={`${color} font-bold text-tag rounded-full inline-flex items-center pt-1.25 pb-1 px-2.5 text-white
         before:bg-white before:w-1.5 before:h-1.5 before:rounded-full before:mr-1.5`}>
            {text}
        </span>
    )
}

export default Status;
