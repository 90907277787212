import { Fragment } from 'react';
import { useElementSize } from 'usehooks-ts';
import { useProject } from '../../../helpers/hooks';

import {
    ActionRankType,
    ActionType,
    ChartLineType,
    IncompatiblesType, SvgGroupType,
    TrajectoryActionType,
    TrajectoryType
} from '../../../types';

import { checkActionDisabled } from './CheckList';




export const getMiddleLine = (svgHeight: number, totalRows: number, index: number) => {
    const offset = 10 / (svgHeight / 100);
    const rest = ((100 - offset * (totalRows - 1)) / totalRows);
    const startOffset = index * rest;
    const endOffset = (index + 1) * rest;

    const startY = index === 0 ? startOffset : startOffset + (offset * index);
    const endY = index === 0 ? endOffset : endOffset + (offset * index);
    const middle = startY + (endY - startY) / 2;

    return { middle };
}


const ChartLine = ({ trajectory, data, index, totalRows, svgHeight, levels, disabled }: ChartLineType) => {

    const { middle } = getMiddleLine(svgHeight, totalRows, index);

    const isSelected = trajectory?.project_trajectory_actions.some((ids) => ids.project_action_id === data.id);
    const color = disabled ? '#F1F1F1' : (isSelected ? trajectory?.color : '#B3B3B3');


    return (
        <g>
            { !isNaN(middle) && levels && [...Array(levels)].map((segment, index) => {

                const firstTrue = data.levels.indexOf(true);
                const actionIsActive = data.levels[index];

                const anticipateTreshold = ( (data.anticipation - 1 < 0) ? 4 : (data.anticipation - 1) );
                const isAnticipate = !actionIsActive && (index >= anticipateTreshold) && (index < firstTrue);

                const startX = `${index === 0 ? 0 : (index * 100) / (levels)}%`;
                const endX = `${(index + 1) * 100 / (levels)}%`;


                if (isAnticipate || actionIsActive) {
                    return (
                        <g key={index}>
                            { !isAnticipate && (
                                <>
                                    <circle cx={startX} cy={`${middle}%`} r="5" fill={color}/>
                                    <circle cx={endX} cy={`${middle}%`} r="5" fill={color}/>
                                </>
                            )}

                            <line
                                x1={startX}
                                x2={endX}
                                y1={`${middle}%`}
                                y2={`${middle}%`}
                                strokeWidth={2.5}
                                strokeDasharray={isAnticipate ? 10 : 0}
                                stroke={color}
                            />
                        </g>
                    )
                }

                return null;
            })}
        </g>
    )
}



export const GenJointure = (
    levels: number | undefined,
    firstAction: SvgGroupType,
    secondAction: SvgGroupType,
    currIndex: number,
    nextIndex: number,
    svgHeight: number,
    totalRows: number
) => {

    let sharedLevels: any[] = firstAction.levels.map((level: boolean, index: number) => {
        if (levels && (index === 0 || index === levels))
            return false;
        else {
            if (level && secondAction.levels[index])
                return true;

            if (level && secondAction.levels[index - 1])
                return true;

            if (firstAction.levels[index - 1] && secondAction.levels[index])
                return true;

            if (firstAction.levels[index - 1] && secondAction.levels[index - 1])
                return true
        }

        return null;
    });

    const { middle: currMiddle } = getMiddleLine(svgHeight, totalRows, currIndex);
    const { middle: nextMiddle } = getMiddleLine(svgHeight, totalRows, nextIndex);

    return { sharedLevels, currMiddle, nextMiddle };
}


type ChartJoinType = {
    trajectory: TrajectoryType | null,
    datas: SvgGroupType[],
    actions: TrajectoryActionType[] | undefined,
    totalRows: number,
    svgHeight: number,
    levels: number | undefined
}

const ChartJoin = ({ trajectory, datas, actions, totalRows, svgHeight, levels }: ChartJoinType) => {

    const selectedIds = actions?.map((action) => action.project_action_id);

    return (
        <>
            { datas.map((firstAction: any, mainIndex: number) => {
                return (
                    <Fragment key={mainIndex}>
                        { datas.map((secondAction: any, innerIndex: number) => {

                            const pairExists = [firstAction.id, secondAction.id].every(value => selectedIds?.includes(value));

                            if (firstAction.id !== secondAction.id && mainIndex < innerIndex && pairExists) {

                                const curr = datas.find((data) => data.id === firstAction.id);
                                const currIndex = datas.findIndex((data) => data.id === firstAction.id);
                                const next = datas.find((data) => data.id === secondAction.id);
                                const nextIndex = datas.findIndex((data) => data.id === secondAction.id);

                                if (curr && next) {

                                    const { sharedLevels, currMiddle, nextMiddle } = GenJointure(
                                        levels,
                                        firstAction,
                                        secondAction,
                                        currIndex,
                                        nextIndex,
                                        svgHeight,
                                        totalRows
                                    );

                                    const color = trajectory?.color;


                                    return (
                                        <Fragment key={innerIndex}>
                                            { sharedLevels.map((level: boolean, index: number) => {
                                                    if (level) {
                                                        const startX = levels && `${index === 0 ? 0 : (index * 100) / (levels)}%`;

                                                        return (
                                                            <g key={index}>
                                                                <line
                                                                    x1={startX}
                                                                    x2={startX}
                                                                    y1={`${currMiddle}%`}
                                                                    y2={`${nextMiddle}%`}
                                                                    strokeWidth={2.5}
                                                                    stroke={color}
                                                                />
                                                            </g>
                                                        )
                                                    }
                                                    return null;
                                                }
                                            )}
                                        </Fragment>
                                    )
                                }
                            }
                            return null;
                        } )}
                    </Fragment>
                )
            })}
        </>
    )
}



type PropsType = {
    incompatibles: IncompatiblesType[],
    edit: TrajectoryType | null,
    show: TrajectoryType | null,
    actions: ActionType[],
    ranks: ActionRankType[]
}

const TrajectoriesChart = ({ incompatibles, edit, show, actions, ranks }: PropsType) => {

    const { seuils } = useProject();
    const [svgRef, { height }] = useElementSize();

    const datas = actions.map((action, index): SvgGroupType => {
        return { id: action.id, levels: action.matches, anticipation: ranks[index]?.anticipation_level }
    });
    const svgHeight = `calc(${(datas.length * 51) / 16}rem + ${((datas.length - 1) * 10)}px`

    const isEdit = (edit && edit?.project_trajectory_actions.length > 1);
    const isShow = (!edit && show && show?.project_trajectory_actions.length > 1);


    return (
        <div className="mt-[2.313rem]" style={{ height: svgHeight }} ref={svgRef}>
            <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" className="overflow-visible">
                { datas && datas.length > 0 && (
                    <g>
                        { datas.map((data, index) => (
                            <ChartLine
                                key={index}
                                data={data}
                                index={index}
                                totalRows={datas.length}
                                svgHeight={height}
                                levels={seuils?.levels.length}
                                trajectory={edit || show}
                                disabled={checkActionDisabled(incompatibles, edit, data.id)}
                            />
                        ))}
                    </g>
                )}

                { (isEdit || isShow) && (
                    <ChartJoin
                        datas={datas}
                        actions={isEdit ? edit?.project_trajectory_actions : show?.project_trajectory_actions }
                        totalRows={datas.length}
                        svgHeight={height}
                        levels={seuils?.levels.length}
                        trajectory={isEdit ? edit : show}
                    />
                )}

            </svg>
        </div>
    )
}

export default TrajectoriesChart;