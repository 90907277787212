import { useImpacts, useActionsCreate, useProject, useObjectives } from '../../../../helpers/hooks';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { formatActionsCreatePostDatas } from '../../../../helpers/api/actionscreate';

import { ActionType } from '../../../../types';

import { ProjectWrap } from '../../../layouts';
import { FormWrap, ReadonlyForm } from '../../../common/Forms';
import { Seuils, Table } from '../../../common/Table';
import { Reminder } from '../../../common/Miscs';
import { CausesList, ImpactsList } from '../../Impacts';
import { Collapse } from '../../../common/Collapse';
import { ImpactsListHeader } from '../../Impacts/ImpactsList';
import { CausesListHeader } from '../../Impacts/CausesList';
import { CreateList } from '../index';
import { ObjectivesListHeader } from '../../Objectives/ObjectivesList';
import { ObjectivesList } from '../../Objectives';

import { globals, SVG } from '../../../../config';
import texts from '../../../../assets/data/contents.json';



export const ActionBlueprint: ActionType = { id: null, name: '', description: '', matches: [false, false, false, false] };


const Create = (): JSX.Element => {

    const containerRef = useRef(null);
    const { project } = useProject();
    const { initialValues: impactsValues } = useImpacts();
    const { initialValues: objectivesValues } = useObjectives();
    const { initialValues: actionsValues, validation } = useActionsCreate();

    const navigate = useNavigate();

    const onSubmit = (res: any) => {
        if (res.step_position) {
            navigate(`${globals.routes.project}/${res.project_id}${globals.routes.staticSteps.actions.rank}`);
        }
    };


    return (
        <ProjectWrap
            index={4}
            title={texts.actions.create.title}
            desc={texts.actions.create.desc}
            descPicto={<SVG.Info className="inline fill-c-light-cyan w-4.5"/>}
            containerRef={containerRef}
        >

            <Reminder/>

            <FormWrap
                api={globals.api.steps.actions.create.post}
                validation={validation}
                initial={actionsValues}
                cta={texts.generic.continue}
                onSubmit={onSubmit}
                formatPostDatas={formatActionsCreatePostDatas}
                additionalParams={{ project_id: project?.id, step_position: 4 }}
                back={`${globals.routes.project}/${project?.id}${globals.routes.staticSteps.objectives}`}
            >

                <Table>
                    <ReadonlyForm initial={impactsValues}>
                        <Seuils containerRef={containerRef} locked/>

                        <Collapse toggle={<ImpactsListHeader containerRef={containerRef} locked={true}/>}>
                            <ImpactsList containerRef={containerRef} locked/>
                        </Collapse>

                        <Collapse toggle={<CausesListHeader containerRef={containerRef} locked={true}/>} className={"!mt-7"}>
                            <CausesList containerRef={containerRef} locked/>
                        </Collapse>
                    </ReadonlyForm>

                    <ReadonlyForm initial={objectivesValues}>
                        <Collapse toggle={<ObjectivesListHeader containerRef={containerRef} locked={true}/>}>
                            <ObjectivesList containerRef={containerRef} locked/>
                        </Collapse>
                    </ReadonlyForm>

                    <CreateList containerRef={containerRef}/>
                </Table>

            </FormWrap>
        </ProjectWrap>
    )
}


export default Create;