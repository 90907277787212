import { ReactNode } from 'react';


type Props = {
    children: ReactNode,
    className?: string,
    size?: string,
    checkbox?: boolean,
    [x: string]: any;
}

const Label = ({ children, checkbox, size, className, ...props}: Props): JSX.Element => {

    const classes = () => {
        return checkbox ? 'cursor-pointer inline-block' : 'block mb-4 text-c-blue';
    }

    return (
        <label {...props} className={`${classes()} ${className || ''} ${size || 'text-base'} font-bold leading-label`}>
            { children }
        </label>
    )
}


export default Label;