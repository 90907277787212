import { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import axiosInstance from '../api/axios';

import { FormikHelpers } from 'formik/dist/types';
import { FormatPostDatasType } from '../../types';



type UseFormLogTypes = { (
        url: string,
        setError: Dispatch<SetStateAction<null>>,
        setSuccess: Dispatch<SetStateAction<boolean>>,
        onSuccess: ((res: any) => void) | undefined,
        additionalParams?: object,
        formatPostDatas?: FormatPostDatasType,
        reset?: boolean
    ): any
};

const useFormLog: UseFormLogTypes = (url, setError, setSuccess, onSubmit, additionalParams, formatPostDatas, reset)  => {

    const controllerRef = useRef<AbortController | null>();

    useEffect(() => {
        return () => { if (controllerRef.current) controllerRef.current.abort(); }
    }, []);

    return async function handleSubmit<Values>(values: Values, { setSubmitting, resetForm }: FormikHelpers<Values>) {
        if (controllerRef.current) controllerRef.current.abort();
        const controller = new AbortController();
        controllerRef.current = controller;

        try {
            const json = JSON.stringify({...additionalParams, ...values});

            const postDatas = (formatPostDatas !== undefined) ? JSON.stringify({...additionalParams, ...formatPostDatas(values)}) : json;

            const response = await axiosInstance({
                url: url,
                method: 'post',
                signal: controllerRef.current?.signal,
                withCredentials: true,
                headers: { accept: 'application/json', 'Content-Type': 'application/json' },
                data: postDatas,
            });

            controllerRef.current = null;

            reset && resetForm();
            setSubmitting(false);
            setSuccess(true);

            if (onSubmit !== undefined)
                onSubmit(response?.data);
        } catch (err: any) {
            console.log(err);
            setError(err.response?.data?.message || err.response?.data?.error);
        }
    }
}


export default useFormLog;