import { MouseEventHandler, useState } from 'react';
import { useNavigate, NavLink } from 'react-router-dom';
import { clearAuthTokens } from 'axios-jwt'
import { Path } from 'history';
import SimpleBar from 'simplebar-react';

import { assets, SVG, globals } from '../../config';
import texts from '../../assets/data/contents.json';




type LinkProp = {
    name: string,
    url: string | Partial<Path> | null,
    picto: string,
    onClick: MouseEventHandler<HTMLButtonElement> | undefined
}


const MenuEntry = (props: LinkProp & {menuOpen: boolean}): JSX.Element => {

    const { name, picto, url, onClick, menuOpen } = props;

    const defaultClasses = `flex items-center w-full px-4.75 py-3 whitespace-nowrap rounded-lg text-left transition-all duration-300 relative
     after:bg-c-yellow after:absolute after:pointer-events-none after:w-3.75 after:right-0 after:h-4/6
     after:top-1/2 after:transform after:-translate-y-1/2 after:transition-opacity after:opacity-0 after:rounded-l-box
     hover:bg-white hover:bg-opacity-10 hover:after:opacity-100`;

    const setLinkClasses = (isActive: boolean): string => {
        return (isActive ? defaultClasses + ' bg-white bg-opacity-10 after:opacity-100' : defaultClasses);
    }

    const frag = <>
        <img className="flex-none w-9.5 max-w-none" src={picto} alt={name}/>
        <span className={`${menuOpen ? '!max-w-2xl !opacity-100 !delay-300' : ''} flex-1 overflow-hidden max-w-0 opacity-0 transition-all duration-300`}>
            <span className="inline-block px-5 py-2">{name}</span>
        </span>
    </>;


    return (
        <>
            {url ? (
                <NavLink to={url} className={({ isActive }) => setLinkClasses(isActive)}>{frag}</NavLink>
            ) : (
                <button className={defaultClasses} onClick={onClick}>{frag}</button>
            )}
        </>
    )
}


const Menu = (): JSX.Element => {
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();

    const handleLogout = () => {
        clearAuthTokens();
        navigate(globals.routes.login);
    }

    const items: LinkProp[] = [
        { name: texts.menu.home, url: globals.routes.index, picto: assets.menu.home, onClick: undefined },
        { name: texts.menu.faq, url: globals.routes.faq, picto: assets.menu.faq, onClick: undefined },
        { name: texts.menu.tuto, url: globals.routes.tuto, picto: assets.menu.tuto, onClick: undefined },
        { name: texts.menu.profile, url: globals.routes.profile, picto: assets.menu.profile, onClick: undefined },
        { name: texts.menu.logout, url: null, picto: assets.menu.logout, onClick: handleLogout },
    ];


    const menuClasses = (): string => {
        if (open)
            return '!max-w-menu-full before:!opacity-100';
        else
            return '!delay-300'
    }

    return (
        <div onMouseEnter={() => setOpen(true)} onMouseLeave={() => setOpen(false)} className={`${menuClasses()} z-3000 max-w-menu transition-all duration-300 fixed inset-0 before:fixed before:z-10 before:inset-0 before:bg-c-overlay before:transition-opacity before:pointer-events-none before:opacity-0`}>
            <SimpleBar className={"menu-simplebar"}>

                <div className="flex flex-col min-h-full w-full bg-c-blue">

                    <div className="flex-1 px-3 py-14">
                        <SVG.Logo className="w-logo ml-2.75" open={open}/>

                        <nav className="mt-16 text-white font-bold">
                            <ul className="flex flex-col space-y-4">
                                {items.map((item, idx) => (
                                    <li key={idx} className="flex justify-center">
                                        <MenuEntry name={item.name} picto={item.picto} url={item.url} onClick={item.onClick} menuOpen={open}/>
                                    </li>
                                ))}
                            </ul>
                        </nav>
                    </div>

                    <div className={`${open ? 'opacity-100 delay-300' : ''} flex-none bg-white bg-opacity-90 py-12 px-10 text-center w-menu-full opacity-0 transition-opacity duration-300`}>
                        <div className="text-c-blue text-xs font-bold space-y-10">
                            <div>
                                <p>{texts.menu.footer}</p>
                                <img className="mt-10 block mx-auto w-full max-w-footerSmall" src={assets.logos.life} alt={texts.generic.life}/>
                                <img className="mt-5 block mx-auto w-full max-w-footerSmall" src={assets.logos.ademe} alt={texts.generic.ademe}/>
                            </div>

                            <div>
                                <p>{texts.menu.partners}</p>
                                <img className="mt-6 block mx-auto w-full max-w-footer" src={assets.logos.acterra} alt={texts.generic.acterra}/>
                                <img className="mt-5 block mx-auto w-full max-w-footer" src={assets.logos.oi} alt={texts.generic.oi}/>
                            </div>
                        </div>
                    </div>

                </div>
            </SimpleBar>
        </div>
    );
}


export default Menu;