import { assets } from '../../../config';
import texts from '../../../assets/data/contents.json';


const Loader = (): JSX.Element => {

    return (
        <div className="inline-flex p-4 items-center justify-center">
            <img className="animate-spin h-6 w-6" src={assets.picto.loader} alt={texts.generic.loading}/>
        </div>
    )
}


export default Loader;