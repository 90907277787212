import {
    TrajectoriesType, TrajectoryType
} from '../../types';



export const formatTrajectoriesPostDatas = (datas: TrajectoriesType) => {

    const postDatas: TrajectoriesType = {
        step5_answer_sage_1: datas.step5_answer_sage_1,
        step5_answer_sage_2: datas.step5_answer_sage_2,
        step5_answer_sage_3: datas.step5_answer_sage_3,
        project_trajectories: []
    }

    datas.project_trajectories.map((traj: TrajectoryType) => {
        postDatas.project_trajectories.push({
            id: typeof(traj.id === 'string') ? null : traj.id,
            name: traj.name,
            color: traj.color,
            project_trajectory_actions: traj.project_trajectory_actions
        });
        return null;
    });

    return postDatas;
}