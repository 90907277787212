import { useRef } from 'react';
import { useCadrage, usePlanning, useProject } from '../../../helpers/hooks';
import { formatPlanningPostDatas } from '../../../helpers/api/planning';
import { useNavigate } from 'react-router-dom';

import CadrageList from '../Cadrage/CadrageList';
import Temporality from './Temporality';
import { Reminder } from '../../common/Miscs';
import { PlanningSageQuestions } from '../../common/Miscs/SageQuestions';
import { ProjectWrap } from '../../layouts';
import { FormWrap, ReadonlyForm } from '../../common/Forms';

import { globals } from '../../../config';
import texts from '../../../assets/data/contents.json';



const Planning = (): JSX.Element => {

    const { project, setProject, seuils } = useProject();
    const containerRef = useRef(null);
    const { initialValues: cadrageValues } = useCadrage();
    const { initialValues: planningValues, validation } = usePlanning();
    const navigate = useNavigate();


    const onSubmit = (res: any) => {
        if (res.step_position && project) {
            setProject({ ...project, step_position: res.step_position });
            navigate(`${globals.routes.project}/${res.project_id}${globals.routes.staticSteps.synthesis}`);
        }
    };


    return (
        <ProjectWrap
            index={6}
            title={texts.planning.title}
            desc={texts.planning.desc}
            containerRef={containerRef}
        >

            <Reminder/>

            <FormWrap
                api={globals.api.steps.planification.post}
                validation={validation}
                additionalParams={{ project_id: project?.id, step_position: 6 }}
                initial={planningValues}
                formatPostDatas={(datas) => formatPlanningPostDatas(datas, seuils?.levels.length)}
                cta={texts.generic.continue}
                onSubmit={onSubmit}
                back={`${globals.routes.project}/${project?.id}${globals.routes.staticSteps.trajectories}`}
            >

                <ReadonlyForm initial={cadrageValues} className="!mb-20">
                    <CadrageList locked/>
                </ReadonlyForm>

                { project && (<PlanningSageQuestions cadrageValues={cadrageValues} questions={texts.sage.impacts} stepIndex={2} light/>) }
                { project && (<PlanningSageQuestions cadrageValues={cadrageValues} questions={texts.sage.objectives} stepIndex={3} light/>) }
                { project && (<PlanningSageQuestions cadrageValues={cadrageValues} questions={texts.sage.actions} stepIndex={4} light/>) }
                { project && (<PlanningSageQuestions cadrageValues={cadrageValues} questions={texts.sage.trajectories} stepIndex={5} light/>) }

                <div className="!mt-24">
                    { project && (<PlanningSageQuestions cadrageValues={cadrageValues} questions={texts.sage.planning} stepIndex={6} light/>) }
                </div>

                <Temporality containerRef={containerRef}/>

            </FormWrap>

        </ProjectWrap>
    )
}


export default Planning;