import { useEffect, useState } from 'react';
import { useFieldArray, useProject } from '../../../../helpers/hooks';
import { FieldArray } from 'formik';

import { ActionType } from '../../../../types';

import { SelectField, Stars } from '../../../common/Forms';

import texts from '../../../../assets/data/contents.json';



const RankAction = ({ action, actionIdx }: { action: ActionType, actionIdx: number }) => {

    const { seuils } = useProject();
    const { values } = useFieldArray();

    const [selectOptions, setSelectOptions] = useState<any>([]);

    useEffect(() => {
        const matches = action.matches.indexOf(true);

        setSelectOptions(texts.actions.rank.form.anticipate.levels
            .filter((option, idx) => idx <= matches)
            .map((option, index) => { return { label: option, value: index } })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [seuils]);


    return (
        <>
            <div className="flex-none w-32">
                <SelectField
                    name={`rankActions[${actionIdx}].anticipation_level`}
                    options={selectOptions}
                    variant="anticipate"
                />
            </div>

            <FieldArray name="rankActions.categories" render={() => (
                <ul className="flex-none flex gap-2.5">

                    { values.rankActions[actionIdx]?.categories && values.rankActions[actionIdx]?.categories.length > 0 && (
                        values.rankActions[actionIdx]?.categories.map((cat: number, idx: number) => (
                            <li key={idx} className="w-40">
                                <Stars actionIdx={actionIdx} categorieIdx={idx} average={false} value={cat}/>
                            </li>
                        ))
                    )}

                </ul>
            )}/>
        </>
    )
}

const RankBody = ({ actions }: { actions: ActionType[]}) => {

    return (
        <div className="min-h-stars">
            <ul className="space-y-2.5">
                { actions.map((action, index) => {
                    return (
                        <li className="flex gap-2.5" key={index}>
                            <RankAction action={action} actionIdx={index}/>
                        </li>
                    )
                } )}
            </ul>
        </div>
    )
}

export default RankBody;