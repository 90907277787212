import { ReactNode } from 'react';


type Props = {
    className?: string,
    sub?: boolean,
    variant?: string,
    weight?: string,
    children: ReactNode,
    [x: string]: any
}

const Paragraph = (props: Props): JSX.Element => {

    const { className, variant, weight, children } = props;

    const classes = () => {
        let classes = { variant: '', weight: '', base: 'text-sm' };

        switch (weight) {
            case 'bold':
                classes.weight = 'font-bold';
                break;
            default:
                classes.weight = 'font-medium';
        }

        switch (variant) {
            case 'blue':
                classes.variant = 'text-c-blue';
                break;
            default:
                classes.variant = 'text-c-light-black';
                break;
        }

        return `${classes.base} ${classes.weight} ${classes.variant} ${className || ''}`;
    }


    return (
        <p {...props} className={`${classes()}`}>
            { children }
        </p>
    )
}


export default Paragraph;