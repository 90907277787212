import { ContentWrap } from '../../layouts';
import { Paragraph, Title } from '../../common/Typos';
import { Box } from '../../common/Box';

import texts from '../../../assets/data/contents.json';



const Faq = (): JSX.Element => {

    return (
        <ContentWrap>
            <Title className="step-title" variant="blue" escaped={texts.faq.title}/>

            <Paragraph className="text-lg !mt-10" variant="blue" weight="bold">{texts.faq.description}</Paragraph>

            { texts.faq.questions.length > 0 && (
                <ul className="!mt-14 space-y-8 max-w-5xl">
                    { texts.faq.questions.map((question, index) => (
                        <li key={index}>
                            <Box variant="spread">
                                <Title as="h3" size="small" variant="blue" className="pt-5">{question.label}</Title>

                                <Paragraph className="text-base mt-2 leading-8 break-words faq-content">
                                    <span dangerouslySetInnerHTML={{__html: question.answer}}/>
                                </Paragraph>
                            </Box>
                        </li>
                    )) }
                </ul>
            )}
        </ContentWrap>
    );
}


export default Faq;