import { Dispatch, SetStateAction } from 'react';
import { TrajectoryType } from '../../../types';

import { Paragraph } from '../../common/Typos';
import { Legend, Colors } from '../../common/Legend';

import { assets } from '../../../config';
import texts from '../../../assets/data/contents.json';



type PropsType = {
    trajectoriesList: TrajectoryType[],
    open: boolean,
    setOpen: Dispatch<SetStateAction<boolean>>
}

const TrajectoriesLegend = ({ trajectoriesList, open, setOpen }: PropsType) => (
    <Legend open={open} setOpen={setOpen}>
        <div>
            <Paragraph className="text-base" variant="blue" weight="bold">{texts.trajectories.trajectory}</Paragraph>

            <ul className="pl-3.5 mt-7 space-y-7 text-sm text-c-light-black">
                <li className="flex items-center">
                    <img className="flex-none w-step mr-5" src={assets.legend} alt={texts.actions.rank.form.anticipate.name}/>
                    <span>{texts.actions.rank.form.anticipate.name}</span>
                </li>

                <li className="flex items-center">
                    <img className="flex-none w-step mr-5" src={assets.deploy} alt={texts.actions.rank.form.anticipate.name}/>
                    <span>{texts.trajectories.deploy}</span>
                </li>
            </ul>
        </div>

        <div>
            <Colors/>
        </div>

        { trajectoriesList.length > 0 && (
            <div>
                <Paragraph className="text-base" variant="blue" weight="bold">{texts.trajectories.list}</Paragraph>

                <ul className="pl-3.5 mt-7 space-y-7 text-sm text-c-light-black">

                    { trajectoriesList.map((traj, index) => (
                        <li key={index} className="flex items-center">
                            <div className="flex-none flex items-center justify-center w-step mr-5">
                                <span className="w-5 h-5 rounded-full" style={{ backgroundColor: traj.color }}/>
                            </div>
                            <span className="text-c-blue font-medium">{traj.name}</span>
                        </li>
                    ))}

                </ul>
            </div>
        )}
    </Legend>
);

export default TrajectoriesLegend;