import { createContext, Dispatch, SetStateAction } from 'react';
import { ProjectType, SeuilType } from '../../types';


export type ProjectContextProps = {
    project: ProjectType | null,
    setProject: Dispatch<SetStateAction<ProjectType | null>>,
    projectId: number | null,
    setProjectId: Dispatch<SetStateAction<number | null>>,
    currentStep: number | null,
    setCurrentStep: Dispatch<SetStateAction<number | null>>,
    seuils: SeuilType | null,
    setSeuils: Dispatch<SetStateAction<SeuilType | null>>
}

const ProjectContext = createContext<ProjectContextProps | undefined>(undefined);



export default ProjectContext;
