import { ActionRankDataType, ActionRankType, ActionsRankPostDatasType, ActionsRankType } from '../../types';
import { ActionRankBlueprint } from '../hooks/useActionsRank';


export const formatActionsRankGetDatas = (datas: ActionsRankPostDatasType) => {

    const getDatas: ActionsRankType = {
        categories: [
            datas.step4_criteria_efficiency,
            datas.step4_criteria_feasibility,
            datas.step4_criteria_flexibility,
            datas.step4_criteria_synergy,
            datas.step4_criteria_environmental_benefit,
            datas.step4_criteria_social_acceptability,
            datas.step4_criteria_cost_effective,
            datas.step4_criteria_mitigation_measures
        ],
        rankActions: [ActionRankBlueprint, ActionRankBlueprint]
    }

    if (datas.project_actions.length > 0) {
        getDatas.rankActions = [];

        datas.project_actions.map((action: ActionRankDataType) => {
            getDatas.rankActions.push({
                id: action.id,
                anticipation_level: action.anticipation_level,
                categories: [
                    action.criteria_efficiency_score,
                    action.criteria_feasibility_score,
                    action.criteria_flexibility_score,
                    action.criteria_synergy_score,
                    action.criteria_environmental_benefit_score,
                    action.criteria_social_acceptability_score,
                    action.criteria_cost_effective_score,
                    action.criteria_mitigation_measures_score
                ],
                average: action.criteria_score_average
            });
            return null;
        });
    }

    return getDatas;
}


export const formatActionsRankPostDatas = (datas: ActionsRankType) => {

    const postDatas: ActionsRankPostDatasType = {
        step4_criteria_efficiency: datas.categories[0],
        step4_criteria_feasibility: datas.categories[1],
        step4_criteria_flexibility: datas.categories[2],
        step4_criteria_synergy: datas.categories[3],
        step4_criteria_environmental_benefit: datas.categories[4],
        step4_criteria_social_acceptability: datas.categories[5],
        step4_criteria_cost_effective: datas.categories[6],
        step4_criteria_mitigation_measures: datas.categories[7],
        project_actions: []

    }

    datas.rankActions.map((action: ActionRankType) => {
        postDatas.project_actions.push({
            id: action.id,
            anticipation_level: action.anticipation_level,
            criteria_efficiency_score: action.categories[0],
            criteria_feasibility_score: action.categories[1],
            criteria_flexibility_score: action.categories[2],
            criteria_synergy_score: action.categories[3],
            criteria_environmental_benefit_score: action.categories[4],
            criteria_social_acceptability_score: action.categories[5],
            criteria_cost_effective_score: action.categories[6],
            criteria_mitigation_measures_score: action.categories[7],
            criteria_score_average: action.average,
        });

        return null;
    });

    return postDatas;
}