import { MouseEvent, useEffect, useState } from 'react';
import { useFormikContext } from 'formik';

import { Input } from './index';

import { assets } from '../../../config';
import texts from '../../../assets/data/contents.json';



const Password = (props: any) => {
    const [toggle, setToggle] = useState(false);
    const [shown, setShown] = useState(false);
    const { values } = useFormikContext<any>();
    const fieldValue = values[props.name];

    useEffect(() => {
        setShown(fieldValue !== '');
    }, [fieldValue]);

    const togglePassword = (e: MouseEvent<HTMLElement>) => {
        e.preventDefault();
        setToggle(!toggle);
    };

    const visibleClasses = (): string => {
        if (shown) {
            return toggle ? 'opacity-100 pointer-events-auto' : 'opacity-50 pointer-events-auto';
        } else {
            return 'opacity-0 pointer-events-none';
        }
    };

    const frag = <>
        <button type="button" className={`${visibleClasses()} vertical-center right-2 p-2 transition-opacity duration-200`} onClick={togglePassword}>
            <img className="w-4" src={assets.picto.eyeG} alt={texts.generic.see}/>
        </button>
    </>

    return (
        <Input
            {...props}
            type={toggle ? "text" : "password"}
            toggle={frag}
            className="pr-11"
            autoComplete={fieldValue}
        />
    );
};

export default Password;